.work-box-style {
    border-radius: 10px !important;
    padding: 0px;
    // margin-top: 16px;
    height: 50px;
    display: flex;

    .work-parent-box {
        align-items: center;
        width: 100%;

        .allocation-title {
            color: #000;
            font-family: Sora;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding: 15px;
        }

        .work-filter-container {
            display: flex;
            gap: 10px;

            .css-10t9wvu-MuiButtonBase-root-MuiToggleButton-root {
                color: #000 !important;

            }

            .work-toggle-btn {
                border-radius: 5px;
                height: 40px;

            }

            .work-toggle-btn .Mui-selected {
                background: #002882 !important;
                color: #fff !important;

            }

            .date-selection-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px 10px;
                height: 40px;
                gap: 2px;

                .date-text {
                    color: #000;
                    font-family: Sora;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 120px;
                }

            }

     
        }





    }
}