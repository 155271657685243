.candidate-head-wrap {
    display: grid;
    grid-template-columns: 164px 1fr;
    gap: 20px;
    border: 1px solid #E5E8EB;
    border-radius: 10px;
    padding: 15px;

    .profile-img {
        width: 164px;
        border-radius: 10px;
        margin-top: 0px;
    }

    .candidate-head-left {
        display: flex;
        flex-direction: column;

        .name-id {
            display: flex;
            align-items: center;
            gap: 8px;
            font-family: 'Sora';

            .user-name {
                color: #000000;
                font-size: 18px;
                font-weight: 600;
                margin: 0px;
            }

            .user-id {
                background-color: #F0F6FB;
                padding: 3px 5px 3px 5px;
                border-radius: 3px;
                margin: 0;
                color: #002882;
                font-size: 12px;
                font-weight: 500;
            }

            .assign-btn{
                font-size: 14px;
                font-weight: 400;

                svg{
                    width: 18px;
                    height: 18px;
                    margin-right: 4px;
                    margin-bottom: 2px;
                    path{
                        fill: #fff !important;
                    }
                }
            }
            .assign-btn[disabled]{
                opacity: 0.5;
                color: #fff;
            }
        }

        .candidate-details {
            display: flex;
            align-items: center;
            gap: 15px;
            color: #727376;
            font-family: 'Sora';
            flex-wrap: wrap;
            .icon-wrap {
                display: flex;
                align-items: center;
                gap: 4px;

                span {
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }

        .descr-text {
            color: #000000;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            font-family: 'Sora';
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            width: 800px;
        }

        .candidate-contact {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            flex-wrap: wrap;
            font-family: 'Sora';
            .contact-wrap {
                display: flex;
                align-items: center;
                gap: 10px;
                &.cursor-pointer{
                    cursor: pointer;
                }

                .MuiSvgIcon-root {
                    color: #FF6E00;
                    width: 20px;
                }

                .contact {
                    display: flex;
                    flex-direction: column;

                    .label-text {
                        font-size: 13px;
                        font-weight: 400;
                        color: #727376;
                        margin: 0px;
                        line-height: 16px;
                    }

                    .value-text {
                        font-size: 14px;
                        font-weight: 400;
                        color: #000000;
                        margin-top: 0px;
                        margin-bottom: 0;
                    }

                    .number-container{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        p{
                            color: #002882 !important;
                        }

                        svg path{
                            fill: #002882 !important;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 840px) {
        grid-template-columns: 1fr;
    }
    @media (max-width: 670px) {
        .profile-img{
            width: 120px;
        }
        .candidate-head-left {
            .candidate-contact {
                .contact-wrap {
                    .contact {
                        .value-text {
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
}

.assign-mandate-location-popup{
    background-color: rgba(0, 0, 0, 0.5);

    .MuiPopover-paper{
        top: calc(50vh - 230px) !important;
        left: calc(50% - 230px) !important;
    }

    .assign-container{
        padding: 20px; 
        width: 500px;

        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                color: #000;
                font-family: Sora;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
        .body-conatiner{
            padding: 20px 0px;
            display: flex;
            flex-direction: column;
            gap: 25px;

            .MuiInputBase-input{

                .primary-text{
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                }
            
                .secondary-text{
                    color: #727376;
                    font-size: 12px;
                    font-weight: 400;
                }

            }
        }

        .footer{
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            padding-top: 20px;
        }
        .assign-warning{
            display: flex;
            font-size: 13px;
            font-weight: 400;
            gap: 10px;
        }
        .warning-msg{
            color: #727376;
        }
        .error-msg{
            color: #F34334;
        }
    }

    .MuiInputBase-root{
        border-radius: 10px !important;
      }
    
}

.mandate-location-lists-opt{
    display: flex;
    flex-direction: column;
    align-items: start !important;

    .primary-text{
        color: #000;
        font-size: 14px;
        font-weight: 400;
    }

    .secondary-text{
        color: #727376;
        font-size: 12px;
        font-weight: 400;
    }
}

ul[aria-labelledby="mandate-location-select-label"]{
    max-height: 200px;
}
.candidateMenuMore{
    ul li{
        display: flex;
        gap: 5px;
        font-size: 14px;
    }
}