.assign-mandates-header {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-bottom: 15px;

}

.assign-radio-group{
  margin-bottom: 15px;
}

.inside-modal-popup {
    .css-e24clt-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 15px !important;
    }
}

.bottom-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 70px;

    .bottom-cancel-button {
        background: #E5E8EB !important;
        border-radius: 8px;
        color: #000000;
        padding: 8px 28px 8px 28px;
        text-transform: none;
        margin-right: 10px;
    }

    .bottom-assign-button {
        background: #002882 !important;
        border-radius: 8px;
        color: #fff;
        padding: 8px 28px 8px 28px;
        text-transform: none;
    }
}

.assignee-name-text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #000000;
}

.assignee-role-text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #727376;
}

.assignee-avatar {
    width: 30px; 
    height: 30px; 
    margin-right: 5px;
    font-size: 14px;
}

.inner-textfield {
    // max-height: 50px;
    /* overflow: none; */
    box-sizing: border-box;
    .MuiTextField-root {
      padding-top: 10px;
      border: 1.5px solid;
      border-radius: 8px;
      .MuiInputBase-adornedEnd{
        padding: 0 10px 10px 10px !important;
      }
  
    }
    label{
      background-color: #fff;
    }
  
    // .MuiInputBase-formControl {
    //   max-height: 50px;
    //   overflow: auto;
    //   /* border: 1px solid; */
    //   // padding-top: 0;
    // }
  
    .MuiInputBase-formControl fieldset {
      border: none;
      outline: none;
    }
    ::-webkit-scrollbar {
      // display: none;
      width: 6px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f4f5f6;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #aaa;
      width:10px;
      text-overflow: ellipsis;
    }
  }