.mbsc-calendar-wrapper{
    .mbsc-calendar-header{
        .mbsc-calendar-controls{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;

        .right-corner{
            display: flex;
            flex-direction: row;
            align-items: center;

            button{
                color: #FF6E00;
                font-family: Sora;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
            }
        }
    }
    }
}
.MuiSnackbar-root > .MuiPaper-root.MuiAlert-standardError{
    background-color: #d32f2f;
    color: #fff;
}
.MuiSnackbar-root > .MuiPaper-root.MuiAlert-standardError svg{
    color: #fff;
}