.mandate-wrapper{
    .nav-header{
        border-bottom: 0px;
    }
    .createRoleAccordionSect{
        margin-top: 20px;
        > .MuiPaper-root{
            box-shadow: none;
            > .MuiButtonBase-root{
                min-height: 54px;
            }
            .accordian-header{
                &::before{
                    min-height: 54px;
                }
            }
        }
    }
}

.roleListFilterSect{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    .roleListFilterSearch{
        position: relative;
        .MuiSvgIcon-root{
            position: absolute;
            left: 10px;
            top: 10px;
            color: gray;
        }
        label:not(.Mui-focused){
            transform: translate(38px, 13px) scale(1) !important;
            font-size: 14px;
        }
        .MuiFormControl-root{
            input{
                padding: 10px;
                padding-left: 40px;
            }
            fieldset{
                border-radius: 6px;
            }
        }
    }
    .MuiFormControl-root{
        label:not(.Mui-focused){
            transform: translate(14px, 11px) scale(1);
            font-size: 14px;
        }
        label.MuiFormLabel-filled{
            transform: translate(14px, -9px) scale(0.75) !important;
            font-size: 16px !important 
        }
        .MuiInputBase-root{
            .MuiSelect-select{
                padding: 10px;
            }
            fieldset{
                border-radius: 6px;
            }
        }
    }
    .MuiFormControl-root{
        .MuiInputBase-root:hover,.MuiInputBase-root.Mui-focused{
            fieldset{
                border-color: rgba(0, 0, 0, 0.23);
                border-width: 1px;
            }
        }
    }
}

.activeInactiveUserText{
    margin: 0px;
    span{
        font-weight: 600;
    }
    .roleName{
        text-decoration: underline;
    }
}
.roleDetailSect .mandate-detail-wrapper{
    height: auto;
    min-height: 100px;
}
.roleStatusWarning{
    position: fixed;
    z-index: 9999;
    left: 0px;
    width: 100%;
    background: #0000005e;
    top: 0px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .roleStatusWarningBody{
        background: #fff;
        padding: 30px 40px 10px 40px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4{
            margin: 10px 0px 0px 0px;
            font-weight: 600;
        }
        .roleStatusWarningBodyCont{
            margin-top: 15px;
            text-align: center;
            p{
                margin: 0px;
                font-size: 14px;
                font-weight: 500;
            }
        }
        .roleStatusWarningAction{
            border-top: 1px solid #cccccc;
            width: 100%;
            text-align: center;
            padding: 10px;
            margin-top: 20px;
            button{
                color: #1565c0;
                background: transparent;
                border: none;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
}