.jobWithCandiActionSect{
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    > .MuiFormControl-root{
        width: 120px;
        .MuiInputBase-root{
            &:hover{
                fieldset{
                    border-color: rgba(0, 0, 0, 0.23);
                }
            }
            border-radius: 10px !important;
            .MuiSelect-select{
                padding: 8px 15px;
            }
        }
    }
}
.topJobCandidateSect{
    .empty-data-card{
        top: 40%;
        height: 100px;
    }
}
.inSerUnserToolSect{
    display: flex;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    margin: 7px 0px;
    padding: 5px 10px;
    gap: 10px;
    p{
        margin: 0px;
        font-size: 12px;
        span{
            font-weight: 600;
        }
    }
}
.legend-items{
    gap: 10px;
    .legend-item{
        .itm-val{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            width: 100px;
        }
    }
}