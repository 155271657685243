.add-position-popup{
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300 !important;

    .MuiPopover-paper{
        top: calc(50vh - 230px) !important;
        left: calc(50% - 230px) !important;
    }
    .add-container{
        padding: 20px; 
        width: 500px;

        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                color: #000;
                font-family: Sora;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
        .body-conatiner{
            padding: 20px 0px;
            display: flex;
            flex-direction: column;
            gap: 25px;

            .MuiInputBase-input{

                .primary-text{
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;
                }
            
                .secondary-text{
                    color: #727376;
                    font-size: 12px;
                    font-weight: 400;
                }

            }
            .location-mandate-dropdown{
                .MuiCheckbox-root{
                    display: none;
                }
            }
        }

        .footer{
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            padding-top: 20px;
        }
        .add-warning{
            display: flex;
            font-size: 13px;
            font-weight: 400;
            gap: 10px;
        }
        .warning-msg{
            color: #727376;
        }
        .error-msg{
            color: #F34334;
        }
        .date-picker{
            svg{
                path{
                    fill: #727376;
                }
            }
        }
    }

    .MuiInputBase-root{
        border-radius: 10px !important;
      }
    
}
.selectedLocationJobOrder{
    display: flex;
    margin-top: 15px;
    gap: 10px;
    p{
        margin: 0px;
        background: #f0f6fb;
        padding: 3px 12px;
        border-radius: 40px;
        font-size: 13px;
        font-weight: 500;
        span{
            color: #002882;
            font-weight: 600;
        }
    }
}