.call-logs-wrap {
  width: auto;
  display: flex;
  justify-content: center;

  .rec-container {
    padding: 1rem 0rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

	.callLogToggle{
		display: flex;
		justify-content: end;
		p{
			font-size: 12px;
		}
	}
  }
  .record-row {
    display: grid;
    grid-template-columns: 30fr 30fr 30fr;
    column-gap: 20px;
    border: 1px solid #e5e8eb;
    border-radius: 10px;

    .log-details {
      padding: 10px;

      .view-notes {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #002882;
      }

			.no-record-url{
				width: 200px;
				background-color: #00288212;
				border-radius: 7px;
				display: flex;
				height: 100%;
				padding: 8px;
				justify-content: center;
				align-items: center;

				.no-record{
					font-weight: 400;
        	font-size: 12px;
					color: #000;
				}
			}
    }
  }

  .flex-row {
    display: flex;
    gap: 10px;
    align-items: center;
  }

	.primary-text{
		font-weight: 400;
		font-size: 13px;
		color: #000000;
	}
	
	.secondary-text{
		font-weight: 400;
		font-size: 12px;
		color: #727376;
	}

	.waveform-container{
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 100px;
		width: 100%;
		background: transparent;
		gap: 2rem;

		.play-button{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 60px;
			background: #efefef;
			border-radius: 50%;
			border: none;
			outline: none;
			cursor: pointer;
			padding-bottom: 3px;
		}

		.wave{
			width: 100%;
  		height: 90px;
		}
	}
	.loader-container{
		padding-top: 2rem;
	}
}
