@mixin header-row1-text {
    color: #4E4E4E;
    font-family: Sora;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
  }

  @mixin centerText {
    align-self: center;
    text-align: center;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;
  }

  .mandate-table-container{
    margin: 5px 0px;
    width: 100%;
    overflow-x: scroll;
    border-left: 1px solid #f3f3f7;
    border-bottom: 1px solid #f3f3f7;
    border-radius: 0px 0px 0px 10px;
  }

.mandate-table {
    width:96%;
    border-collapse: collapse;
    margin-left: -2px;
    margin-top: -2px;

    .mandate-table-head {
        background: #F3F3F7;
        width: 100%;
    
        .mandate-header-row1{
            height: 65px;
            width:100%;
            flex-shrink: 0; 
            th {
                padding: 6px 5px;
                border: 2px solid #FFFFFF;
                @include header-row1-text()
            }

            .mandate-header {
                width: 200px;
                background: #F3F3F7;
            }
            .allocation-header{
                width: 200px;
                flex-shrink: 0; 

            }
            // .Kpi-header {

            // }
        }

        .mandate-header-row2 {
            height: 40px;
            flex-shrink: 0;
            background-color: #F0F6FB;
            .add-mandate {

                color: #002882;
                font-family: Sora;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                gap: 6px;
                margin-left: 5px;
            }
            .sticky-left{
                background-color: #F0F6FB;
            }

            .plus-icon{

                fill:#002882
            }

            .kpi-header {
                color: #727376;
                text-align: center;
                font-family: Sora;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }


        }
    
    }

    .mandate-table-row{
     height:130px;
     td{
        padding: 6px 5px;
        .mandateTableLocationCardSect{
            width: 140px;
            overflow: hidden;
            .mandateTableLocationCard{                
                display: flex;
                width: 140px;
                flex-wrap: wrap;
                height: 26px;
                gap: 5px;
                overflow: hidden;
                position: relative;
                .MuiListItemText-root{
                    flex: none;
                }                
            }  
            > span{
                position: absolute;
                right: 0px;
                top: -2px;
            } 
        } 
        .mandateTableLocationCardSect.ellipseText{
            width: 120px;
        }
        &:nth-child(2){
            padding: 6px 15px;
            border: 1px solid #E5E8EB;
            border-top: none;
            border-bottom: none;
        }
        .noMandateFound{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 30px;
            color: #727376;
            margin: auto;
            svg{
                width: auto;
                height: auto;
            }
        }
     }
   
     .box {
        // margin: auto;
        width: 70px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid #CFD1D2;
         
     

        .job-count{
            @include centerText;
            width: 100%;
            color: #000;
        }
        .job-count-target{
            @include centerText;
            width: 100%;
            color: #000;
        }
    }



    .side-line{
        height: 30px;
        flex-basis: 3%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    
    .target p{
        color: #FFA866 !important;
    }
    .good p{
        color: #80CEA1 !important;
   
    }

    .critical p{

        color: #F88E85 !important;
    
    }
    .attention p{

        color: #FDD080 !important;
  
    }
    }

    .mandate-unassign-tag{
        opacity: 0.5;
    }
}

.mandate-header-row2{
    display: none;
}
.week-view-table .mandate-header-row2{
    display: table-row;
}
.week-view-table .weekViewSubHeaderSect{
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 54px;
    height: 35px;
    margin-left: 33px;
}
.week-view-table .mandate-header-row1 button{
    display: none;
}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
    .mandate-table-container{
        width: 1000px;
    }
    .week-view-table .weekViewSubHeaderSect {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 54px;
        height: 35px;
        margin-left: 33px;
    }
}
@media screen and (min-width: 1500px) {
    .mandate-table-container{
        width: 1100px;
    }
    .week-view-table .weekViewSubHeaderSect {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 70px;
        height: 35px;
        margin-left: 33px;
    }
}
