.tasklist {
  margin-left: 1rem;
}

.mar0{
  margin: 0;
}

.tasklist .tasklist-title {
  color: #000000 !important;
  font-size: 14px !important;
  line-height: 24px;
}

.tasklist .tasklist-count {
  margin-left: 0.2rem;
  color: #002882 !important;
  font-size: 14px !important;
  line-height: 24px;
}

.add-new-icon {
  margin-right: 10px;
}

.filter-box-style {
  border-radius: 10px !important;
  padding: 10px 20px;
  margin-top: 16px;
}

.button-grid {
  padding-top: 0px !important;
}
.filter-title {
  margin-bottom: 10px !important;
}
.reset-button {
  color: #002882 !important;
  text-transform: none !important;
}
.reset-label {
  margin-left: 5px !important;
}
.placeholder-text {
  position: absolute;
  margin-left: 25px !important;
  top: 7px !important;
  color: #bcbcbc;
}

.filter-parent-box {
  align-items: center;
}
.auto-complete-input-container .MuiAutocomplete-endAdornment
  {
    display: contents;
  }
  .submissionFilterEnableField{
    position: relative;
  }
  .submissionFilterEnableField label{
    position: absolute;
    top: -8px;
    left: 15px;
    transform: none !important;
    font-size: 12px;
    transition: none !important;
    background: white;
    padding: 0px 5px;
  }