
.active {

    .topline{
        background-color: #002882;
        width: calc(100% - 30px);
        height: 3px;
        // position: absolute;
        // top: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    
}


  @mixin centerText {
    align-self: center;
    text-align: center;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
  }
.recruiter-search {
    width: 200px;
    flex-shrink: 0;

    .MuiInputBase-formControl {
        border-radius: 10px;
        height: 45px;

    }
}

.week-view-container {
    padding: 30px 0px;
    .workManagStickyHead{
        position: sticky;
        top: -1px;
        z-index: 9;
        background-color: #fff;
        padding-top: 1px;
        .week-view-filter {
            height: 50px;
            display: flex;
            flex-direction: row;
            gap: 20px;
    
    
            .recruiter-search {
                width: 200px;
                flex-shrink: 0;
    
                .MuiInputBase-formControl {
                    border-radius: 10px;
                    height: 45px;
    
                }
            }
    
            .Kpi-tab{
                // flex-basis: 100%;
                border-radius: 10px;
                display: flex;
                flex-direction: row;
                background: #F3F3F7;
                .week-view-box-container {
                    justify-content: space-between;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    width: 170px;
                    height: 100%;
                    flex-shrink: 0;
                    border-radius: 10px;
                    border: 1px solid #E5E8EB;
                }
    
                .active {
                    background: #FFF;
    
                }
                .week-view-header-text {
                    @include centerText;
                    flex-basis: 100%;
                    padding: 10px;
                    color: #4E4E4E;
                }
    
              
            }
    
        }
        .week-view-header {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
    
            .my-team-container {
                display: flex;
                flex-direction: row;
                width: 210px;
                align-items: center;
    
    
                .my-team {
                    color: #000;
                    font-family: Sora;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                }
            }
    
            .week-view-header-text {
                @include centerText;
    
                color: #4E4E4E;
            }
            .week-view-box-container {
                width: 110px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
        
            }
    
        }
    }
    

}
.week-view-table-container {
    width: 100%;
    .week-view-table {
        // width: 1120px;
    
        &::-webkit-scrollbar {
            height: 6px;
        }
    }


}



.Target-container {

    width: 110px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .box {
        width: 70px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #CFD1D2;

    }

    .target {
        @include centerText;
        // border-left: 5px solid #FFA866;
        // width: 100%;
     

        color: #000;
        
    }
    .good{
        @include centerText;
        // border-left: 5px solid #80CEA1;
        // width: 100%;
        color: #000;
    }

    .critical {
        @include centerText;

        // border-left: 5px solid #F88E85;
        // width: 100%;
        color: #000;
    }
    .attention {
        @include centerText;

        // border-left: 5px solid #FDD080;
        // width: 100%;
        color: #000;
    }


}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
    .Target-container{
        width: 80px;
    }
    .week-view-container .Target-container{
        width: 60px;
    }
    .week-view-container .Target-container .box{
        width: 60px;
    }
    .week-view-container .workManagStickyHead .week-view-header .my-team-container{
        width: 405px;
    }
    .week-view-container .workManagStickyHead .week-view-header .week-view-box-container{
        width: 61px;
    }
    .week-view-container .workManagStickyHead .week-view-filter .Kpi-tab .week-view-box-container{
        width: 155px;
    }
}

@media screen and (min-width: 1500px) {
    .Target-container{
        width: 110px;
    }
    .week-view-container .Target-container{
        width: 60px;
    }
    .week-view-container .Target-container .box{
        width: 60px;
    }
    .week-view-container .workManagStickyHead .week-view-header .my-team-container{
        width: 420px;
    }
    .week-view-container .workManagStickyHead .week-view-header .week-view-box-container{
        width: 61px;
    }
    .week-view-container .workManagStickyHead .week-view-filter .Kpi-tab .week-view-box-container{
        width: 155px;
    }
}