.main-image {
    position: relative;
  }
  .people-effect {
    position: relative;
    margin-right: -15px;
  }

  @media (max-width: 1000px) {
    .main-image{
      width: 100%;
    }
    .people-effect{
      width: 60%;
    }
  }
  @media (max-width: 750px) {
    .people-effect{
      width: 100%;
    }
  }