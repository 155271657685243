/* .mandate-modalform {
  margin-bottom: 1rem !important;
} */

.modal-cancel-btn,
.modal-assign-btn {
  border-radius: 8px !important;
  font-size: 14px !important;
}

.task-list-checkbox.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #0275d8 !important;
}

.upload-document-label.MuiInputLabel-shrink {
  font-weight: 400;
  /* font-size: 12px; */
  color: #727376;
}

.hints-label.MuiFormHelperText-root {
  font-weight: 400;
  font-size: 12px;
  color: #727376;
  margin: 0;
  padding-left: 5px;
}

.mandatedetails-header.MuiTypography-h6 {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  padding: 0 16px;
  margin-top: 10px;
}

input[type='file'] {
  /* color: rgba(0, 0, 0, 0) */
  display:none;
}

.upload-custom-btn{
  display: inline-block;
  color:#002882;
  text-align: center;
  padding: 1rem;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
 border: dotted 1px #002882;
 background-color: #F0F6FB;
 border-radius: 8px
}

.upload-custom-btn:active{
  transform: scale(0.9);
}

.file-upload-text{
  margin-left: 0.5rem;
}

.error-message{
  font-size: 12px;
  color: #db3131;
  margin-left: 1rem;
}

.MuiFormLabel-root.MuiInputLabel-shrink.Mui-focused{
  color:#5e5b5d!important
}

.helper-boxtext{
  font-size: 12px;
  color:#5e5b5d ;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.form-spaces{
  margin-bottom:1.8rem !important;
}

.file-preview-name{
  font-size: 14px;
  color: #5e5b5d;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow-x: hidden;
 white-space: nowrap;
}

.action-btn-cls.MuiDialogActions-root{
  margin-top: 0;
  padding-top:0
}
/* .mandate-modal-style{
  overflow: hidden !important;
} */

.upload-approver-filed {
  max-height: 50px;
  /* overflow: none; */
  box-sizing: border-box;

  .MuiChip-filled + input::placeholder,
  .MuiAutocomplete-tag + input::placeholder{
    color: #fff;
  }

  .MuiTextField-root {
    // padding-top: 10px;
    border: 1.5px solid #c8d0d4;
    border-radius: 10px;
    .MuiInputBase-adornedEnd{
      padding: 6px 10px !important
    }

  }
  label{
    background-color: #fff;
    // top: -10px;
  }

  .MuiInputBase-formControl {
    max-height: 50px;
    overflow: auto;
    /* border: 1px solid; */
    // padding-top: 0;
  }

  .MuiInputBase-formControl fieldset {
    border: none;
    outline: none;
  }
  ::-webkit-scrollbar {
    // display: none;
    width: 6px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f4f5f6;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aaa;
    width:10px;
    text-overflow: ellipsis;
  }
}

.autofield-error{
  .MuiTextField-root {
    border-color: red !important;
    border-width: 1.5px !important;
  }
}
.upload-error-text{
  margin-left: 14px;
  color: #d32f2f;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
#menu-clientId{
  > .MuiPaper-root{
    width: 400px;
    li{
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: break-spaces;
    }
  }
}
.cv-profile-Modal{
  .MuiDialog-scrollPaper .MuiPaper-root{
    overflow: hidden;
    > h2{
      padding-bottom: 10px;
    }
    form{
      > .MuiDialogContent-root{
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;

        .cvProfileScrollSect{
        height: calc(100vh - 250px);
        overflow-y: auto;
        padding-right: 10px;
        .mandatedetails-header.MuiTypography-h6{
          padding-left: 0px;
          margin-bottom: 10px;
        }
        }
      }
    }
  }
  .cvProfileContactSect{
    .form-spaces{
      margin-bottom: 20px !important;
    }
      input[type=number]::-webkit-inner-spin-button, 
      input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
  }
      
  }
  .cvUploadErrorMsg{
    font-size: 12px;
    color: red;
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
  
    svg{
      font-size: 14px;
    }
  }
  .uploadCvSection {
    margin-bottom: 20px;
    > p{
      font-size: 12px;
      margin: 0px;
      margin-bottom: 10px;
      color: #5e5b5d;
      display: flex;
  
      svg{
        font-size: 6px;
        color: red;
      }
    }
  }
  .cvUploadRemarks{
    margin-top: 30px;
    > .MuiFormControl-root{
      width: 100%;
      > .MuiInputBase-root{
        border-radius: 10px;
      }
      > .MuiInputBase-root.Mui-focused,.MuiInputBase-root:hover{
          fieldset{
            border-color: rgba(0, 0, 0, 0.23);
            border-width: 1px;
          }
        
      }
    }
  }
}