.drawer-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: space-between;
  padding: 5px 10px 0px 10px;
}
.navTogglefalse .drawer-header{
  padding-top: 0px !important;
}
.drawer-header .MuiButtonBase-root svg{
  width: 20px;
}
.navTogglefalse .drawer-header > .MuiButtonBase-root{
  right: -6px;
  padding: 6px 8px 4px 9px;
  background-color: #F3F3F7;
}
.navTogglefalse .drawer-header > .MuiButtonBase-root svg{
  width: 15px;
}
.logo {
  width: 126px;
  height: 60px;
}
.list {
  height: 100%;
}
.router-item {
  height: 40px;
  margin-bottom: 8px !important;
  padding: 0px 10px;
}
.noLinkMenu{
  cursor: auto;
}
.default-router-item {
  padding-left: 8px !important;
  height: 40px !important;
}
.router-icon-button {
  min-width: 22px !important;
}
.rightMenuSection.active{
  background: rgb(240,246,251);
  position: relative;
}
.rightMenuSection.active::before{
  content: "";
  width: 4px;
  height: 100%;
  left: 0px;
  position: absolute;
  background-color: rgb(0, 40, 130);
  border-radius: 0px 10px 10px 0px;
}
.router-icon {
  height: 18px !important;
  width: 18px !important;
}
.route-title span{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0px !important;
  white-space: break-spaces;
}
.route-title-parent span{
  padding-left: 0px !important;
  color: #727376;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase; 
}

.selected-route {
  background-color: #E5E8EB !important;
  height: 40px !important;
  border-radius: 5px !important;  
  padding-left: 10px !important;
}
.navTogglefalse .xpheno-navigationList .selected-route{
  width: 45px !important;
}
.selected-route .router-icon-button .MuiIconButton-root svg path{
  fill: #000;
}

.selected-route-highlighter {
  background: #ff6e00;
  border-radius: 0px 10px 10px 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 32px;
  width: 5px;
  right: 0px;
  position: absolute;
}
.expand-icon {
  padding: 10px;
}
.xpheno-navigationList{
  overflow: auto !important;
}
.xpheno-navigationList .MuiCollapse-root a.active li .route-title .MuiTypography-root,
.xpheno-navigationList > div > a.active li .route-title .MuiTypography-root{
  font-weight: 500;
}
.navTogglefalse .xpheno-navigationList .MuiCollapse-root a .router-item .MuiButtonBase-root{
  padding-left: 5px;
}
.navTogglefalse .xpheno-navigationList .MuiCollapse-root a.active .router-item .MuiButtonBase-root{
  padding-left: 1px !important;
}
.navTogglefalse .xpheno-navigationList > div > a.active .router-item .MuiButtonBase-root{
  padding-left: 7px !important;
}
.navTogglefalse .xpheno-navigationList .MuiCollapse-root a.active .router-item .MuiButtonBase-root .router-icon-button {
  background-color: #E5E8EB !important;
  height: 40px !important;
  border-radius: 5px !important;
  padding-left: 10px !important;
  width: 40px;
}
.navTogglefalse .xpheno-navigationList .MuiCollapse-root a.active .router-item .MuiButtonBase-root .router-icon-button svg{
  fill: #002882;
}
.navTogglefalse > .MuiPaper-root{
  overflow: visible !important;
}
.navTogglefalse > .MuiPaper-root .xpheno-navigationList .subMenuRoot a .router-item .MuiButtonBase-root{
  width: 45px !important;
  padding-left: 3px !important;
}
.navTogglefalse > .MuiPaper-root .xpheno-navigationList .subMenuRoot + .subMenuLevelMenu,
.navTogglefalse > .MuiPaper-root .xpheno-navigationList .subMenuRoot.subMenuHighlight > a > li > .MuiButtonBase-root::after{
  display: none;
}
.subMenuLevelMenu > div > a > li > .MuiButtonBase-root {
  padding-left: 20px !important;
  background-color: transparent !important;
}
.subMenuLevelMenu > div > a > li > .MuiButtonBase-root .router-icon-button{
  min-width: 15px !important;
}
.subMenuLevelMenu > div > a > li > .MuiButtonBase-root .router-icon-button button{
  background: #727376;
  padding: 2px;
  margin-top: -4px;
}
.disableMenu{
  display: none;
}
.subMenuLevelMenu > div >a.active > li > .MuiButtonBase-root .router-icon-button button{
  background: #002882;
}
.subMenuLevelMenu > div >a.active > li > .MuiButtonBase-root .route-title > span{
  color: #002882
}
.subMenuLevelMenu > div >a.active > li > .MuiButtonBase-root .selected-route-highlighter{
  display: none;
}
.subMenuHighlight > a > li > .MuiButtonBase-root{
  background: #e5e8eb;
    border-radius: 6px;
    position: relative;
}
.subMenuHighlight >a > li > .MuiButtonBase-root .router-icon-button button svg path{
  fill:#000;
}
.subMenuRoot.Dashboard .router-icon-button svg path{
  fill: none !important;
}
.subMenuHighlight >a > li > .MuiButtonBase-root .route-title > span{
  font-weight: 500;
}
.subMenuHighlight > a > li > .MuiButtonBase-root::after{
  content: "";
  background: #ff6e00;
  border-radius: 0px 10px 10px 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 32px;
  width: 5px;
  right: 0px;
  position: absolute;
}