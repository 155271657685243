.candidate-header-wrapper {
  border-radius: 10px;
  background: #fff;

  .Mui-focused{
    .MuiOutlinedInput-notchedOutline{
      border-color: #D9D9D9 !important;
      border-width: 1px !important;
    }
  }

  .MuiOutlinedInput-notchedOutline{
    border-color: #D9D9D9 !important;
    border-width: 1px !important;
  }

  .MuiOutlinedInput-notchedOutline:hover{
    border-color: #D9D9D9 !important;
    border-width: 1px !important;
  }

  .header-tabs {
    display: flex;
    border-bottom: 1px solid #e5e8eb;

    .tab-container {
      padding: 0 10px;
      border-right: 1px solid #d9d9d9;
      cursor: pointer;
      .tab {
        display: flex;
        gap: 10px;
        padding: 12px 0;
        p {
          color: #000;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .tab-container:last-child{
      border: none;
    }

    .active-tab {
      .tab {
        svg {
          path {
            fill: #002882;
            stroke: #002882;
          }
        }
        p {
          color: #002882;
        }
      }
    }
    .active-tab::before {
      content: '';
      height: 3px;
      width: 100%;
      background-color: #002882;
      display: block;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .no-active::before {
      content: '';
      height: 3px;
      width: 100%;
      background-color: #fff;
      display: block;
    }
  }

  .filter-container {
    padding: 20px;

    .candidateTabKeyword input {
      padding: 14px 14px;
    }
    .candidateTabSource input{
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .candidateTabSource .MuiOutlinedInput-notchedOutline{
      height: 53px !important;
    }
    .candidateTabSource > .MuiFormControl-root > {
      
      // label{
      //   transform: translate(14px, 16px) scale(1);
      // }
      .MuiSelect-select{
        padding: 11px;
      }
    }

    .MuiTextField-root {
      width: 100%;

      .MuiInputBase-formControl {
        border-radius: 10px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .footer-btn {
      padding-top: 15px;
      border-top: 1px solid #e5e8eb;
      display: flex;
      justify-content: end;
      gap: 10px;

      .submit-btn {
        border-radius: 8px;
        background: #000;
        text-transform: none;
        color: #fff;
        min-width: 96px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
      .reset-btn {
        text-transform: none;
        min-width: 96px;
        color: #002882;
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          margin-left: 4px;
        }
      }
    }

    .candidate-filters-wrapper {
      width: 30%;
      .MuiFormControl-root {
        width: 100%;
        .MuiInputBase-root {
          width: 100%;
          border-radius: 10px;
        }
      }
    }

    .tag-auto-dropdown {
      min-width: 300px;
      max-width: 350px;

      .MuiInputLabel-root {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .rpaSearchSelect {
      min-width: 300px;
      max-width: 300px;
      border-radius: 10px;
      // color: #B3B5B7;
      font-size: 14px;
      font-weight: 400;
    }
    .label-tag-rpa{
      font-size: 14px;
      font-weight: 400;
    }

    .candidate-loc-dropdown{
      border-radius: 10px
    }

    .confidence-wrapper {
      margin-top: 10px;
      .confidence-formctl {
        width: 30%;

        .label-of-Score {
          color: #727376;
          font-size: 12px;
          font-weight: 400;
        }

        .candidate-slider {
          padding-left: 10px;

          .select-container {
            .MuiInputBase-root {
              border-radius: 10px;
              color: #000;
              font-size: 13px;
              font-weight: 400;
            }
            .MuiInputLabel-outlined {
              color: #727376;
              font-size: 13px;
              font-weight: 400;
            }
          }
        }
      }
      .MuiSlider-rail {
        background: #d9d9d9;
      }
      .MuiSlider-track {
        color: #ff6e00;
      }

      .MuiSlider-thumb {
        border: 1px solid #ff6e00;
        background-color: #fff;
      }

      .MuiSlider-root {
        margin-top: 2.4rem;
        .MuiSlider-markLabelActive {
          color: #727376;
          font-size: 12px;
          font-weight: 400;
        }
      }

      .mandate-slider {
        display: flex;
        gap: 20px;
        align-items: center;

        .range-slider {
          width: 50%;
        }

        .range-select-container {
          width: 50%;
          margin-top: unset !important;
        }

        .MuiSlider-colorPrimary {
          color: #ff6e00;
        }

        .MuiSlider-thumb {
          width: 15px;
          height: 15px;
          background-color: #fff;
          border: 1px solid #ff6e00;
        }

        .MuiSlider-valueLabelOpen {
          transform: translateY(100%) scale(1) !important;
          top: 0px !important;
          background-color: #000000;
        }

        .MuiSlider-valueLabelOpen::before {
          top: -8px;
        }
      }
    }
  }
}

.artemisIntelligentSect{
  display: flex;
  gap: 30px;
  .MuiTextField-root{
    label:not(.MuiFormLabel-filled):not(.Mui-focused){
      transform: translate(14px, 14px) scale(1);
    }
    input{
      height: 40px;
    }
  }  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }
}
.artemisIntellTab{
  > .MuiFormControl-root{
    .selected-container{
      display: none;
    }
  }
}