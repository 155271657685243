.dashboardWidgetSmallCard{
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 10px;
    border-radius: 10px !important;
    box-shadow: none !important;
    .dashWidgetHeader{
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        h6{
            font-size: 16px;
            font-weight: bold;
            margin-top: 10px;
        }
        .dashWidgetActionSect{
            display: flex;
            column-gap: 5px;
            padding-top: 5px;
            .launchIcon{
                margin-top: 7px;
                margin-right: 5px;
                cursor: pointer;
            }
        }
    }
}
// .offerMgtWidgetSect svg > g > g:nth-child(3) text{
//     font-size: 13px !important;
//     fill: #727376 !important;
// }
.offerMgtWidgetSect svg > g > g:nth-child(2) text tspan{
    fill: #727376 !important;
}