
.candidate-main-header {
    display: flex;
    justify-content: space-between;
}
.rpa-search-checkbox {.MuiFormControlLabel-label {
    font-style: normal;
    font-size: .7rem;
    white-space: nowrap;

    color: #002882;
}}
    .candidate-header-search-section-box {
        .search-header-tab {
            padding: 10px;
            border-right: 1px solid #E5E8EB;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
           svg{
            path{
                // fill:#727376;
                stroke: #727376;
            }
           }
        }
        .selected-keyword-tab{
            color: #002882;
            svg{path{
                fill:#002882;
                stroke: #002882;
            }
            }
        }
        .selected-mandate-tab{
            color: #002882;

            svg{path{
                stroke: #002882;
            }
            }
        }
        .first-level-filters-container {
            border-top: 1px solid #E5E8EB;
            display: flex;

            .dropdownSubtext {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #727376;
            }
            .mandate-filter-dropdown {
                .MuiAutocomplete-inputRoot {
                    border-radius: 10px;
                    height: 50px;
                    // height: 2.325rem;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    padding-right: 0px;

                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid #E5E8EB;

                    }

                    .MuiAutocomplete-input {
                        font-size: 0.7rem;

                    }
                }
            }

            .search-keyword-box-container {
                display: flex;
                align-items: center;
                box-shadow: none;
                border: 1px solid #E5E8EB;
                border-radius: 10px;
                height: 50px;
                flex: 1;

                .search-icon {
                    font-size: 1.1rem;
                }

                .search-keyword-box-input {
                    font-size: 0.7rem;
                    width: 100%;
                    padding-right: 1rem;
                }

                .boolean-search-form-group {
                    .boolean-search-label {
                        .MuiFormControlLabel-label {
                            font-style: normal;
                            font-size: .7rem;
                            white-space: nowrap;
        
                            color: #002882;
                        }
                    }
                    .boolean-search-icon {
                        margin-top: 0.2rem;
                        font-size: 1.2rem;
                    }
                }
    

            }


        }

        .MuiAutocomplete-endAdornment
        {
            display: contents;
        }


        
        .candidate-filters-wrapper {
            margin-top: 1.0625rem;
            margin-bottom: 1.0625rem;

            &.mandate-filters-wrapper {
                margin-bottom: 3rem;
            }
            .match-criteria-dropdown {
                overflow-x: hidden;
                overflow-y: auto;

                .MuiAutocomplete-inputRoot {
                    border-radius: 10px;
                    height: 50px !important;
                    // height: 2.325rem;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    padding-right: 0px;

                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid #E5E8EB;
                    }

                    .MuiAutocomplete-input {
                        font-size: 0.7rem;

                    }
                }
            }
            .candidate-filter-title {
                font-style: normal;
                font-weight: 600;
                font-size: 0.8rem;

                color: #000000;
            }
            .candidate-filter-inputs-container {
                margin-top: 1.0625rem;
                display: flex;
                .rpaSearchQuery{
                    height: 50px !important;
                    border-radius: 0.625rem;
                    // border: 1px solid #E5E8EB;
                    font-size: 0.7rem;
                    .MuiOutlinedInput-notchedOutline {
                        border: 1px solid #E5E8EB;
                        // border:none ;

                    }
                
                }
                .MuiInputLabel-root {
                    font-size: 0.7rem;
                    line-height: 0.7rem;
                    color: #B3B5B7;
                    margin-left: 0rem;
                }
                .MuiInputBase-root {
                    height: 2.325rem;
                    // border: 1px solid #D9D9D9;
                    border-radius: .625rem;
                    font-size: 0.7rem;
                    color: #B3B5B7;
                }
                .confidence-score-form-control {
                    margin-left: 1.7rem;
                    margin-right: 0.8rem;
                    .label-of-Score {
                        margin: -20px 0 0 -20px !important;
                    }
                    .MuiSlider-rail {
                        background: #D9D9D9;
                    }
                    .MuiSlider-track {
                        color: #FF6E00;
                    }

                    .MuiSlider-thumb {
                        border: 1px solid #FF6E00;
                        background-color: #fff;
                    }

                    .MuiSlider-root {
                        margin-top: 0.8rem;
                    }

                    .mandate-slider {
                        display: flex;
                        gap: 20px;
                        align-items: center;

                        .range-slider {
                            width: 50%;
                        }

                        .range-select-container {
                            width: 50%;
                            margin-top: unset !important;
                        }

                        .MuiSlider-colorPrimary {
                            color: #FF6E00;
                        }

                        .MuiSlider-thumb {
                            width: 15px;
                            height: 15px;
                            background-color: #fff;
                            border: 1px solid #FF6E00;
                        }

                        .MuiSlider-valueLabelOpen {
                            transform: translateY(100%) scale(1) !important;
                            top: 0px !important;
                            background-color: #000000;
                        }

                        .MuiSlider-valueLabelOpen::before {
                            top: -8px;
                        }

                        .select-container {
                            margin-top: unset !important;
                            padding: 50px;
                        }
                    }
                }
                .sourcing-tag-autocomplete {
                    overflow-y: auto;
                    overflow-x: hidden;
                    border-radius: 0.625rem;
                    margin-top: -2.5px;
                    border: 1px solid #E5E8EB;
                    .MuiAutocomplete-inputRoot {
                        border-radius: 10px;
                        height: 50px !important;
                        // height: 2.325rem;
                        padding-top: 0px;
                        padding-bottom: 0px;
                        padding-right: 0px;
            
                        .MuiOutlinedInput-notchedOutline {
                            border: none;
            
                        }
            
                        .MuiAutocomplete-input {
                            font-size: 0.7rem;
            
                        }
                    }
                    .MuiInputBase-adornedEnd {
                        padding: 0 !important;
                    }
            
                    .MuiInputBase-fullWidth {
                        // height: fit-content !important;
                        overflow-y:auto ;
                        overflow-x: hidden;
                    }
                    .MuiInputBase-adornedEnd {
                        padding: 0;
                       
                    }
                    
            
                    .MuiAutocomplete-endAdornment {
                        display: flex;
                        top: 50% !important;
                        right: 7px !important;
                        transform: translate(0, -42%) !important;
                        // position: initial;
                    }
            
                    input {
                        color: #000000;
                      
                    }
                    .MuiInputBase-root{
                        padding: 2.5px 0 0 0;
                        fieldset{
                            top: -3px;
                            legend{
                                width: 0;
                                span{
                                    opacity: 1;
                                }
                            }
                        }
                        &.Mui-focused{
                            legend{
                                width: auto;
                                opacity: 100;
                                visibility: visible;
                                overflow: visible;
                                // background-color: #002882;
                            }   
                        }
                    }
                    &.MuiAutocomplete-hasClearIcon{
                        label{
                            display: none;
                        }
                    }
                    .MuiInputLabel-root {
                        font-size: 0.7rem;
                        line-height: 0.7rem;
                        color: #B3B5B7;
                        margin-left: 0rem;
                        &:not(.Mui-focused){
                            transform: translate(14px, 12px) scale(1);
                        }
                    }
                }
            }

            
            
        }

        .candidate-filters-header-footer {
            padding: 0px 20px 20px 20px;
            .filter-button-group {
                align-items: center;
                display: flex;
            }
            .filter-submit-button {
                width: 5rem;
                height: 1.9rem;
                margin-left: 1rem;


                background: #000000;
                border-radius: 8px;

                font-size: 0.7rem;
                color: white;
                text-transform: none;
            }
            .filter-reset-button {
                padding: 0rem;
                width: auto;
                margin-left: 1rem;
                svg {
                    font-size: 0.6rem;
                }
                .filter-reset-label {
                    margin-left: 0.4rem;
                    font-size: 0.7rem;
                    color: #002882;
                    text-transform: none;
                }
            }
        }

    }
    * ::-webkit-scrollbar {
        width: 5px;
    }

    * ::-webkit-scrollbar-track {
        border-radius: 10px;

    }

    * ::-webkit-scrollbar-thumb {
        background: #DBDBDB;
        border-radius: 10px;
    }


.header-tabs{
    display: flex;
    border-bottom: 1px solid #E5E8EB;

    .tab-container{
        padding: 0 15px;
        border-right: 1px solid #D9D9D9;
        cursor: pointer;
        .tab{
        display: flex;
        gap: 10px;
        padding: 12px 0;
        p{
            color: #000;
            font-size: 14px;
            font-weight: 400;
        }
        }
    }

    .active-tab{
        .tab{
        svg{
            path{
            fill: #002882;
            stroke: #002882;
            }
        }
        p{
            color: #002882;
        }
        }
    }
    .active-tab::before{
        content: "";
        height: 3px;
        width: 100%;
        background-color: #002882;
        display: block;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .no-active::before{
        content: "";
        height: 3px;
        width: 100%;
        background-color: #fff;
        display: block;
    }
    }