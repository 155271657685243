.artemisIntelligentSkillSect{
    overflow: auto;
    max-height: 300px;
    padding-right: 0px;
    .artemisIntelligentSkillTable{
        border: 1px solid #E5E8EB;
        box-shadow: none;
        table{
            thead{
                tr{
                    background-color: #F3F3F7;
                    th{
                        padding: 9px 15px;
                        font-weight: 400;
                        font-size: 12px;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        .MuiSlider-root{
                            margin-top: 0px !important;
                            width: 95%;
                        }
                        .numberFieldSectIncDec{
                            height: 40px;
                            button{
                                height: 20px;
                            }
                            input{
                                height: 40px;
                            }
                        }
                    }
                    &:last-child{
                        td{
                            border-bottom: 0px;
                        }
                    }
                }
            }
        }
        .artemisSkillNumberField{
            border: 1px solid #D9D9D9;
            border-radius: 10px;
            overflow: hidden;
            width: 200px;
            .MuiTextField-root{
                fieldset{
                    border: none;
                }
            }
            .actionBtn{
                .MuiIconButton-root{
                    background-color: #d9d9d9;
                    width: 30px;
                    height: 20px;
                    &:nth-child(1){
                        border-radius: 3px 0px 0px 0px;
                        padding-bottom: 0px;
                    }
                    &:nth-child(2){
                        padding-top: 0px;
                        border-radius: 0px 0px 0px 3px;
                    }
                }
            }
            
        }
    }
    
}
.visiblityHidden{
    visibility: hidden;
    position: absolute;
}