.modalbox-container {
    position: absolute;
    background-color: white;
    width: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top-style: solid;
    border-top-color: #FF6E00;
    text-align: center;
    justify-items: center;
    padding: 2px 10px;
}
.confirmationAlertModal{
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}
.confirmationAlertModal:focus{
    outline: none;
}
.confirmationAlertModal p{
    font-weight: 600;
}
.confirmationAlertModal h4{
    font-weight: 500;
    margin-top: 15px;
}
.confirmationAlertModal h4 span{
    font-weight: 700;
    color: #0E94F4;
}
.confirmationAlertModal .confirmationCloseBtn{
    background: #E5E8EB;
    font-size: 14px;
    padding: 10px 30px;
    height: auto;
}
.confirmationAlertModal .confirmationActionBtn,.confirmationAlertModal .confirmationActionBtn:hover{
    background: #002882;
    font-size: 14px;
    padding: 10px 30px;
    height: auto;
    color: #fff;
    margin-left: 15px;
}
.confirmationAlertModal .confirmationActionBtn.Mui-disabled{
    opacity: 0.5;
    color: #fff;
}