.each-experience-card {
    position: relative;

    .svgcontainer {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px;
        z-index: 20;
        background-color: #F3F3F7;

        img {
            padding: 8px 8px 5px 8px;
        }
    }

    .svgcontainer-active {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #E3F4EB;
        border-radius: 4px;
        z-index: 20;

        img {
            padding: 8px 8px 5px 8px;
        }
    }

    .experience-content,
    .experience-content-last {
        border-left: 1px dashed #B3B5B7;
        margin-left: 17px;
        padding-left: 20px;
        margin-top: 5px;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .exp-title {
            display: flex;
            flex-direction: column;

            .head {
                font-weight: 500;
            }

            .date {
                font-size: 13px;
                color: #727376;
            }
        }

        .description {
            font-size: 12px;
        }

        .rolesandresp {
            .head {
                font-size: 12px;
                color: #727376;
            }

            ul {
                padding-left: 20px;
                list-style: url("../../../../assets/icons/Polygon.svg");

                li {
                    font-size: 13px;
                }
            }
        }

        .on-page {
            .head {
                font-size: 12px;
                color: #727376;
            }

            ul {
                padding-left: 20px;
                list-style: url("../../../../assets/icons/Polygon.svg");

                li {
                    font-size: 13px;
                }
            }
        }

        .off-page {
            .head {
                font-size: 12px;
                color: #727376;
            }

            ul {
                padding-left: 20px;
                list-style: url("../../../../assets/icons/Polygon.svg");

                li {
                    font-size: 13px;
                }
            }
        }

    }

    .experience-content-last {
        border: none;
    }
}
.workexperience-wrap{
    margin-top: 20px;
    .each-experience-card{
        .experience-content,.experience-content-last{
            padding-top: 0px !important;
            padding-bottom: 15px;
            padding-left: 30px;
        }
    }
    .candidateWorkExper .experience-content-last,
    .candidateWorkExper .experience-content{
        gap: 0px;
    }
}