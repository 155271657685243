.task-detail-drawer .MuiDrawer-paper {
  width: 45%;
}

@media (max-width: 800px) {
  .task-detail-drawer .MuiDrawer-paper {
    width: 100% !important;
  }
}

.task-detail-box {
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.task-detail-box .task-status-split {
  display: flex;
  justify-content: space-between;
}
.task-detail-box .task-status-split .task-status-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}
.task-detail-box .task-status-split .task-left-title{
  width: calc(100% - 160px);
}
.task-detail-box .task-status-split .task-left-title .task-detail-title{
  max-width: calc(100% - 150px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-detail-box .task-detail-title {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18px;
  display: inline-block;
  padding-right: 0.5rem;
  max-width: 100%;
  word-wrap: break-word;
}

.task-detail-box .task-detail-date {
  color: #727376 !important;
  font-size: 13px !important;
  display: inline;
  line-height: 16px;
  font-weight: 400 !important;
}

.task-detail-box .task-assign {
  margin: 0.3rem 1rem 0.8rem 0rem;
}

.task-assign .task-user-icon {
  margin-right: 0.4rem;
}

.task-detail-box .task-assigner {
  align-items: flex-start;
  font-size: 12px;
  line-height: 15px !important;
  color: #727376 !important;
}

.task-divider {
  border-color: #e5e8eb !important;
}

.task-detail-box .task-description {
  font-size: 13px !important;
  color: #000 !important;
  line-height: 16px !important;
  max-width: 100%;
  word-wrap: break-word;
}

.task-detail-box .task-des-box {
  margin: 1.5rem 0rem 2rem 0rem;
}
.task-detail-box .task-des-box-status {
  margin-top: -15px;
}

.task-des-box .task-detail-profile {
  margin-top: 0.8rem;
}

.user-task-profile {
  border-radius: 10px;
  height: 45px;
  width: 45px;
}

.task-employee-name,
.task-employee-role {
  font-size: 14px !important;
  line-height: 18px !important;
  cursor: pointer !important;
}

.task-employee-name {
  color: #000 !important;
  font-weight: 400 !important;
  max-width: 100%;
  word-wrap: break-word;
  cursor: pointer !important;
}

.task-employee-role {
  color: #727376 !important;
}

.task-footer-content {
  margin-left: 0.6rem;
}

.task-status-box {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  width: 108px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.task-status-text {
  font-size: 12px !important;
  line-height: 15px !important;
  color: #000 !important;
}

.status-color {
  border: 4px solid #2bad63;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}

.action-icons {
  height: 25px;
  width: 25px;
  ;
}

.assignee-list {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

.assignee-name {
  max-width: 300px;
  width: fit-content;
  height: fit-content;
  background: #F3F3F7;
  border-radius: 8px;
  font-family: 'Sora';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: #000000;
  padding: 8px 20px;
  margin: 5px 0 0 0;
}

.date-text {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #727376;
}

.status-wrap{
  display: flex;
  gap: 10px;
}
.status-wrap .svgcontainer {
  padding-top: 8px;
  position: relative;
}
.status-wrap .svgcontainer::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 29px;
  transform: translateX(-50%);
  width: 1px;
  border-right: 1px dashed #B3B5B7;
  height: calc(100% - 21px);
}
.status-wrap .svgcontainer.active::after {
  border-color: #2bad63;
}
.status-wrap:last-child .svgcontainer::after {
  border: none;
}
.dropdown-status .MuiSelect-select{
  padding: 0;
}
.dropdown-status fieldset{
  border: none;
  outline: none;
}
.title-of-task{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}