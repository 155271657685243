body{
    .joAgeingBlkSect{
        > h3{
            font-size: 16px;
        }
        .hiringTableTopSect{
            background-color: #fff;
            border-radius: 10px !important;
            padding-top: 0px;
            .reportTableContSect{
                background-color: transparent;
                border-radius: 0px;
            }            
            .hiringTablePaginationSect{
                border-top: 1px solid rgba(224, 224, 224, 1);
                margin-left: 15px;
                margin-right: 15px;
            }
        }
        .joAgeningToggleWidget {
            background-color: #fff;
            border-radius: 10px;
            .MuiToggleButtonGroup-root{
                button{
                    border: none;
                    background: none;
                    text-transform: capitalize;
                    display: flex;
                    gap: 10px;
                    padding: 12px 15px;
                    &:nth-child(1){
                        border-right: 2px solid #ececec;
                    }
                    &:hover{
                        background-color: transparent;
                    }
                }
                button.Mui-selected{
                    color: #002882;
                    position: relative;
                    &::before{
                        content: "";
                        width: 96%;
                        height: 4px;
                        background-color: #002882;
                        position: absolute;
                        top: 0px;
                        border-radius: 3px 3px 10px 10px;
                    }
                }
            }
        }
        .dashboardFilterHeaderTitle {
            margin-top: -49px;
        }
        .hiringTableTopSect{
            padding-left: 0px;
            padding-right: 0px;
            margin-top: 10px;
            .hiringTableSect{
                padding: 15px;
            }
            .submissionReportContainer.hiringDemandTableSect {
                max-height: calc(100vh - 300px) !important;
                height: auto;
                thead{
                    tr{
                        &:not(:first-child){
                            th{
                                &:nth-child(1){
                                    background-color: #f5f1fb !important;
                                }
                                .customehiringdemand{
                                    .MuiInputBase-root{
                                        background: #fff;
                                    }
                                }
                            }
                        }     
                        th:first-child {
                            position: sticky;
                            left: 0;
                            z-index: 2;
                            background: #f3f3f7;
                          }               
                    }
                }
                tbody{
                    tr{
                        td{
                            &:nth-child(1){
                                background-color: #f5f1fb;
                                position: sticky;
                                left: 0;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
        .dashboardFilterHeader {
            margin: 0px;
        }
        .dashboardFilterHeader + .MuiBox-root{
            margin-top: 10px;
        }
    }
}
.emptyJoAgeing{
    .submissionReportContainer{
        overflow: hidden;
        .empty-data-card{
            width: 500px;
            left: 320px;
            top: 20%;
        }
    }
}
.joAgeingBlkSect {
    .DateRangeField{
        .datePicker{
          .MuiInputBase-root{
            input{
              padding: 8px 10px;
            }        
          }
          label:not(.Mui-focused):not(.MuiFormLabel-filled){
            transform: translate(14px, 9px) scale(1);
            font-size: 14px;
        }
        }
    }
}