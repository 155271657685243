.task-list-text .MuiListItemText-primary {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    color: #000;
    width: 100%;
}

.MuiTypography-root.task-list-date {
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: #000;
    display: inline-block;
}

.mandate-full-page-one {
    // display: flex;
    // justify-content: space-between;
    // /* width: 20%; */
    display: grid !important;
    // grid-template-columns: 550px 250px 150px 100px !important;
    grid-template-columns:minmax(min-content, 40%) minmax(15%, 1fr)minmax(10%, 1fr)  minmax(13%, 1fr) minmax(12%, 1fr) minmax(10%, 1fr)!important;
    align-items: center;
    gap: 20px;

    .task-name{
        width: 350px;
    }
}

.mandate-full-page-task {
    // margin-right: 10rem !important;
    background-color: transparent !important;
}
.assignees-avatar-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}
.task-basic-info-container {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    max-width: 360px;
}

.MuiTypography-root.task-name {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.task-list-assignee-heading {
    font-weight: 400;
    font-size: 12px !important;
    line-height: 15px;

    color: #727376;
}
.task-list-secondary-text {
    display: flex !important;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    .avatarGroup{
        .css-1yxmx0u-MuiAvatar-root-MuiAvatarGroup-avatar{
            width: 30px !important;
            height: 30px !important;
            font-size: small;
        }
        .task-assignee-avatar:hover{
            margin-top: -5px;
        }
    }

    .task-list-assignee-list-text-nowrap {
        font-weight: 400;
        font-size: 14px !important;
        line-height: 18px;
        color: #000000;
        display: inline-block;
        width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .expand-assignee{
        background-color: transparent;
        color: #000;
        border: none;
        padding: 0;
    }
}
.task-list-assignee-list-text {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
    color: #000000;
    display: inline-block;
    width: 100%;
    padding-right: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}


.MuiTypography-root.task-date {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    margin-left: 0.5rem;
    display: inline-block;
    white-space: nowrap;
}

.MuiTypography-root.task-assigner-name {
    margin-left: 5px;
    font-size: 12px;
}

.task-list-checkbox.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
    color: #0275d8;
}

.edit-icon {
    margin-right: 5px !important;
}

.edit-read-only-icon,
.delete-read-only-icon {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.search-no-results-found {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
}

.no-result-found {
    text-align: center;
    /* height: 100vh; */
}

.task-loading-container,
.load-more-section-loading-container {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
}

.load-more {
    text-align: center;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    margin-top: 20px !important;
}

.load-more-container {
    cursor: pointer;
}

.load-more-icon {
    margin-left: 5px;
}

.delete-edit-wrapper{
    // margin-left: 40px;
    display: flex;
    align-items: center;
}
.status-with-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .mandate-full-page-one {
        display: flex;
        width: 50%;
        justify-content: space-between;
    }
}
@media only screen and (max-width: 1400px) {
    .task-basic-info-container {
        max-width: 320px;
    }
}
@media only screen and (max-width: 1220px) {
    .task-basic-info-container {
        max-width: 280px;
    }
    .mandate-full-page-one {
        grid-template-columns: minmax(min-content, 40%) minmax(20%, 1fr) minmax(20%, 1fr)  minmax(10%, 1fr) minmax(10%, 1fr)!important;
    }
}
@media only screen and (max-width: 1000px) {

    .task-basic-info-container {
        max-width: 280px;
    }
    .mandate-full-page-one {
        grid-template-columns: minmax(50%, 1fr) minmax(50%, 1fr) !important;
    }
    .status-with-buttons{
        justify-content: space-between;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .delete-edit-wrapper{
        margin-right: 20px;
    }
}
@media only screen and (max-width: 800px) {
    .task-basic-info-container {
        max-width: 100%;
    }
    .mandate-full-page-one {
        grid-template-columns: 100% !important;
    }
    .status-with-buttons{
        justify-content: space-between;
        grid-column-start: 1 !important;
        grid-column-end: 1 !important ;
    }
    
}

