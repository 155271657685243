@mixin centerText {
    align-self: center;
    text-align: center;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;
  }
.recruiter-search {
    width: 200px;
    flex-shrink: 0;

    .MuiInputBase-formControl {
        border-radius: 10px;
        height: 45px;

    }
}

.day-view-container {
    padding: 30px 0px;

    .workManagStickyHead {
        position: sticky;
        top: -1px;
        z-index: 9;
        background-color: #fff;
        padding-top: 1px;
        
        .day-view-filter {
            display: flex;
            flex-direction: row;
            gap: 20px;
            height: 50px;
    
    
            .recruiter-search {
                width: 200px;
                flex-shrink: 0;
    
                .MuiInputBase-formControl {
                    border-radius: 10px;
                    height: 45px;
    
                }
            }
    
            .selected-date {
                flex-basis: 100%;
                border-radius: 10px;
                background: #F3F3F7;
    
                .date-label {
                    width: 150px;
                    padding: 15px;
                    color: #000;
                    text-align: center;
                    font-family: Sora;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    text-transform: capitalize;
                }
            }
    
        }        
        .day-view-header {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
    
            .my-team-container {
                display: flex;
                flex-direction: row;
                width: 410px;
                align-items: center;
            }
    
            .day-view-header-text {
                @include centerText;
    
                color: #4E4E4E;
            }
            .my-team {
                color: #000;
                font-family: Sora;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;
            }
            .day-view-box-container {
                width: 90px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 19px;
        
            }
    
        }
    }

}
.day-view-table-container {
    overflow: scroll;
    // width: 80vw;
    width: 100%;
    .day-view-table {
        // width: 1120px;
    
        &::-webkit-scrollbar {
            height: 6px;
        }
    
        .day-view-box-container {
            width: 110px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
    
        }
    }


}




.noRecordsFound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 60px 30px;
    color: #727376;
}
@media screen and (max-width: 1500px) and (min-width: 1200px) {
    .day-view-container .workManagStickyHead .day-view-header .my-team-container{
        width: 440px;
    }
    .day-view-container .workManagStickyHead .day-view-header .day-view-box-container{
        width: 60px;
        margin-right: 20px;
    }
}