.insight-wrap{
    padding-bottom: 8px;
    border-right:1px solid #CFD1D2;
    float:left;
    height: 100%;
    width: 80%;
    .insight-head{
        display: flex;
    border-bottom:1px solid #CFD1D2;
        padding: 15px;
        justify-content: space-between;
        align-items: center;
    }
    .tabs{
        padding: 10px;
    }
    .mandate-select-box{
        border-radius: 10px;
    }
}
.d-none{
    display: none;
}
.side-panel{
    display: flex;
    justify-content: space-between;
    height: 100%;

    .sidepanel-right{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: fit-content;

        >* {
            width: 100%;
            height: 40px;
            padding: 10px;
            color :#B3B5B7;
            
        }
        svg{
            path{
                stroke:#B3B5B7;
            }
            
        }
        .puzzle{
            path{
                stroke:none;
            }
        }
        
    }
}
.tab-button-puzzle{
    border-radius: 4px 0 0 4px;
    border-left: 4px solid #002882;
    background-color: #F0F6FB;
    margin: 5px;

    path {
        stroke: transparent !important;
        fill: #002882;
    }

}
.tabButton-color{
    border-radius: 4px 0 0 4px;
    border-left: 4px solid #002882;
    background-color: #F0F6FB;
    margin: 5px;

    path {
        stroke: #002882 !important;
    }

    }
.note-name{
    font-weight: 600 !important;
    font-size: 14px;
    color: #000000;
    padding :5px 0;
    margin: 0px;
}
.note-createdat{
    font-weight: 400 !important;
    font-size: 12px;
    color: #727376;
    margin: 0px;
}

.note-content{
    font-family: 'Sora';
    font-weight: 400 !important;   
    font-size: 14px;
    color: #000000;
    margin: 0px;
    text-justify:auto;
    padding-bottom: 10px;
    border-bottom:1px solid #CFD1D2;
}
.mandatelist-on-notes{
    .MuiFormControl-fullWidth{
        width: 100% !important;
        .MuiInputBase-adornedEnd{
            border-radius: 15px !important;
        }
    }
}