.bulkAlloactionRecuriterTable{
    tbody{
        tr{
            td{
            &:nth-child(2){
                padding-top: 30px !important;
                }
                .change-option .edit-icon path {
                    stroke: #0E94F4;
                }
            }
        }
    }
}
@media screen and (max-width: 1500px) and (min-width: 1200px) {
    .bulkAlloactionRecuriterTable .weekViewSubHeaderSect {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 54px;
        height: 35px;
        margin-left: 33px;
    }
}
@media screen and (min-width: 1500px) {
    .bulkAlloactionRecuriterTable .weekViewSubHeaderSect {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 79px;
        height: 35px;
        margin-left: 30px;
    }
}

.confirmationPopupSect{
    .header-wrapper{
        padding-bottom: 0px !important;
        svg{
            width: 12px;
        }
    }
    
    h1{
        font-size: 14px;
        font-weight: 400;
    }
    h2{
        font-size: 14px;
    }
}
.assigneeBulkError{
    padding-left: 15px;
    font-size: 13px;
    font-weight: 500;
    color: red;
    line-height: 24px;
    margin-bottom: 0px;
}
.weekBulkContainerSect{
    .workManagStickyHead{
        padding-left: 280px;
        .week-view-header{
            padding-left: 97px;
        }
    }
    .weeklyBulkViewData{
        display: flex;
        align-items: baseline;
        gap: 183px;      
        .change-option .edit-icon path {
            stroke: #0E94F4;
        }  
    }
}
.weeklyTableBlk{
    display: flex;
    justify-content: center;
}
.disableIcon{
    opacity: 0.5;
    pointer-events: none;
}