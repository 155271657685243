.funnelWidgetSect{
  width:100%;
  height:350px;
  margin-bottom:"20px";
}
.funnelLabelTop{
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: baseline;
  padding: 20px 40px 0px 0px;
}
.funnelLabelTop p{
  width: 100%;
  padding: 0px 10px;
  display: flex;
  gap:5px;
  align-items: center;
  font-size: 11px;
}
.funnelLabelTop p span{
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 3px;
}
.funnelWidgetSect > div:nth-child(2){
  margin-top: -55px;
  margin-left: -17px;
}
.dashboardFunnelSect{
  display: flex;
  align-items: center;
  gap: 0px;
}
.dashboardFunnelSect .dashboardFunnelLeft{
  border: 1px solid #CFD1D2;
  border-radius: 6px 0px 0px 6px;
  color: #000;
  font-size: 14px;
  padding: 8px 12px;
  cursor: pointer;
}
.dashboardFunnelSect .dashboardFunnelLeft.active{
  background: #FF6E00;
  border-right: 0px;
  color: #fff;
}
.dashboardFunnelSect .dashboardFunnelRight{
  border: 1px solid #CFD1D2;
  border-radius: 0px 6px 6px 0px;
  color: #000;
  font-size: 14px;
  padding: 8px 12px;
  cursor: pointer;
}
.dashboardFunnelSect .dashboardFunnelRight.active{
  background: #FF6E00;
  border-left: 0px;
  color: #fff;
}
.funnelChartLabel{
  font-size: 13px;
  font-weight: 600;
  fill: #fff;
}
.funnelWidgetZerCase{
  > div:nth-child(2){
    position: absolute;
    height: 2px !important;
    bottom: 140px;
  }  
}