.reminder_text-danger {
    color: red;
    font-size: 12px;
  }
  .schAdminInputCtrSelect  label {
    left: 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #1B1C1E;
    opacity: 0.6;
    margin-top: -2px;
  }

  .schAdminInputCtr{
    width:100%
  }
  span.MuiFormLabel-asterisk{
    color:red
  }