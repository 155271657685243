.mandate-wrapper{
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  width: 100%;

  .MuiAccordion-root::before{
    height: 0px !important;
  }

  .nav-header{
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #E5E8EB;
  }
  .header-text{
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  .accordian-header.accordian-header{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    background-color: #FAFAFA;
    margin-bottom: 15px !important;
  }
  .accordian-header::before{
    content: "";
    width: 5px;
    height: inherit;
    min-height: 64px;
    background-color: #002882;
    border-radius: 0px 10px 10px 0px;
    position: relative;
    left: -1rem;
  }
  .accordian-details{
    .datePicker{
      svg{
        path{
          stroke: #727376;
        }
      }
    }
  }
  .container-wrapper{
    padding: 0rem 0.5rem 1rem;

    .row-grid{
      display: flex;
      gap: 20px;
      > .datePickerMandate{
        width: calc(25% - 15px) !important;
      }
      .job-title-box,.mandateSpocBox{
        position: relative;
        *::-webkit-scrollbar {
          // display: none;
          width: 6px;
          height: 10px;
        }
        *::-webkit-scrollbar-track {
          background-color: #f4f5f6;
          border-radius: 10px;
        }
        *::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #aaa;
          width:10px;
          text-overflow: ellipsis;
        }
        .main-suggestions{
          position: absolute;
          top: 60px;
          width: 100%;
          background-color: #fff;
          height: fit-content;
          max-height: 300px;
          overflow-x: auto;
          overflow-y: none;
          z-index: 100;
          padding: 10px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          border: 1px solid #D9D9D9;
          
          .suggested-heading{
            color: #727376;
            font-size: 14px;
          }
          .nooptions-suggestion{
            text-align: center;
          }
          .eachboxes{
            display: flex;
            flex-direction: column;
            cursor: pointer;
            padding: 2px;
            border-radius: 2px;
            .mandate-body-id{
              color: #727376;
              font-size: 12px;
            }
          }
          :hover{
            background-color: #EFEFEF;
          }
        }
      }
    }
    .pt-1{
      padding-top: 1.5rem;
    }

    .mandate-slider{
      display: flex;
      gap: 20px;
      align-items: center;
      .range-slider{
        width:50%;
      }
      .range-select-container{
        width: 50%;
        margin-top: unset !important;
      }
      .MuiSlider-colorPrimary{
        color: #FF6E00;
      }
      .MuiSlider-thumb{
          width:15px;
          height:15px;
          background-color:#fff;
          border: 1px solid #FF6E00;
      }
      .MuiSlider-valueLabelOpen{
          transform: translateY(100%) scale(1) !important;
          top: 0px !important;
          background-color: #000000;
      }
      .MuiSlider-valueLabelOpen::before{
          top: -8px;
      }
      .select-container{
        margin-top: unset !important;
        > .MuiFormControl-root{
          min-width: 120px;
        }
      }
    }
  }
  .button-container{
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    button{
      text-transform: none !important;
      border-radius: 8px;
      padding: 0.5rem 1rem;
    }
    button.Mui-disabled{
      opacity: 0.5;
    }
    .cancel-btn{
      background-color: #E5E8EB;
      color: #000;
    }
    .save-btn{
      background-color: #002882;
      color: #fff;
    }
    .draft-btn{
      color: #002882;
      border: 1px solid #002882;
    }
  }

  .Attachment-label{
    font-size: 12px;
    color: #727376; 
    font-weight: 400;
  }
  .upload-form-control{
    display: flex;
    flex-direction: row;
  }
  .custom-dropzone{
    width: 291px;
    height: 60px;
    left: 591px;
    top: 1263px;

    background: #F8FAFF;
    border: 1px dashed #002882;
    border-radius: 8px;

    .file-name-container{
      display: flex; 
      flex-direction: column;
      height: inherit;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .file-upload-icon{
        display: flex; 
        align-items: center; 
        justify-content: center; 
        gap: 10px; 

        span{
          color: #002882;
          font-size: 14px; 
          font-weight: 500;
        }
      }

      .file-preview-text{
        word-wrap: break-word;
        color: #5e5b5d;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 270px;
        overflow-x: hidden;
      }
    }
  }
  .helper-boxtext{
    display: flex;
    gap: 6px;
    align-items: flex-start;
  }

  .error{
    font-size: 12px;
    color: red;
  }

  .MuiInputBase-root{
    border-radius: 10px !important;
  }
  .Mui-expanded{
    margin: unset !important;;
  }
  .ruler-hr{
    width: calc(100% - 50px);
  }
  .quill{
    height: 240px;
    ::-webkit-scrollbar {
      width: 5px !important;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px !important;
    
    }
    ::-webkit-scrollbar-thumb {
        background: #DBDBDB !important;
        border-radius: 10px !important;
    }
  }
  .ql-toolbar.ql-snow {
    border: unset !important;
  }
  .ql-container.ql-snow{
    border: unset !important;
    height: calc(100% - 28%) !important;
  }

  .delete-icon{
    svg path{
      stroke: #F34334 !important;
    }
  }

  .select-unit-wrapper{
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;

    .unit-container{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px !important;
      font-weight: 400 !important;

      .MuiInputBase-input{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        color: #002882 !important;
      }
      .MuiSelect-iconOutlined{
        top : calc(50% - 0.55em) !important;
      }
    }
  }
  .lable-text-select{
    font-size: 12px; 
    font-weight: 400; 
    color: #727376; 
    padding-bottom: 10px;
  }
  .uploaded-file-wrapper{
    display: flex; 
    gap: 10px; 
    padding: 0.5rem;

    .uploaded-file-names{
        color: #081F36; 
        text-decoration-line: underline;
    }
  }
  .accord-icon{
    path{
      stroke: #002882 !important;
    }
  }
  .location-dropdown{
    height: 100% !important;
    .MuiTextField-root{
      height: 100% !important;
    }
    .MuiAutocomplete-inputRoot{
      height: 100% !important;
      padding: 0px 9px !important;
    }
    .MuiAutocomplete-endAdornment{
      top: calc(50% - 14px) !important;
    }
  }

  .autofield-error{
    .MuiTextField-root {
      border-color: red !important;
      border-width: 1.5px !important;
    }
  }
  .error-span{
    margin-top: 10px !important;
  }
  .inner-textfield {
    // max-height: 50px;
    /* overflow: none; */
    box-sizing: border-box;

    .MuiTextField-root {
      padding-top: 10px;
      border: 1.5px solid;
      border-radius: 8px;
      .MuiInputBase-adornedEnd{
        padding: 0 10px 10px 10px !important;
      }
  
    }
    label{
      background-color: #fff;
    }
  
    .MuiInputBase-formControl {
      max-height: 50px;
      overflow: auto;
      /* border: 1px solid; */
      // padding-top: 0;
    }
  
    .MuiInputBase-formControl fieldset {
      border: none;
      outline: none;
    }
    ::-webkit-scrollbar {
      // display: none;
      width: 6px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: #f4f5f6;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #aaa;
      width:10px;
      text-overflow: ellipsis;
    }
  }
}
.assign-mandates-header {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin-bottom: 15px;

}

.inside-modal-popup {
  .css-e24clt-MuiInputBase-root-MuiOutlinedInput-root {
      border-radius: 15px !important;
  }
}

.bottom-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 70px;
  
  .bottom-cancel-button {
      background: #E5E8EB !important;
      border-radius: 8px;
      color: #000000;
      padding: 8px 28px 8px 28px;
      text-transform: none;
      margin-right: 10px;
  }

  .bottom-assign-button {
      background: #002882 !important;
      border-radius: 8px;
      color: #fff;
      padding: 8px 28px 8px 28px;
      text-transform: none;
  }
}
@media (max-width: 992px) {
  .mandate-wrapper{
    .container-wrapper{
      .row-grid{
        flex-direction: column;
        .datePickerMandate{
          width: calc(100%) !important;
        }
      }
    }
    .res-grid{
      width: 100% !important;
    }
  }
}

@media (max-width: 768px) {
  .mandate-wrapper{
    .mandate-slider{
      display: flex;
      flex-direction: column !important;
      .select-container{
        width: 100% !important;
      }
    }
    .custom-dropzone {
      width: 270px !important;
    }
  }

  .mandate-location-field-model{
    .row-grid{
      flex-direction: column;

      .clientspoc-field{
        width: 100% !important;
      }
    }
    .res-grid{
      width: 100% !important;
    }
    .pricing-group{
      flex-direction: row !important;
    }

    .location-dropdown{
      div{
        div{
          input{
            padding: 16.5px 0px 16.5px 14px !important;
          }
        }
      }
    }
  }
}

.mandate-location-wrapper{
.no-mandate-location-text{
  color: #727376;
  font-family: Sora;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.mandate-location-tag{
  text-transform: none;
  background-color: #F3F3F7;
  border-radius: 8px;
  color: #002882;
  font-weight: 400;

  svg:nth-child(1){
    width: 14px;
    height: 14px;
    margin-left: 10px;
    path{
      stroke: #0E94F4 !important;
    }
  }

  svg:nth-child(2){
    width: 12px ;
    height: 12px;
    margin-left: 6px;
    path{
      stroke: #FF0000 !important;
    }
  }
}
}

.mandate-location-field-model{
.row-grid{
  display: flex;
  gap: 20px;
}
.gray-out{
  opacity: 0.5;
}

.form-modal{
  width: 80%;
  max-width: 1000px;
  height: calc(100% - 60px);
  overflow-y: auto;
  position: relative;
}

.mandate-slider{
  display: flex;
  gap: 20px;
  align-items: center;
  .range-slider{
    width:50%;
  }
  .range-select-container{
    width: 50%;
    margin-top: unset !important;
  }
  .MuiSlider-colorPrimary{
    color: #FF6E00;
  }
  .MuiSlider-thumb{
      width:15px;
      height:15px;
      background-color:#fff;
      border: 1px solid #FF6E00;
  }
  .MuiSlider-valueLabelOpen{
      transform: translateY(100%) scale(1) !important;
      top: 0px !important;
      background-color: #000000;
  }
  .MuiSlider-valueLabelOpen::before{
      top: -8px;
  }
  .select-container{
    margin-top: unset !important;
  }
}

.select-unit-wrapper{
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;

  .unit-container{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    font-weight: 400 !important;

    .MuiInputBase-input{
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      color: #002882 !important;
    }
    .MuiSelect-iconOutlined{
      top : calc(50% - 0.55em) !important;
    }
  }
}

.ruler-hr{
  width: calc(100% - 70px);
}
.ruler-hr-spoc{
  width: calc(100% - 112px);
}
.bottom-buttons {
  padding-top: 20px;
  border-top: 1px solid #CFD1D2;
  margin-top: unset !important;
   }
.MuiInputBase-root{
  border-radius: 10px !important;
}
.Mui-expanded{
  margin: unset !important;;
}

.location-dropdown{
  height: 100% !important;
  .MuiTextField-root{
    height: 100% !important;
  }
  .MuiAutocomplete-inputRoot{
    height: 100% !important;
    padding: 0px 9px !important;
  }
  .MuiAutocomplete-endAdornment{
    top: calc(50% - 14px) !important;
  }
}
}
.disableTextArea{
opacity: 0.5;
pointer-events: none;
}
.disableTextArea .MuiInputBase-root{
background: #eeeeee;
}
.aboutClientSect{
border: 1px solid rgba(0, 0, 0, 0.23);
padding: 16.5px 14px;
border-radius: 10px!important;
position: relative;
p{
  display: none;
}

div{
  color: rgba(0, 0, 0, 0.6);
}
}
.aboutClientSectAdded {
p{
  display: block;
  position: absolute;
  top: -9px;
  font-size: 12px;
  background: #fff;
  padding: 0px 5px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px;
}
}
.manadateDateError{
font-size: 12px;
display: flex;
align-items: center;
margin-top: 10px;
gap: 5px;
color: red;

svg{
  color: red;
  font-size: 14px;
}
  }  
.jobDescHighlightEnable{
  > div:not(.aiImageSect){
    background: rgb(252, 249, 255);
    border: 2px solid rgb(101,18,179) !important;
  }
  .lable-text-select{
    color: rgb(101,18,179);
  }
}
.minimizeChatSect{
  position: fixed;
  bottom: 22px;
  right: 130px;
  display: flex;
  align-items: center;
  background-image: url(../../../assets/images/chatEnhanceBg1.svg);
  padding: 10px 20px;
  border-radius: 10px;
  gap: 40px;
  height: 60px;
  .minimizeChatSectLeft{
    display: flex;
    align-items: center;
    gap: 10px;
    p{
      font-size: 14px;
      margin: 0px;
      font-weight: 600;  
    }
  }
  .minimizeChatSectRight{
    img{
      width: 13px;
    }
  }
}