.multilevel-dropdown{
    position: relative;
    .overlay-multiselect{
        z-index: 9;
        position: fixed;
        inset: 0;
    }
    .input-box{
        display: flex;
        flex-direction: row;
        padding-right: 3px;
        width: 100%;
        overflow: hidden;
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        height: 57px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    
    .input-label-text{
        margin: auto 0px;
        padding-left: 15px;
        width: 50%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
     
    }
    .input-ind-text{
        margin: auto 0px;
        padding-left: 15px;
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;  
    
    }
    .input-placeholder-text{
        margin: auto 0px;
        padding-left: 15px;
        color: grey;
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
    }
    .input-count-text{
        margin:auto;
        width: 40%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

    }
    .custom-arrow-icon{
      width: 10%;
        display: flex;
    align-items: center;
    color: gray
    }
}

.customeindustry{
    .multilevel-dropdown{
        position: relative;
        .overlay-multiselect{
            z-index: 9;
            position: fixed;
            inset: 0;
        }
        .input-box{
            display: flex;
            flex-direction: row;
            padding-right: 3px;
            width: 100%;
            overflow: hidden;
            border: 1px solid #D9D9D9;
            border-radius: 10px;
            height: 37px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        
        .input-label-text{
            margin: auto 0px;
            padding-left: 15px;
            width: 50%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
         
        }
        .input-ind-text{
            margin: auto 0px;
            padding-left: 15px;
            width: 90%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;  
        
        }
        .input-placeholder-text{
            margin: auto 0px;
            padding-left: 15px;
            color: grey;
            width: 90%;
            overflow: hidden;
            white-space: nowrap;
        }
        .input-count-text{
            margin:auto;
            width: 40%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
    
        }
        .custom-arrow-icon{
          width: 10%;
            display: flex;
        align-items: center;
        color: gray
        }
        > label{
            display: none;
        }
    }
    .multilevel-dropdown.enableLabel > label{
        display: block;
        position: absolute;
        top: -3px;
        font-size: 12px;
        background: #fff;
        left: 16px;
        padding: 0px 4px;
        line-height: 7px;
    }
}
.input-list{
    max-height: 350px;
    overflow: auto;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 0;

    .depIndSearch{
        border-bottom: 1px solid #D9D9D9;
        border-radius: 0;
        background-color: #F7F7F7;
        input{
            padding: 15px 14px 15px 0;
        }
        fieldset{
            display: none;
        }
    }

    .MuiPaper-root{
        box-shadow: none;
        overflow: hidden;
        .MuiAccordionSummary-root{
            padding: 0;
            padding: 10px 15px;
            min-height: auto;
            .MuiAccordionSummary-content{
                margin: 0;
                width: calc(100% - 24px);
                overflow: hidden;
                .list,
                .list-item{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    input{
                        min-width: 18px;
                        min-height: 18px;
                    }
                }
            }
        }
        .MuiCollapse-root{
            .list{
                width: 100%;
                display: flex;
                align-items: center;
                gap: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 0;
                margin-bottom: 6px;
                cursor: pointer;
                input{
                    min-width: 18px;
                    min-height: 18px;
                }
            }
            .MuiAccordionDetails-root{
                padding: 8px 16px 0;
            }
        }
    }
}
.popup-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    margin-left: 20px;
    border-radius: 10px;
}

.list{
    font-size: 14px;
    padding: 0 0 5px 5px ;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .MuiButtonBase-root{
        pointer-events: none;
    }
    .list-item{
        padding: 4px 15px 4px 5px;
        background-color: #f4f5f6;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 2px;
        cursor: pointer;
        svg{
            margin-left: auto;
        }
    }
 
    .list{
        padding: 5px 10px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 2px;
        cursor: pointer;
        &.active{
            background-color: #f4f5f6;
        }
    }
    &.active{
        .list-item{
            background-color: #f4f5f6;
        }
        &:hover{
            .sub-list{
                display: flex;
            }
        }
    }
}

.sub-list{
    width: 100%;
    // display: none;
    padding-left: 20px;
    flex-direction: column;
    gap: 2px;
    padding-top: 2px;
    display: flex;
}
.multi-accordian{
    padding-left: 20px;
}
.multilevel-dropdown{
    .sub-list{
        padding-bottom: 10px;
    }
    &:last-child{
        .sub-list{
            padding-bottom: 0;
        }
    }
}
.ellipsis-drop-cls-list{
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}