.manageCopilotSect{
    .userViewTabSect{
        .MuiTabs-root{
            .MuiTabs-scroller{
                background-color: #fff !important;
                .MuiTabs-flexContainer{
                    justify-content: flex-start;
                    background: #F3F3F7;
                    width: fit-content;
                    border-radius: 15px;
                    button{
                        width: fit-content;
                        min-width: fit-content;
                    }
                    button.Mui-selected{
                        padding: 0px 25px;
                    }
                    button.Mui-selected:nth-child(1){
                        path{
                            fill: #002882;
                        }
                    }
                    button.Mui-selected:nth-child(2),button.Mui-selected:nth-child(3){
                        path{
                            stroke: #002882;
                        }
                    }
                }
            }
        }
    }
}
.manageCopilotCardSect{
    padding: 0px 20px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    .coPoilotCardsSect{
        display: flex;
        flex-direction: column;
        border: 1px solid #D9D9D9;
        padding: 18px 0px;
        border-radius: 10px;
        width: fit-content;
        position: relative;
        width: 170px;
        // min-height: 270px;
        .cardStatus{
            position: relative;
            margin-left: 18px;
            margin-right: 18px;        
            img{
                float: left;
                width: 100%;
            }
            p{
                position: absolute;
                bottom: 0px;            
                margin: 0px;
                font-size: 13px;
                width: 100%;
                text-align: center;
                padding: 8px 0px;            
                font-weight: 600;
                border-radius: 0px 0px 10px 10px;
            }
            p.inactiveRequest{
                background: #FFEDEB;
                color: #F34334;
            }
            p.NewRequest{
                background: #E0F4E8;
                color: #2BAD63;
            }
        }
        .cardStatus.cardImgDisable{
            img{
                opacity: 0.5;
            }
        }
        .cardDesc{
            text-align: center;
            .pilotInfoSect{
                background-color: #f3f3f7;
                display: flex;
                flex-direction: column;
                gap: 5px;           
                padding: 10px 0px;    
                margin: 8px 0px; 
                > div{
                    display: flex;
                    padding: 0px 10px;
                    align-items: center;
                    gap: 10px;
                    .coPilotText{
                        font-size: 13px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        text-align: left;
                        width: 108px;
                    }
                    .coPilotExpire{
                        font-size: 12px;
                        font-weight: 600;
                        margin-top: 5px;
                        text-align: left;
                        width: 108px;
                    }
                }
                
            }
            h4{
                margin: 10px 0px 0px 0px;
                font-weight: 600;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin-left: 18px;
                margin-right: 18px; 
            }
            p{
                margin: 0px;
                font-size: 13px;
            }
            .coPilotRole{
                font-size: 13px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                height: 22px;
                margin-left: 18px;
                margin-right: 18px; 
                color: #727376;
            }
            
        }
        .cardAction {
            display: flex;
            gap: 15px;
            margin-top: 15px;
            justify-content: center;
            padding-top: 15px;
            border-top: 1px solid #EAEAF5;
            svg{
                cursor: pointer;
            }
        }
        .cardApprovalPending{
            background: #FCF4E5;
            width: 100%;
            position: absolute;
            bottom: 0px;
            left: 0px;
            text-align: center;
            font-size: 14px;
            color: #E09000;
            padding: 5px 0px;
            border-radius: 0px 0px 10px 10px;
            font-weight: 600;
        }
    }
    .coPoilotCardsSect.disableCard{
        .cardStatus {
            opacity: 0.5;
        }
        .cardDesc  {
            opacity: 0.5;
        }
        .cardAction  {
            visibility: hidden;
        }
    }
    .coPoilotCardsSect.addCopilotCard {
        border-style: dashed;
        border-color: #0E94F4;
        cursor: pointer;
        .cardDesc{
            .addCopilotText{
                color: #002882;
                margin: 40px 0px 0px 0px;
            }
        }
    }
}
.manageCopilotAuditTable{
    position: relative;
    overflow: visible;
    .userListFilterSect{
        position: absolute;
        right: 0px;
        top: -70px;
    }
}
.addCopilotSect{
    position: fixed;
    left: 0px;
    top: 0px;
    background: #0000006b;
    z-index: 1300;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .addCopilotSectBlk{
        background: #fff;
        border-radius: 15px;
        padding: 30px 30px 0px 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 50%;
        max-width: 550px;
        .addCopilotSectBlkTop{
            display: flex;
            justify-content: space-between;
            h3{
                margin: 0px;
                font-size: 16px;
                font-weight: 500;
            }
            svg{
                font-size: 18px;
            }
        }
        .addCopilotSectBlkBody{
            display: flex;
            flex-direction: column;
            gap: 30px;
            .reasonlFields{
                .MuiSelect-select{
                    padding: 10px;
                }
                fieldset{
                    border-radius: 10px;
                }
                label:not(.Mui-focused){
                    transform: translate(14px, 11px) scale(1);
                    font-size: 14px;
                }
                label.MuiFormLabel-filled{
                    transform: translate(14px, -9px) scale(0.75) !important;
                    font-size: 16px !important 
                }
            }
            .reasonCommentsFields{
                fieldset{
                    border-radius: 10px;
                }
            }
            .addCopilotSectBlkForm{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .MuiFormControl-root{
                    fieldset{
                        border-radius: 10px;
                    }
                }
            }
            .button-container{
                padding-right: 0px;
            }
        }
        .deactive-btn{
            background-color: red;
            color: #fff;
        }
        .save-btn {
            background-color: #002882;
            min-width: 100px;
        }
        .cancel-btn {
            background-color: #E5E8EB;
            min-width: 100px;
        }
        .MuiFormControl-root{
            .MuiInputBase-root:hover,.MuiInputBase-root.Mui-focused{
                fieldset{
                    border-color: rgba(0, 0, 0, 0.23);
                    border-width: 1px;
                }
            }
        }
    }
}
.MuiPopper-root.MuiPickersPopper-root{
    z-index: 9999;
}
.audit-log-table{
    thead{
        tr{
            th{
                p{
                    padding: 0px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}
.inactiveDisable{ 
    opacity: 0.5;
    // pointer-events: none;
}
.auditLogTableSect{
    overflow: auto;
    height: calc(100vh - 320px);
}
.manageCopilotApprover{
    display: flex;
    gap: 0px;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    padding: 0px 10px;
    svg{
        width: 20px;
        color: #ab5f39;
    }
    h6{
        margin: 0px;
        font-weight: 500;
        color: #727376;
        font-size: 13px;
        margin-bottom: 5px;
    }
    p{
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        line-height: 18px;
        margin: 5px 0px 0px 0px;
    }
}