
    .ai-support-container{
      .input-wrapper {
        .text-field-wrap {
          max-width: 100% !important;      
        }
      }
      .maximize-cls {
        .header-cls-maximize{
          // background-color: rgb(241,226,255) !important;
          background-image: url(../../../assets/images/chatEnhanceBg2.svg) !important;
          background-size: cover !important;
          background-repeat: no-repeat !important;
        }
      }
      .header-wrapper{
        .header-text{
          display: flex;
          align-items: center;
          color: #000 !important;
          gap: 10px;
        }
        .rightSideHeader{
          img{
            filter: invert(100%);
          }
        }
      }
      .answer-conatiner .copy-icon-container{
        width: auto !important;
      }
      .insert-icon-container{
        display: flex;
        gap: 10px;
        background: rgb(235, 240, 251);
        padding: 4px 10px 4px 5px;
        font-size: 14px;
        border-radius: 5px;
        align-items: center;
        margin-top: 8px;
        margin-left: 10px;
        cursor: pointer;
        svg{
          width: 18px;
        }
      }
    }