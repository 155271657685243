.mandate-detail-wrapper{
  background-color: #fff;
  border-radius: 10px;
  // height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 90px);

> .MuiAccordion-root{
  box-shadow: none;
  margin-top: 0px !important;
}
.MuiAccordion-root::before{
    height: 0px !important;
  }

  .accordian-header{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    background-color: #FAFAFA;
    padding-left: 10px;
    height: 48px;
    min-height: 48px !important;
  }
  .accordian-header::before{
    content: "";
    width: 5px;
    height: inherit;
    min-height: 48px;
    background-color: #002882;
    border-radius: 0px 10px 10px 0px;
    position: relative;
    left: -10px;
  }
  .accordian-header.Mui-expanded{
    margin-bottom: 40px;
  }
  .container-wrapper{
    padding: 0rem 0.5rem 1rem;

    .gray-out{
      opacity: 0.5;
    }

    .row-grid{
      display: flex;
      gap: 5px;
    }
    .pt-1{
      padding-top: 1.5rem;
    }
    .remark-container{
      grid-column: span 2;
    }
    .header-text{
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #727376;
    }
    .data-text{
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      padding-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }
    .JO-wrapper{
      padding-top: 5px;
      span{
        padding-right: 4px;
        color: #002882;
      }
      svg{
        cursor: pointer;
        path{
          fill: #002882;
        }
      }
    }
    .depIndFull{
      grid-column: 1/-1;
      .depIndContainer{
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        padding: 15px;
        margin-top: 10px;
        .depIndContain{
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: 6px 20px;
          padding: 15px 0;
          border-bottom: 1px solid #D9D9D9;
          &:last-child{
            border-bottom: none;
            padding-bottom: 0;
          }
          &:first-child{
            padding-top: 0;
          }
          h4{
            grid-column: 1/-1;
            font-size: 14px;
            margin: 0;
            font-weight: 600;
            color: #002882;
          }
          .depIndWrap{
            h5{
              font-weight: 500;
              margin: 0 0 5px;
              font-size: 14px;
            }
            .depIndBadges{
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              div{
                padding: 5px 10px;
                border-radius: 5px;
                background-color: #EFEFEF;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .logs-container{
    width: 85%;
    background-color: #F3F3F7;
    padding: 0 0 4px 0 !important;
    border-radius: 8px;
    .log-row{
      background-color: white;
    margin:  3px 3px 0px 3px;
    border-radius: 5px;


      .avatar-box{
        display:flex;
        flex-direction: row;
        margin-left: 15px;
        .name-text{
          display:flex;
          width: 80%;
          flex-direction: column;
          .name{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14px;  
          }
          .role{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            color: #727376;
          }
        }
        
      }
      .approve{
        font-size: 14px;
        color: #167916;
      }
      .reject{
        font-size: 14px;
        color: #FF0000;
      }
      .data-time{
        font-size: 14px;

      }
    }
  }
  .header-container{
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 0rem;
    border-bottom: 1px solid #CFD1D2;

    .mandate-profile{
      display: flex;
      gap: 16px;
      align-items: center;
      padding-left: 0px;

      .profile-text{
        display: flex;
        flex-direction: column;
      }
    }
    .reject-info{
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      // .reject-icon{
  
      //     path{
      //       stroke: #E5252A;
      //     }
        
       
      // }
      .reject-text{
        color: red;
        font-size: 14px;
      }
    }
    .btn-container{
      display: flex;
      gap: 10px;
      align-items: center;
    }
    .download-btn{
      svg path{
        stroke: #fff;
      }
    }
  }

  .MuiInputBase-root{
    border-radius: 10px !important;
  }
  // .Mui-expanded{
  //   margin: unset !important;
  // }

  .primary-text-font{
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #000000;
    word-break: break-word;
  }
  .secondary-text-font {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #727376;
    word-break: break-word;
  }
  .accord-icon{
    path{
      stroke: #002882 !important;
    }
  }
  .ruler-hr{
    width: 100%;
  }
  .grid-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 10px;
  }
  .mandate-file-name{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
  }
  .rich-text-editor{
    p{
      margin: unset !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
  .grid-quick-view{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 5px;
  }
  .status-log-name{
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
  }

  .audit-log-table{
    width: 100%;
    .d-flex{
      display: flex;
      align-items: center;
      gap: 10px;
    }
    thead{
      tr{
        th{
          padding: 12px;
          color: #727376;
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
          line-height: normal;
          background-color: #F3F3F7;
          border-bottom: unset;
        }
        th:nth-child(1){
          border-top-left-radius: 5px;
          padding-left: 25px;
        }
        th:nth-child(4){
          border-top-right-radius: 5px;
        }
      }
    }
    tbody{
      border: 1px solid #E5E8EB;
      tr{
        td{
          padding: 10px 12px;
          line-height: normal;
        }
        td:nth-child(1){
          padding-left: 25px;
        }
      }

      .user-detail{
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
      .ellipsisText1{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .primary-text{
        color: #000;
        font-size: 14px;
        font-weight: 400;
      }
      .secondary-text{
        color: #727376;
        font-size: 14px;
        font-weight: 400;
      }
      .text-ellips{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis !important;
        width: 148px;
        display: block;
      }
    }
  }
  .potential-wrap{
    display: flex; 
    align-items: center;
    gap: 10px;
    padding: 15px;
    border-radius: 8px;
    background: #F3F3F7;

    .potential-header{
      color: #727376;
      font-family: Sora;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .potential-val{
      color: #000;
      font-family: Sora;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .mandate-edit-btn{
    button.Mui-disabled{
      opacity: 0.5;
      color: #fff;
    }
  }
  .mandate-postjob-btn
  {
    min-width: 130px;
    .postjobs{
      border: 1px solid #002882;
      border-radius: 8px;
      padding: 6px 15px;
      color: #002882;
      align-items: center;
      display: flex;
      text-transform: capitalize;
      font-weight: 400;
      width: max-content;
    }
  }
}
.reject-model-textbox{
  border: none;
        box-sizing: border-box;
        max-width: inherit;
        width: 100%;
        .MuiInputBase-colorPrimary {
            fieldset {
                border-radius: 10px;
            }
        }
        & > label{
            font-size: 15px;
            margin: 0;
            padding-right: 8px;
            background-color: #FFFFFF;
        }
        &.Mui-focused{
            & > label{
                transform: translate(14px, -6px) scale(0.7) !important;
                background-color: #FFFFFF;
            }
        }
}
.readMoreText{
  .hideText{
    height: 63px;
    margin: 0px;
    overflow: hidden;
  }
  .showText{
    height: auto;
    margin: 0px;
  }
  span{
    color: #0e94f4;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .mandate-detail-wrapper{
    .grid-quick-view{
      grid-template-columns: 1fr 1fr;
    }
    .grid-container{
      grid-template-columns: 1fr 1fr;
    }
  }
}
.show-more-less-clickable{
  color: #002882;
  font-weight: 600;
  cursor: pointer;
}
body .mandate-edit-btn.disableEdit button svg path{
  stroke: #939393 !important;
}
.shareMandteBtn{
  p{
    margin: 0px;
    line-height: 0px;
    color: #002882;
    font-size: 14px;
    margin-top: 3px;
  }
  .infoIcon{
    position: absolute;
    top: -6px;
    right: -5px;
  }
}
.mandate-data-drawer .collabApproveSectBlk .collabApproveNoti{
  display: none !important;
}
.collabApproveSectBlk,.copilotBtnSect{
  position: relative;
  .collabApproveNoti{
    position: absolute;
    width: max-content;
    right: 0px;
    background: #000000b8;
    border-radius: 10px;
    z-index: 99;
    margin-top: 15px;
    padding: 0px 10px;
    .arrow-up {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(0, 0, 0, 0.7215686275);
      position: absolute;
      margin-top: -9px;
      right: 20px;
    }
    p{
      margin: 13px 8px;
      font-size: 14px;
      color: #fff;
      display: flex;
      span.viewDetail{
        text-decoration: underline;
        cursor: pointer;
        margin-left: 5px;
      }
      svg{
        margin-left: 10px;
        font-size: 20px;
        margin-left: 15px;
        cursor: pointer;
      }
    }
    
  }
}
.copilotBtnSect{
  .collabApproveNoti{
    top: -75px;
    right: -130px;
    background: #000;
    .arrow-up{
      transform: rotate(180deg);
      bottom: -10px;
      left: 50%;
      border-bottom: 10px solid rgba(0, 0, 0);
    }
  }
}