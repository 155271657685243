.timeToFileExperienceSect{
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    > .MuiFormControl-root{
        width: 300px;
        label:not(.MuiFormLabel-filled):not(.Mui-focused):not(.MuiInputLabel-shrink){
            transform: translate(14px, 10px) scale(1);
            font-size: 14px;
        }
       .MuiInputBase-root {
        border-radius: 10px !important;
        padding-top: 2px;
        padding-bottom: 2px;
        .MuiSelect-select {
            padding: 8px 15px;
        }
       }
    }
    > ul {
        padding: 0px;
        display: flex;
        gap: 20px;
        list-style: none;
        li{
            font-size: 14px;
            position: relative;
            padding-left: 15px;
            span{
                width: 10px;
                height: 10px;
                position: absolute;
                left: 0px;
                top: 5px;
                border-radius: 3px;
            }
        }
    }
}
.timeToFillToolTipSect{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .ContentSect{
        display: flex;
        flex-direction: column;
        p{
            color: #C1B8B8;
            margin: 0px;
            font-weight: 400;
            font-size: 13px;
        }
        h5{
            color: #fff;
            margin: 0px;
            position: relative;
            font-weight: 500;
        }
    }
    .ContentSect.mandateTypeSect{
        h5{
            span{
                width: 10px;
                height: 10px;
                position: absolute;
                left: 0px;
                top: 5px;
                border-radius: 3px;
            }
            padding-left: 15px;
        }
        
    }
}
.timeToFillBarChartSect{
    > div{
        > div{
            > svg + div{
                z-index: 9999 !important;
                > div{
                    z-index: 9999 !important;
                }                
            }
        }
    }
}