.allocation-list{
    padding-bottom: 0 !important;
    .unassignLocStyle{
      color: #F34334;
    }
  }

  .allocation-list .MuiListItemText-primary {
    font-weight: 500;
    font-size: 12px;
  }

  .allocation-card-list-icon {
    min-width: 30px !important;
  }

  .work-icon {
    svg {
      path{
        stroke: #002882;
      }
    }
  }

  .calender {

    svg {
      path{
        stroke: #002882;
      }
      height: 16px;
      width: 16px;
    }
  }
  .due-date{
    path {
      stroke: #F34334;
    }
  }