.app-modal-dialog .MuiDialog-paper{
    width: 100%;
    max-height: 435px;
}
.app-dialog-title {
    margin: 0px !important;
    padding: 16px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
}
.app-dialog-title-icon {
    position: absolute !important;
    right: 8px !important;
    top: 8px !important;
}
.app-dialog-content{
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #000000 !important;
}