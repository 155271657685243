.mandate-wrapper{
    .nav-header{
        border-bottom: 0px;
    }
    .createRoleAccordionSect{
        margin-top: 20px;
        form{
            > .MuiPaper-root{
                box-shadow: none;
                > .MuiButtonBase-root{
                    min-height: 54px;
                }
                .accordian-header{
                    &::before{
                        min-height: 54px;
                    }
                }
            }
        }
        .personalInfoWrapper{
            display: flex;
            width: 100%;
            .personalInfoProfileSect{
                width: 350px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .MuiFormControl-root{
                    align-items: center;
                    .profileUploadImgSect{
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        .profileCloseIcon{
                            width: 150px;
                            position: relative;
                            svg{
                            position: absolute;
                            top: -5px;
                            right: -5px;
                            background: #ec692c;
                            color: #fff;
                            border-radius: 40px;
                            width: 20px;
                            height: 20px;
                            padding: 3px;
                            cursor: pointer;
                            }
                        }
                        label{
                            // width: 60%;
                            p{
                                font-size: 13px;
                                background: #000;
                                color: #fff;
                                text-align: center;
                                padding: 10px 15px;
                                border-radius: 8px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }                
            }
            .personalInfoFieldWrapper{
                width: 100%;
                .contactNumberGroup{
                    display: flex;
                    width: 100%;
                    .phoneCode{
                        width: 100px;
                        >.MuiInputBase-root{
                            .MuiSelect-select{
                                padding-left: 10px;
                            }
                            fieldset{
                                border-bottom-right-radius: 0px;
                                border-top-right-radius: 0px;
                                legend{
                                    span{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .numberFieldSect{
                        fieldset{
                            border-bottom-left-radius: 0px;
                            border-top-left-radius: 0px;
                            border-left: 0px;
                        }
                    }
                }    
                input[type=number]::-webkit-outer-spin-button,
                input[type=number]::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type=number] {
                    -moz-appearance:textfield;
                }               
            }            
        }
        .MuiFormControl-root{
            .MuiInputBase-root:hover,.MuiInputBase-root.Mui-focused{
                fieldset{
                    border-color: rgba(0, 0, 0, 0.23);
                    border-width: 1px;
                }
            }
        }
    }    
    .mb-3{
        margin-bottom: 30px;
    }
    .userViewTabBlk{
        > .MuiTabPanel-root{
            padding-top: 10px;
            padding-left: 0px;
            padding-right: 0px;
        }
        .userViewTabSect{      
            border:none;  
            .MuiTabs-root{
                padding: 15px;
                .MuiTabs-scroller{
                    background: #F3F3F7;
                    overflow: hidden;
                    border-radius: 15px;
                    .MuiTabs-flexContainer{
                        justify-content: space-between;
                        button{
                            width: calc(100% / 4);
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 5px;
                            text-transform: capitalize;
                            color: #000;
                            min-height: 55px;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            position: relative;
                            svg{
                                margin: 0px;
                            }
                            &::after{
                                content: "";
                                width: 1px;
                                height: 30px;
                                background-color: #c5c5c5;
                                position: absolute;
                                right: 0px;
                            }
                            &:last-child{
                                &::after{
                                    display: none;
                                }
                            }
                        }
                        button.Mui-disabled{
                            opacity: 0.5;
                        }
                        button.prev{
                            &::after{
                                display: none;
                            }
                        }
                        button.Mui-selected{
                            background: #fff;
                            border: 2px solid #E5E8EB;
                            border-radius: 15px;
                            box-shadow: 0px 0px 4px #e1e1e1;
                            color: #002882;
                            position: relative;
                            &::before{
                                content: "";
                                width: 90%;
                                height: 8px;
                                background-color: #002882;
                                position: absolute;
                                top: -5px;
                                border-radius: 10px;
                            }
                            &::after{
                                display: none;
                            }
                        }
                    }
                    .MuiTabs-indicator{
                        display: none;
                    }
                }
            }           
        }
        .mandate-detail-wrapper{
            height: auto;
            min-height: 100px;
        }
    }
    .userViewPersonalSect{
        display: flex;
        align-items: flex-start;
        gap: 60px;
        > img {
            width: 200px;
            border-radius: 20px;
        }
        .userViewPersonalFields{
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .userViewPersonalFieldsFlex{
                display: flex;
                width: 100%;
                justify-content: start;
                > .MuiBox-root{
                    width: calc(100% / 3);
                    margin-bottom: 30px;
                    p{
                        margin-top: 0px;
                        color: #727376;
                        font-size: 12px;
                        margin-bottom: 5px;
                    }
                    h5{
                        font-size: 14px;
                        font-weight: 500;
                        margin: 0px;
                        word-break: break-all;
                        padding-right: 15px;
                    }
                    h5.disableStatus{
                        pointer-events: none;
                    }
                }
            }
        }        
    }
    .userViewEmployeeFields{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .userViewEmpolyeeFieldsFlex{
            width: 100%;
            margin-bottom: 30px;
            display: flex;
            // justify-content: space-between;
            > .MuiBox-root{
                width: calc(100% / 4);
                p{
                    margin-top: 0px;
                    color: #727376;
                    font-size: 12px;
                    margin-bottom: 5px;
                }
                h5{
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0px;
                    word-break: break-all;
                    padding-right: 15px;
                }
                h5.domain{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    word-break: keep-all;
                }
            }
        }
    }
    .userViewSocialFields{
        width: 100%;
        display: flex;
        justify-content: space-between;
        > .MuiBox-root{
            width: calc(100% / 2);
            p{
                margin-top: 0px;
                color: #727376;
                font-size: 12px;
                margin-bottom: 5px;
            }
            h5{
                font-size: 14px;
                font-weight: 500;
                margin: 0px;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                padding-right: 30px;
                float: left;
                width: 100%;
                height: 18px;
            }
        }
    }
    .userViewDocumentFields,.userViewCallingFields{
        width: 100%;
        display: flex;
        gap: 50px;
        p{
            margin-top: 0px;
            color: #727376;
            font-size: 12px;
            margin-bottom: 5px;
        }
        h5{
            font-size: 14px;
            font-weight: 500;
            margin: 0px;
        }
        .smartFlowPass{
            display: flex;
            align-items: center;
            input{
                border: none;
                font-size: 14px;
                pointer-events: none;
            }
            button{
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }
    }
    .userViewDocumentFields{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 0px;
        > div{
            width: 50%;
            margin-bottom: 30px;
            .header-text{
                margin-top: 0px;
                color: #727376;
                font-size: 12px;
                margin-bottom: 5px;
                margin-bottom: 15px;                
            }
            .data-text{
                align-items: center;
                .mandate-file-name{
                    font-size: 14px;
                    color: #000000;     
                    text-decoration: underline;       
                }
            }
            
        }
    }
    .userViewSocialFields{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 0px;
        > div{
            width: 50%;
            margin-bottom: 30px;
            p{
                margin-top: 0px;
                color: #727376;
                font-size: 12px;
                margin-bottom: 5px;
                margin-bottom: 15px;  
            }
            h5{
                font-size: 12px;
                color: #4a9fdf; 
            }
        }
    }
    .userViewTopOptionSect{
        display: flex;
        justify-content: space-between;
        .userViewTopActionSect{
            display: flex;
            gap: 10px;
            .copiolet{
                border: 1px solid #002882;
                border-radius: 8px;
                padding: 6px 15px;
                color: #002882;
                align-items: center;
                display: flex;
                text-transform: capitalize;
                font-weight: 400;
            }
            .copiolet.Mui-disabled,
            .submission.Mui-disabled{
                opacity: 0.5;
            }
            .submission{
                border: 2px solid #002882;
                border-radius: 8px;
                padding: 6px 15px;
                color: #fff;
                align-items: center;
                display: flex;
                text-transform: capitalize;
                background: #002882;
                font-weight: 400;
            }
            .edit{
                border: 2px solid #000;
                border-radius: 8px;
                padding: 6px 15px;
                color: #fff;
                align-items: center;
                display: flex;
                text-transform: capitalize;
                background: #000;
                font-weight: 400;
            }
        }
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
}
.userListFilterSect{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    .userListFilterSearch{
        position: relative;
        .MuiSvgIcon-root{
            position: absolute;
            left: 10px;
            top: 10px;
            color: gray;
        }
        label:not(.Mui-focused){
            transform: translate(38px, 13px) scale(1) !important;
            font-size: 14px;
        }
        .MuiFormControl-root{
            input{
                padding: 10px;
                padding-left: 40px;
            }
            fieldset{
                border-radius: 6px;
            }
        }
    }
    .MuiFormControl-root{
        label:not(.Mui-focused){
            transform: translate(14px, 11px) scale(1);
            font-size: 14px;
        }
        label.MuiFormLabel-filled{
            transform: translate(14px, -9px) scale(0.75) !important;
            font-size: 16px !important 
        }
        .MuiInputBase-root{
            .MuiSelect-select{
                padding: 10px;
            }
            fieldset{
                border-radius: 6px;
            }
        }
    }
    .MuiAutocomplete-root{
        label:not(.Mui-focused){
            transform: translate(14px, 11px) scale(1);
            font-size: 14px;
        }
        label.MuiFormLabel-filled{
            transform: translate(14px, -9px) scale(0.75) !important;
            font-size: 16px !important 
        }
        .MuiInputBase-root{
            input{
                padding: 0px;
            }
            fieldset{
                border-radius: 6px;
            }
        }
    }
    .MuiFormControl-root{
        .MuiInputBase-root:hover,.MuiInputBase-root.Mui-focused{
            fieldset{
                border-color: rgba(0, 0, 0, 0.23);
                border-width: 1px;
            }
        }
    }
}
.submissionReportContainer.userListTableSect,.submissionReportContainer.roleListTableSect {
    table tbody td:nth-child(1), table tbody td:nth-child(2){
        background-color: #fff;
    }
    table tbody td:nth-child(2){
        color: #002882;
        cursor: pointer;
    }
    table thead tr:first-child th > div svg{
        top: -3px;
    }
    max-height: calc(100vh - 300px);
    // min-height: calc(100vh - 410px);
    height: auto;
}
.submissionReportContainer.hiringDemandTableSect {
    table tbody td:nth-child(1), table tbody td:nth-child(2){
        background-color: #fff;
    }
    // table tbody td:nth-child(2){
    //     color: #002882;
    //     cursor: pointer;
    // }
    table thead tr:first-child th > div svg{
        top: -3px;
    }
    max-height: calc(100vh - 390px);
    // min-height: calc(100vh - 410px);
    height: auto;
}
.funnelDetailWidgetSect .submissionReportContainer.hiringDemandTableSect,
.offerManageSect .submissionReportContainer.hiringDemandTableSect,
.jobsWithCandidateSect .submissionReportContainer.hiringDemandTableSect,
.appTrendsWidgetSect .submissionReportContainer.hiringDemandTableSect,
.jobOrderAgeingSect .submissionReportContainer.hiringDemandTableSect{
    max-height: calc(100vh - 340px);
}
.jobOrderAgeingSect .submissionReportContainer.roleListTableSect{
    max-height: calc(100vh - 340px);
}
.submissionReportContainer.appTrendsTableSect {
    table tbody td:nth-child(1), table tbody td:nth-child(2){
        background-color: #fff;
    }
    table thead tr:first-child th > div svg{
        top: -3px;
    }
    max-height: calc(100vh - 390px);
    // min-height: calc(100vh - 410px);
    height: auto;
}
.jobOrderTable{
    max-height: calc(100vh - 220px);
    min-height: calc(100vh - 270px);
}
.decativeUserSect{
    position: fixed;
    left: 0px;
    top: 0px;
    background: #0000006b;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .decativeUserSectBlk{
        background: #fff;
        border-radius: 15px;
        padding: 30px 30px 0px 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 50%;
        max-width: 550px;
        .decativeUserSectBlkTop{
            display: flex;
            justify-content: space-between;
            h3{
                margin: 0px;
                font-size: 16px;
                font-weight: 500;
            }
            svg{
                font-size: 18px;
            }
        }
        .decativeUserSectBlkBody{
            display: flex;
            flex-direction: column;
            gap: 30px;
            .reasonlFields{
                .MuiSelect-select{
                    padding: 10px;
                }
                fieldset{
                    border-radius: 10px;
                }
                label:not(.Mui-focused){
                    transform: translate(14px, 11px) scale(1);
                    font-size: 14px;
                }
                label.MuiFormLabel-filled{
                    transform: translate(14px, -9px) scale(0.75) !important;
                    font-size: 16px !important 
                }
            }
            .reasonCommentsFields{
                fieldset{
                    border-radius: 10px;
                }
            }
        }
        .deactive-btn{
            background-color: red;
            color: #fff;
        }
        .save-btn {
            background-color: #002882;
        }
        .cancel-btn {
            background-color: #E5E8EB;
        }
        .MuiFormControl-root{
            .MuiInputBase-root:hover,.MuiInputBase-root.Mui-focused{
                fieldset{
                    border-color: rgba(0, 0, 0, 0.23);
                    border-width: 1px;
                }
            }
        }
    }
}
.userFilterSect{
    .userFilterSectAction{
        border-top: 1px solid #CFD1D2;
        .filter-box-style{
            margin-top: 0px;
            p{
                padding-left: 0px;
                font-weight: 600;
            }
        }
    }
    form{
        .filter-btn-container{
            border: none;
        }
    }
}
.userRoleTabSect,.userDlsTabSect{
    .button-container{
        border-top: 1px solid #d4d4d4;
        margin-top: 50px;
        .updateBtn{
            background-color: #002882;
            color: #fff;
        }
    }
    .listTreeViewTransferBlk{
        padding: 0px 30px 30px 30px;
    }
}
.disableEdit,.disableActiveCheck{
    opacity: 0.3;
    pointer-events: none;
}