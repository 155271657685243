.textarea{
   
    div{
        textarea{
            height: 100px !important;
            overflow: auto !important;
        }
    }
  }

.mandate-location-field-model{
    .grid-layout{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding-top: 24px;
        gap: 20px;
    }
    .potential-wrapper{
        display: flex;
        gap: 14px;
        padding: 15px;
        border-radius: 8px;
        background: #F3F3F7;
        align-items: center;

        .header-span{
            color: #727376;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .text-span{
            color: #000;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            // letter-spacing: -1.8px;
        }
    }
}

.clientspoc {
    white-space: nowrap;
    font-size:  "16px";
    font-weight: 500;
        color: "#000",
  }

  .button-container{
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    button{
      text-transform: none !important;
      border-radius: 8px;
      padding: 0.5rem 1rem;
    }
    .cancel-btn{
      background-color: #E5E8EB;
      color: #000;
    }
    .save-btn{
      background-color: #002882;
      color: #fff;
    }
    .draft-btn{
      color: #002882;
      border: 1px solid #002882;
    }
  }

  .multiselect-limited-height{
    label{
      background-color: #FFFFFF;
    }
    .MuiInputBase-root{
      max-height: 100px;
      overflow: auto;
      border: 1px solid rgba(0, 0, 0, 0.23);
      fieldset{
        border: none;
        span{
          background-color: #FFF;
        }
      }
    }
  }
  .manadateLocationPopup{
    .mandate-slider{
      .select-container{
        >.MuiFormControl-root{
          width: 150px
        }
      }
    }
  }
  .manLocPhoneNumberField input[type=number]::-webkit-inner-spin-button, 
  .manLocPhoneNumberField input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }