.gridbox {
  display: flex;
  justify-content: center;
}

.boxline {
  width: 1px !important;
  // height: 76px !important;
  background: #e5e8eb !important;
  margin-top: 20px;
}

.Chatgptbox {
  width: 120px;
  // height: 112px;
  flex-shrink: 0;
  border-radius: 0px 0px 0px 10px;
  background: #fff;
  justify-content: center;
  display: flex;
  justify-content: center;
}

.Smartflobox {
  width: 120px;
  // height: 112px;
  flex-shrink: 0;
  border-radius: 0px 0px 0px 10px;
  background: #fff;
  display: flex;
  justify-content: center;
  pointer-events: none;
  opacity: 0.4;
}

.Helpdeskbox {
  width: 120px;
  // height: 112px;
  flex-shrink: 0;
  border-radius: 0px 0px 0px 10px;
  background: #fff;
  display: flex;
  justify-content: center;
  pointer-events: none;
  opacity: 0.4;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Grey background with some transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.ai-support-container {
  position: fixed;
  bottom: 10px;
  right: 25px;
  z-index: 9999;
  cursor: pointer;

  .d-flex-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pb-4 {
    padding-bottom: 4px;
  }

  .ai-btn-wrapper {
    display: flex;
    justify-content: flex-end;

    .down-drop-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #002882;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;

      .down-arrow {
        fill: #fff;
        width: 30px;
        height: 30px;
      }
    }
  }

  .conversation-container {
    height: calc(100% - 285px);
    overflow-y: auto;
    padding: 0px 15px;
    overflow-x:hidden ;

    .chat-wrapper {
      .ai-chat-wrap {
        display: flex;
        gap: 4px;
        width: 90%;
        padding-top: 15px;

        .chat-text-wrap {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .msg-cont {
          display: flex;

          .answer-conatiner {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .chat-text {
              padding: 10px;
              color: #000;
              // font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              border-radius: 8px;
              background: #f3f3f7;
              position: relative;
            }

            .copy-icon-container {
              width: 15px;
              margin-top: 10px;
              cursor: pointer;
              position: relative;
              span{
                position: absolute;
                background-color: #000;
                color: #fff;
                border-radius: 30px;
                padding: 5px 15px;
                top: 38px;
                left: -35px;
                font-size: 12px;
                width: max-content;
              }
            }
          }

          .chat-btn {
            color: #0e94f4;
            // font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            border-radius: 3px;
            border: 1px solid #0e94f4;
            padding: 8px 10px;
            cursor: pointer;
          }
        }
      }

      .user-text-wrapper {
        width: 100%;
        padding-top: 15px;
        display: flex;
        justify-content: flex-end;

        .user-text {
          padding: 10px;
          border-radius: 8px;
          background: #ff6e00;
          color: #fff;
          // font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          width: fit-content;
          max-width: 95%;
        }
      }
    }
  }

  .input-wrapper {
    height: 130px;
    padding: 12px 15px;
    overflow: hidden;

    .text-field-wrap {
      width: 100%;
      box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.12);
      border-radius: 25px;

      .MuiInputBase-root {
        border-radius: 25px;
        // font-family: Inter;
        font-size: 14px;
        font-weight: 500;
      }

      .send-icon {
        path {
          fill: #ff6e00;
        }
      }
    }

    .regenerate-wrap {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 8px;
      padding-right: 4px;
      align-items: center;
      gap: 2px;
      cursor: pointer;

      svg {
        width: 15px;
        height: 15px;
      }

      .regen-text {
        color: #002882;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .cont-hide {
      visibility: hidden;
    }
  }

  .footer-nav {
    border-top: 1px solid #e5e8eb;
    height: 70px;
  }

  .gpt-icon {
    width: 22px;
    height: 22px;

    path {
      fill: #002882;
    }
  }

  .smtflo-icon {
    path {
      stroke: #4e4e4e;
      fill: #4e4e4e;
    }
  }

  .chat-container {
    width: 390px;
    height: 534px;
    max-height: 590px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    margin-right: 40px;
    z-index: 1000000;

    .header-wrapper {
      padding: 20px 15px;
      border-bottom: 1px solid #E5E8EB;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 79px;

      svg {
        cursor: pointer;
      }

      .header-text {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
      }

      .mandateid-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #727376;
      }
    }
  }

  .date-text-cls {
    color: #727376;
    font-family: Sora;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
    margin-left: 2px;
  }

  .chat-col-cls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .load-text-cls {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;

    .font-cls {
      font-family: Sora;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: right;
      color: #0E94F4;
      margin-right: 4px;
    }
  }

  .maximize-cls {
    position: fixed;
    top: 10px;
    /* left: 5%; */
    right: 2%;
    width: 80%;
    max-width: 1600px;
    height: 100vh !important;
    max-height: 95% !important;
    z-index: 10000000 !important;

    .conversation-container{
      height: calc(100% - 250px);
    }
    .input-wrapper{
      height: 180px;
    }
    .header-cls-maximize {
      border-radius: 10px 10px 0px 0px !important;
      background: #002882 !important;
      color: #FFFFFF !important;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-height: 66px;
    }

    .header-text-maximize {
      color: #FFF !important;
      font-family: Sora !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: normal !important;
    }

    .input-wrapper {
      .text-field-wrap {
        border-radius: 6px !important;
        background: #FFF !important;
        box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.12) !important;
        max-width: 794px;

        .MuiInputBase-root {
          border-radius: 6px !important;
          background: #FFF !important;
          box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.12) !important;
        }
      }
    }

    .chatgpt-text-maximize {
      color: #444654;
      text-align: center;
      font-family: Sora;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 10px;
    }
  }
  .addBorder{
    border: 2px solid #FF6E00;
    border-radius: 50%;
  }
  .addBorder .img{
    border: 2px solid #FFFFFF;
    border-radius: 50%;
  }
}