.candidate-tracking-container{
  padding: 14px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .d-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .container{
    padding-top: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 400;

    .mandate-search{
      width: 100%;

      .MuiInputBase-formControl{
        border-radius: 10px;
      }
    }

    .mandate-list{
      padding: 20px 0px;
      border-bottom: 1px solid #CFD1D2;
      overflow-x: auto;
      &::-webkit-scrollbar{
        height: 6px;
      }
      
      .tag-wrapper{
        max-width: calc(100vw - 762px);
        display: flex;
        gap: 10px;

        .mandate-tag-wrapper{
          width: auto;
        }

        .hire-man-tag{
          cursor: pointer;
          width: 265px;
        }
        .active-tag{
          border: 2px solid #0E94F4;
        }
      }
    }

    .hiring-header{

      .stage-dropdown{
        border: 1px solid #CFD1D2;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        gap: 10px;

        .status-tag{
          border-radius: 5px;
          background: #E5E9F3;
          color: #002882;
          font-size: 14px;
          font-weight: 400;
          padding: 4px 10px;
        }

        svg{
          margin-top: 10px;
        }
      }
      .stage-dropdown.disableStage{
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .mandate-location-tag{
      border-radius: 8px;
      background: #F0F6FB;
      width: fit-content;
      padding: 10px 15px;
      display: flex;
      flex-direction: column;

      .loc-text{
        color: #002882;
        font-size: 14px;
        font-weight: 400;
      }
      .loc-id{
        color: #727376;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .hiring-stages {
      padding: 10px 0px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .status-wrap {
        display: flex;
        gap: 10px;

        .svgcontainer {
          padding-top: 8px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 29px;
            transform: translateX(-50%);
            width: 1px;
            border-right: 1px dashed #B3B5B7;
            height: calc(100% - 7px);
          }

        }
        .MuiBox-root{
          width:100%;
        }
        .active::after {
          border-color: #2bad63;
        }
        .MuiBox-root{
          width:100%;
          }

        &:last-child .svgcontainer::after {
          border: none;
        }

        .disable-card {
          opacity: 0.5;
        }
        .candidateTrackingChildList{
          .MuiBox-root:last-child .candidateTrackingChildSvg:after{
            border: none;
          }
          .profile-card{
            grid-template-columns: 3fr 3fr 1fr 260px;
          }
          .profileCardCCM2{
            background-color: #f0f6fb;
          }
          .candidateTrackingChildListCCM{
            background: #1a85d6;
            color: #fff;
            padding: 3px 6px 3px 5px;
            font-size: 12px;
            margin-left: 5px;
          }
          .candidateTrackingChildActive{
            .candidateTrackingChildSvg{
              &:after {
                border-color: #2bad63;
              }
              .interviewStageRist {
                border-color: #2bad63;
                background-color: #2bad63;
                color: #fff;
                font-size: 12px;
              }
            }
          }
          .candidateTrackingChildSvg{
            display: flex;
            align-items: center;
            position: relative;

            &:after {
              content: '';
              position: absolute;
              left: 50%;
              top: 50px;
              transform: translateX(-50%);
              width: 1px;
              border-right: 1px dashed #B3B5B7;
              height: calc(100% - 7px);
            }

            .interviewStageRist{
              border: 1px solid #002882;
              color: #002882;
              background-color: #fff;
              border-radius: 5px;
              width: 30px;
              height: 30px;
              text-align: center;
              align-items: center;
              display: flex;
              justify-content: center;
              font-weight: 600;
              font-size: 12px;
            }
          }
        }
        .profile-card {
          display: grid;
          width: 100%;
          grid-template-columns: 2fr 4fr 1fr 260px;
          padding: 10px;
          border: 1px solid #E5E8EB;
          border-radius: 10px;
          gap: 10px;

          .primary-text {
            font-size: 14px;
              font-weight: 500;
            span.stageName{
              color: #000;
              padding: 5px 8px;
              border-radius: 5px;           
            }
          }

          .secondary-text {
            color: #727376;
            font-size: 13px;
            font-weight: 400;
          }

          .status-tag {
            border-radius: 5px;
            font-size: 14px;
            font-weight: 400;
            padding: 4px 10px;
            width: fit-content;
            height: fit-content;
          }

          .active-tag {
            background: #E8F5E9;
            color: #2BAD63;
          }

          .inprogrees-tag {
            background: #E5E9F3;
            color: #002882;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .disable-tag {
            background: #fff;
            color: #000;
          }

          .user-box {
            display: flex;
            gap: 10px;
            align-items: center;

            .recruiter-wrapper{
              width: calc(100% - 40px);
              .text-over-flow{
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .status-box {
            display: flex;
            justify-content: end;
            align-items: center;
            gap:10px
          }

          .remark-box {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .profile-card.currentStage {
          .stage-box{
            .primary-text{
              color: #002882;
              font-weight: 600;
            }
          }
        }
      }
    }
    }
}

.hiring-model{

  .model-container{
    background-color: #fff;
    width: 550px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
    border-radius: 10px;
    outline: none;

    .header-wrapper{
      display: flex;
      padding: 20px 20px 0px 20px;
      justify-content: space-between;
    }
    .hiring-model-scollBar{
      padding: 0px 20px 0px 20px;
      overflow: auto;
      max-height: calc(100vh - 150px);
    }
    .footer-wrapper{
      padding: 0px 20px 20px 20px;
    }

    .body-wrapper{
      .field-container{
        padding-top: 20px;

        .label-text{
          color: #727376;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .MuiTextField-root{
          width: 100%;

          .MuiInputBase-multiline{
            &:hover{
              fieldset{
                border-color: rgba(0, 0, 0, 0.23);
              }
            }
            border-radius: 10px;
          }
        }

        .mandate-location-tag{
          border-radius: 8px;
          background: #F0F6FB;
          width: fit-content;
          padding: 6px 14px;
          display: flex;
          flex-direction: column;

          .loc-text{
            color: #002882;
            font-size: 14px;
            font-weight: 400;
          }
          .loc-id{
            color: #727376;
            font-size: 12px;
            font-weight: 400;
          }
        }
        .MuiInputBase-formControl{
          border-radius: 10px;
        }

        .man-option-tag{
          width: 60%;
        }

        .Mui-focused{
          fieldset{
            border: 1px solid rgba(0, 0, 0, 0.23) !important;
          }
          
        }

        .status-tag{
          border-radius: 5px;
          background: #E5E9F3;
          color: #002882;
          font-size: 14px;
          font-weight: 400;
          padding: 4px 10px;
        }

        .remark-text{
          color: #000;
          font-size: 14px;
          font-weight: 400;
          word-break: break-all;
        }
      }
    }
  }
  
  .d-flex{
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }
  .canidateTrackInfoSect{
    flex-wrap: wrap;
    gap: 0px;
    .field-container{
      width: calc(96% / 2);
      flex: none;
    }
  }
  .flex-fill{
    flex: 1;
  }
  .card{
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #E5E8EB;
    flex: 1;
  }
  .interview-rounds{
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding-top: 15px;
    font-size: 14px;
    gap: 10px;
    .rounds{
      display: flex;
      align-items: center;
      gap: 5px;
      color: rgba(0, 0, 0, 0.7);
      &::before{
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        border-radius: 2px;
        background-color: #0E94F4;
      }
    }
    .MuiFormControlLabel-root{
      margin: 0;
      .MuiSwitch-root{
        height: 14px;
        width: 30px;
        margin-right: 5px;
        padding: 0 2px 0 0;
        .MuiSwitch-track{
          background-color: #b0bec5;
          opacity: 1;
        }
        .MuiButtonBase-root{
          padding: 1px;
          .MuiSwitch-thumb{
            width: 12px;
            height: 12px;
            background-color: #eceff1;
            box-shadow: none;
          }
          &.Mui-checked{
            transform: translateX(14px);
            .MuiSwitch-thumb{
              background-color: #0E94F4;
            }
            ~ .MuiSwitch-track{
              background-color: #0E94F4;
              opacity: 0.3;
            }
          }
        }
      }
      .MuiTypography-root{
        font-size: 14px;
      }
    }
    p{
      margin: 0px;
      display: flex;
      gap: 5px;
      font-size: 13px;
      align-items: center;
      line-height: 12px;
    }
  }
}

ul[aria-labelledby="status-select-label"]{
  max-height: 200px;
  .status-tag{
    border-radius: 5px;
    background: #E5E9F3;
    color: #002882;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 10px;
  }
}
.remarkSect{
  position: relative;
  p{
    margin: 0px;
    display: flex;
    font-size: 13px;
    gap: 5px;
    align-items: center;
    margin-top: 10px;
    color: #727376;
  }
  .remarkFileUpload{
    position: absolute;
    right: 15px;
    top: 15px;
    img{
      cursor: pointer;
    }
    
  }
}
.checkClear{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  label{
    color: rgba(0, 0, 0, 0.6);
    font-family: Sora;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 10px;
  }
  .checkClearList{
    display: flex;
    gap: 20px;
    p{
      margin: 0px;
      padding: 5px 12px;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 5px;
      line-height: 24px;
      cursor: pointer;
      span{
        width: 10px;
        height: 10px;
        border-radius: 2px;
      }
    }
    .checkClearListRed.active{
      border-color: red;
    }
    .checkClearListRed span{
      background-color: red;
    }
    .checkClearListAmber.active{
      border-color: #fcb12b;
    }
    .checkClearListAmber span{
      background-color: #fcb12b;
    }
    .checkClearListGreen.active{
      border-color: #2bad63;
    }
    .checkClearListGreen span{
      background-color: #2bad63;
    }
  }
}
.remarkFileAttach{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 40px;
  left: 15px;
  width: 94%;
  border-top: 1px solid #D9D9D9;
  padding-top: 10px;
  p{
    margin: 0px;
    display: flex;
    font-size: 13px;
    gap: 5px;
    align-items: center;
    color: #727376;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    position: relative;
    padding-left: 20px;
    &::before{
      content: "";
      width: 18px;
      height: 20px;
      background-image: url(../../../../assets/icons/remarkAttachIcon.svg);
      position: absolute;
      left: 0px;
    }
  }
  .MuiBox-root{
    display: flex;
    gap: 10px;
    img{
      cursor: pointer;
    }
  }
}
.candidateTrackFileUploaded{
  .remarkSect > .MuiTextField-root .MuiInputBase-root{
    padding-bottom: 60px;
  }
}
.offerAcceptSect{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 20px;
  .MuiBox-root{
    width: 100%;
    .MuiFormControl-root{
      width: 100%;
      .MuiInputBase-root{      
        border-radius: 10px;
        // box-shadow: 0px 4px 6px #d1d1d1;
          input[type=number]::-webkit-outer-spin-button,
          input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          input[type=number] {
            -moz-appearance: textfield;
          }
        fieldset{
          border: 1px solid #D9D9D9;
          border-radius: 10px;
        }
      }
    }
  }
}
.currentCandProcessStag{
  background: #FCF4E5;
  border-radius: 8px;
  width: fit-content;
  padding: 6px 14px;
  display: flex;
  flex-direction: column;
  color: #FF6E00;
  font-size: 14px;
}
.readOnlySection{
  pointer-events: none;
  opacity: 0.7;
}
.candidateTrackListClearStatus{
  margin: 0px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 4px;
  span.redCode{
    background-color: red;
  }
  span.amberCode{
    background-color: #fcb12b;
  }
  span.greenCode{
    background-color: #2bad63;
  }
  span{
    width: 10px;
    height: 10px;
    border-radius: 2px;
  }
}
.rejectedCase{
  .candidateTrackingChildSvg{
    .interviewStageRist{
      border-color: #f34334 !important;
      background: #fff !important;
      color: #f34334 !important;
    }    
  }
  .profile-card{
    background-color: #feeceb;
  }
}
.joinStageInfo{
  position: absolute;
  top: 65px;
  background: #f3f3f7;
  display: flex;
  gap: 20px;
  border-radius: 0px 0px 10px 10px;
  padding: 7px 20px;
  p{
    margin: 0px;
    font-size: 13px;
    color: #7f7f7f;
    span{
      color: #000;
      font-weight: 600;
    }
  }
}
.remarkAttShow{
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  span{
    color: #000;
    font-size: 14px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
  }
}