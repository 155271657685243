.header-left-items {
  display: flex;
  align-items: center;
  align-content: space-between;
}
.home-icon-div {
  padding: 0px 10px 0px 20px;
  height: 18px;
  cursor: pointer;
}
.home-icon {
  height: 16px;
  width: 16px;
}
.divider {
  height: 25px !important;
  position: relative;
  top: 10px;
}
.toolbar {
  margin-right: 15px;
}
.appbar {
  z-index: 9 !important;
  padding: 0 !important ; 
}
.MuiAppBar-root.appbar {
  background-color: #fff;
  height: 50px;
  padding-top: 10px !important;
}

.mobile-right-nav-icon {
  height: 24px !important;
  width: 24px !important;
}
.user-avatar-button {
  padding: 0px !important;
}
.mobile-right-menu {
  margin-right: 10px !important;
}

.user-menu-item {
  font-size: 14px !important;
  padding: 15px 0px 0px 0px !important;
  display: flex !important;
  gap: 10px;
  color:#000 !important;
}
.user-menu-item:hover{
  background-color: transparent !important;
}
.user-menu > .MuiPaper-root{
  box-shadow: 0px 0px 10px #dbdbdb;
  border-radius: 10px;
}
@media screen and (max-width:600px) {
  .appbar {
    width: calc(100% - 0px) !important;
    margin-right: 0px !important;
  }
  .hamburger-icon-div > button {
    color: rgba(0, 0, 0, 0.54);
  }
} 
.xpheno-navigationHeader input::placeholder{
  color: #000;
}
.xpheno-navigationHeader > .MuiStack-root button:hover{
  background-color: transparent;
}
.xpheno-navigationHeader .appHeaderRight{
  align-items: center;
  /* gap: 15px; */
}
.xpheno-navigationHeader .appHeaderRight button{
  padding: 5px 15px !important;
  height: auto;
  border-radius: 5px;
  display: flex;
  gap: 5px;  
}
.xpheno-navigationHeader .appHeaderRight button:hover{
  background-color: #000;
  color: #fff;
}
.copilotSwitchList{
  display: flex;
  align-items: center;
}
.copilotSwitchList ul{
  margin: 0px;
  padding: 0px;
  display: flex;
  gap: 0px;
}
.copilotSwitchList ul
.copilotSwitchList > h3{
      margin: 15px 0px;
  }
.copilotSwitchList ul + span.coPilotMiddle,.tourMiddle{
    width: 2px;
    display: block;
    height: 30px;
    background: #e5e8eb;
    margin: 0px 11px;
  }
.copilotSwitchList ul li{
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  margin-bottom: 0px;
  border: 2px solid #ffffff;
  border-radius: 20px;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  background-color: gray;
  position: relative;
}
.copilotSwitchList ul li:nth-child(1){
  z-index: 1;
}
.copilotSwitchList ul li:nth-child(2){
  margin-left: -10px;
  z-index: 2;
}
.copilotSwitchList ul li:nth-child(3){
  margin-left: -10px;
  z-index: 3;
}
.copilotSwitchList ul li:hover{
  z-index: 99;
  cursor: pointer;
}
.copilotSwitchList li img{
  border-radius: 8px;
  width: 34px;
  cursor: pointer;
}
.copilotSwitchList li span{
  font-size: 12px;
}
.copilotSwitchList li:hover .profileHoverSect{
  display: block;
}
.copilotSwitchList ul li .profileHoverSect{
  position: absolute;
  width: fit-content;
  background: #000000;
  top: 40px;
  right: 0px;
  border-radius: 10px !important;
  padding: 10px 15px;
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 180px;
  display: none;
 }
 .copilotSwitchList ul li .profileHoverSect .arrow-up {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #000000;
  position: absolute;
  top: -5px;
  right: 10px;

}
 .copilotSwitchList ul li .profileHoverSect .nameField {
  margin: 0px;
  font-size: 14px;
  text-transform: capitalize;
  color: #fff;
 }
 .copilotSwitchList ul li .profileHoverSect .userRole {
  color: #fff;
  font-size: 10px;
  font-weight: 500;
 }
 .coPilotAtciveMessage{
  background-color: #ffe8d4;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
  margin-left: 10px;
  border-radius: 3px;
 }
 .coPilotAtciveMessage > span{
  background-color: #ffb676;
  padding: 8px 7px 9px 10px;
  border-radius: 3px;
  width: 32px;
  height: 32px;
  display: flex;
 }
 .coPilotAtciveMessage p{
  margin: 0px;
  color: #000;
  font-size: 12px;
 }