.status-main {
    display: flex;
    max-width: 130px;
    width: fit-content;
    height: 34px;
    box-sizing: border-box;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0px 10px;

    .ring {
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        left: 1179px;
        top: 234px;
        border: 4px solid #0E94F4;
        border-radius: 50%;
    }

    .text {
        font-family: 'Sora';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0E94F4;
    }
}