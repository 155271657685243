body{
    .candidateActivityActionSect{
    display: flex;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 10px;
    padding-left: 15px;
    .mandateSelectField{
        > .MuiFormControl-root{
            > .MuiAutocomplete-root{
                > .MuiFormControl-root{
                    padding-top: 3px !important;
                    border-color: rgba(0, 0, 0, 0.23);
                    > label{
                        top: -4px;
                        font-size: 14px;
                    }
                    label:not(.MuiFormLabel-filled):not(.Mui-focused):not(.MuiInputLabel-shrink){
                        transform: translate(10px, 10px) scale(1);
                      }
                      label.MuiFormLabel-filled,label.Mui-focused{
                        transform: translate(5px, -9px) scale(0.75);
                      }
                }
            }
        }
        .MuiInputBase-root{
            padding-bottom: 0px !important;
            span.MuiAutocomplete-tag{
                font-size: 12px;
                margin-top: 0px;
                font-weight: 600;
                margin-left: -4px;
            }
            .MuiChip-colorDefault{
                height: 22px;
                width: 90px;
                left: -7px;
                top: -2px;
                .MuiChip-label{
                    font-size: 12px;
                }
                svg{
                    width: 14px;
                }
            }
            input{
                padding-top: 0px;
            }
            .MuiSelect-select{
                font-size: 12px;
            }            
        }
    }
    .MuiFormControl-root{
        fieldset{
            border-radius: 6px;
            border-color: rgba(0, 0, 0, 0.23);
        }
    }
    
    }
}
.candidateStatusCheck {
    align-items: flex-start !important;
    margin: 7px 0px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    > span{
        &:hover{
            background-color: transparent;
        }
        padding-top: 2px;
        padding-bottom: 0px;
        padding-left: 9px;
    }
}
.candidateStatusCheck.shortClientSub {
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 7px;
}
.respoBarChart{
    > .MuiBox-root{
        > div > div {
            // position: inherit !important;
            svg + div{
                z-index: 99999 !important;
                left: 30px !important;
                margin-top: 50px !important;
                > .MuiBox-root::before{
                    left: calc(50% - 30px) ;
                }
                > .MuiBox-root::after{
                    left: calc(50% - 30px) ;
                }
            }
        } 
    }
}
.respoBarChartScroll{
    overflow-y: auto;
    overflow-x: hidden;
    height: 400px;
    width: calc(100% - 150px);
    margin-bottom: 0px;
    margin-top: 20px;
}
.barYaxisCard{
    width: 565px;
    height: 25px;
    margin-top: 0px;
}