.mandate-profile {
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10px;

    .d-flex{
        display: flex;
        gap: 10px;
    }

    .header-container {
        padding: 20px 10px 10px;
        border-bottom: 1px solid #E5E8EB;
        display: flex;
        justify-content: space-between;

        .primary-text {
            color: #000;
            font-size: 16px;
            font-weight: 600;
        }

        .right-pane {
            display: flex;
            align-items: center;

            .toggle-btn {
                .Mui-selected {
                    background-color: #002882 !important;
                }
            }

            .down-svg{
                path{
                    stroke: #fff !important;
                }
            }
        }
    }

    .mandate-container{
        display: flex;
        justify-content: space-between;
        padding: 10px;

        .mandate-location-dropdown{
            width: 100%;
    
            .mandate-location-list{
                width: 100%;
                min-width: 265px;
                border-radius: 10px;

                .MuiOutlinedInput-input{
                    padding: 8px 32px 8px 14px !important;
                }
            }
    
            .MuiInputBase-input{
    
                .primary-text{
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                }
        
                .secondary-text{
                        color: #727376;
                        font-size: 12px;
                        font-weight: 400;
                }
    
            }
        }

        .man-wrapper{
            padding: 10px;
            border: 1px solid #E5E8EB;
            border-radius: 10px;
            height: fit-content;
            min-width: 260px;

            .mand-text{
                display: flex;
                flex-direction: column;
                color: #000;
                font-size: 14px;
                font-weight: 400;

                .mand-id{
                    color: #727376;
                    font-size: 12px;
                }
            }
        }
    }

    .body-container{
        position: relative;
        min-height: 70%;

        .primary-text{
            color: #000;
            font-size: 13px;
            font-weight: 400;
        }

        .secondary-text{
            color: #727376;
            font-size: 13px;
            font-weight: 400;
            align-items: center;
        }

        .filter-container{
            padding: 10px 15px;
            border: 1px solid #CFD1D2;
            border-radius: 10px;
            margin-top: 20px;

            .search-wrapper{
                padding: 20px 0px;
            }

            .filter-button-group{
                align-items: center;
                display: flex;
                justify-content: end;
                padding: 10px 10px 0px;
                border-top: 1px solid #CFD1D2;

                .filter-submit-button {
                        width: 5rem;
                        height: 1.9rem;
                        margin-left: 1rem;


                        background: #000000;
                        border-radius: 8px;

                        font-size: 0.7rem;
                        color: white;
                        text-transform: none;
                }
                .filter-reset-button {
                        padding: 0rem;
                        width: auto;
                        margin-left: 1rem;
                        svg {
                                font-size: 0.6rem;
                        }
                        .filter-reset-label {
                                margin-left: 0.4rem;
                                font-size: 0.7rem;
                                color: #002882;
                                text-transform: none;
                        }
                }
            }

            .select-dropdown{
                min-width: 265px;
            }
        }

        .profile-container{
            padding: 20px 0px;
            position: relative;

            .profile-card{
                border-radius: 10px;
                border: 1px solid #E5E8EB;
                margin-bottom: 15px;

                .header{
                    padding: 15px;
                    display: flex;
                    justify-content: space-between;

                    .address-icons{
                        svg{
                            width: 13px;
                            height: 13px;
                        }
                        .mandateProfilecCompany{
                            max-width: 420px;

                            .secondary-text{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }

                    .status-icon-wrapper{
                        display: flex;
                        gap: 10px;
                        span{
                            padding: 5px 10px;
                            font-size: 13px;
                            font-weight: 400;
                            border-radius: 3px;
                            height: fit-content;
                        }
                        .color-1{
                            color: #1A85D6;
                            border: 0.5px solid #1A85D6;
                            background: #EAF4FB;
                        }
                        .color-2{
                            color: #002882;
                            border: 1px solid #FFF;
                            background: #E5E9F3;
                            min-width: 110px;
                            text-align: center;
                            max-width: 200px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .footer{
                    background: #F3F3F7;
                    display: flex;
                    justify-content: space-between;
                    padding: 8px 15px;

                    .loc-icon{
                        path{
                            stroke: #FF6E00 !important;
                        }
                    }
                }

                .avatar-div{
                    font-size: .875rem;
                    font-style: normal;
                    font-weight: 400;
                    height: 2.6875rem;
                    width: 2.6875rem;
                }
            }
        }

        .stage-tab{
            width: calc(100% + 40px);
            margin: 0 20px 0 -20px;
            background-color: #F3F3F7;
            display: flex;
            padding: 10px 10px 0px;
            overflow-x: auto;
            &::-webkit-scrollbar{
        height: 6px;
      }

            .stage-card{
                background-color: #F3F3F7;
                cursor: pointer;
                padding-bottom: 10px;
                min-width: 212px;

                .active-bar{
                    // border-radius: 0px 10px 10px 0px;
                    background: #002882;
                    height: 2px;
                    margin: 0 10px;
                }

                .ele-card{
                    display: flex;
                    gap: 6px;
                    justify-content: center;
                    padding: 12px 20px 10px;
                    // border-right: 1px solid #CFD1D2;
                }

                .divider-right{
                    border-right: 1px solid #CFD1D2;
                }

                .ele-header{
                    color: #727376;
                    font-size: 12px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                .ele-count{
                    color: #000;
                    font-size: 24px;
                    font-weight: 500;
                }
            }
            .active-card{
                background-color: #fff;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;

                .icon-cont{
                    div{
                        background-color: #002882 !important;
                    }
                    svg{
                        g{
                            #Rectangle{
                                fill:#002882 !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .MuiInputBase-root{
        border-radius: 10px !important;
    }
}

.show-more-popup{
    .popup-wrapper{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 15px;
        margin: 0px;
        
        .icon-wrapper{
            display: flex;
            gap: 4px;
            align-items: center;
            cursor: pointer;

            .notes-icon{
                path{
                    fill: #727376 !important;
                }
            }

            .pop-text{
                color: #000;
                font-size: 13px;
                font-weight: 400;
            }
        }

        .disable-popup{
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
.mandateProfileListScrollSect{  
    position: relative;
    .bulkStatusUpdateSect{
        position: fixed;
        bottom: 2px;
        margin-left: -20px;
        background: #fff;
        width: calc(100% - 177px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        box-shadow: 0px 0px 8px #e3e3e3;
        max-width: 1422px;
        .bulkStatusUpdateCheckSect{
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            > span{
                padding: 0px;
                svg{
                    width: 20px;
                }
            }
            > p{
                margin: 1px 0px 0px 0px;
                font-size: 14px;
                span{
                    color: #002882;
                }
            }
        }
        button.Mui-disabled{
            opacity: 0.5;
            color: #fff;
        }
    }
    .bulkStatusUpdateSect.menuExpand{
        width: calc(100% - 358px);
    }
}
.mandateScrollProfile{
    overflow: auto;
    height: calc(100vh - 370px);
    padding-bottom: 20px;
}
.bulkStateFilter{
    display: flex;
    align-items: center;
    gap: 3px;
    svg{
        font-size: 14px;
        color: #002882;
    }
    p{
        margin: 0px;
        line-height: 12px;
        font-size: 10px;
        color: #002882;
        font-weight: 500;
    }
}