.collaboratorPopupSect {
    .MuiInputBase-root {
    border-radius: 10px !important;
    }
    .pb-30{
        padding-bottom: 30px;
    }
    .header-wrapper{
        padding-left: 20px;
        padding-right: 20px;
    }
    .collaboratorField{
        .MuiAutocomplete-root{
            > .MuiFormControl-root{
                label:not(.MuiInputLabel-shrink){
                    font-size: 14px;
                    transform: translate(16px, 14px) scale(1);
                }
                .MuiInputBase-root{
                    padding: 5px;
                    .MuiButtonBase-root{
                        height: auto;
                        border-radius: 5px;
                        padding: 3px;
                        font-size: 12px;
                        span{
                            padding-left: 5px;
                        }
                        svg{
                            margin-right: 0px;
                        }
                    }
                    > span.MuiAutocomplete-tag{
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }
        .MuiAutocomplete-root:not(.Mui-expanded):not(.Mui-focused){
            .MuiFormControl-root{
                .MuiInputBase-root{
                    height: 48px;
                }
            }
        }
        > label:not(.MuiInputLabel-shrink){
            font-size: 14px;
            transform: translate(16px, 14px) scale(1);
        }
        > .MuiInputBase-root{
            height: 48px;
        }
        .collaboratorLocationArray{
            .MuiGrid-root{
                > .MuiFormControl-root{
                    > label:not(.MuiInputLabel-shrink){
                        font-size: 14px;
                        transform: translate(16px, 14px) scale(1);
                    }
                    > .MuiInputBase-root{
                        height: 48px;
                    }
                }               
            }
            .button-container{
                button{
                    border: none;
                    padding-left: 0px;
                }                
            }
            .numberFieldSect{
                .numberFieldSectIncDec{
                    padding: 0px;
                    height: 48px;
                    box-shadow: none;
                    border-color: rgba(0, 0, 0, 0.23);
                    button{
                        width: 45px;
                        height: 24px;
                        margin-left: -24px;
                        background-color: #d9d9d9;
                        &:hover{
                            svg{
                                color: #000;
                            }
                        }
                        svg{
                            width: 18px;
                        }
                    }
                    input{
                        width: calc(100% - 17px);
                    }
                    &:hover{
                        border-color: #DAE2ED;
                    }
                }
                .numberFieldSectIncDec.base--focused{
                    border-color: #DAE2ED;
                    box-shadow: none;
                }
            }
            // >div:last-child{
            //     margin-bottom: 0px !important;
            // }
        }
        
    }
}
.popup-wrap{
    form{
        > .button-container{
            .save-btn{
                &:hover{
                    background-color: #002882;
                    color: #fff;
                }                
            }
            button.Mui-disabled{
                opacity: 0.5;
                color: #fff;
            }
        }
        .collaboratorInfoSect{
            display: flex;
            gap: 5px;
            margin-top: 5px;
            p{
                margin: 0px;
                font-size: 12px;
            }
        }
        .collaboratorMandateListSect{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #f0f6fb;
            border-radius: 10px;
            padding: 10px 10px;            
            > .MuiBox-root{
                display: flex;
                align-items: center;
                gap: 10px;
                width: 100%;
                .collabMandateListLeft{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: 100%;
                }
                .collabMandateListRight{
                    width: 75%;
                    p{
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: left;
                        gap: 10px;
                        span{
                            display: flex;
                            align-items: center;
                            color: #ff5500;
                            svg{
                                width: 18px;
                                height: auto;
                                background: transparent;
                                border-radius: 0px;
                                padding: 0px;
                            }
                        }                        
                    }                    
                }
                .collNotFound{
                    background-color: #fff;
                    display: flex;
                    padding: 6px;
                    border-radius: 40px;
                    svg{
                        background: transparent;
                        width: 20px;
                        height: 20px;
                        padding: 0px;
                        border-radius: 0px;
                    }
                }
                svg{
                    background: #cfe3f6;
                    padding: 6px;
                    width: 35px;
                    height: 35px;
                    border-radius: 20px;
                }
                p{
                    margin: 0px;
                    font-size: 13px;
                    line-height: 16px;
                    span{
                        font-weight: 500;
                    }
                }
            }
            .collNotFoundSect{
                display: flex;
                justify-content: space-between;
                width: 100%;
                .collNotFoundSectLeft{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                .collNotFoundSectRight{
                    p{
                        display: flex;
                        align-items: center;
                        text-align: center;
                        justify-content: left;
                        gap: 10px;
                        span{
                            display: flex;
                            align-items: center;
                            svg{
                                width: 18px;
                                height: auto;
                                background: transparent;
                                border-radius: 0px;
                                padding: 0px;
                            }
                        }                        
                    }                    
                }
            }
        }
        .disableCollList{
            background-color: #f3f3f7;
            .collNotFoundSect{
                .collNotFoundSectLeft{
                    opacity: 0.5;
                    pointer-events: none;
                }
                .collNotFoundSectRight.disableApproverList{
                    opacity: 0.5;
                    pointer-events: none;
                }
                .collNotFoundSectRight{
                    span{
                        color: #ff5500;
                    }
                }
            }
        }
        .MuiFormControl-root{
            .MuiInputBase-root:hover,.MuiInputBase-root.Mui-focused{
                fieldset{
                    border-color: rgba(0, 0, 0, 0.23);
                    border-width: 1px;
                }
            }
        }
    }
}
.collaboratorApprovalListScroll{
    overflow: auto;
    max-height: 500px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    .collabortorListAccordion{
        box-shadow: none !important;
        margin-top: 0px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.23);
        padding-bottom: 15px;
        &::before{
            display: none;
        }
        > .MuiButtonBase-root{
            background-color: #f0f6fb !important;
            min-height: auto;
            padding: 10px 10px;
            border-radius: 10px;
            > .MuiAccordionSummary-content{
                margin: 0px;
            }
        }
        .collaboratorMandateListSect .MuiBox-root{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            cursor: pointer;
            gap: 10px;
            width: 100%;
            svg{
                background: #cfe3f6;
                padding: 6px;
                width: 35px;
                height: 35px;
                border-radius: 20px;
            }
            p{
                margin: 0px;
                font-size: 14px;
                // line-height: 3px;
                span{
                    font-weight: 700;
                }
            }
        }
    }
    .collaboratorApprovalList{
        .collaboratorApprovalListSect{
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-top: 20px;
            gap: 20px;
            .approvalListLeft{
                display: flex;
                gap: 15px;
                width: 40%;
                span{
                    background: #fcf4e5;
                    padding: 10px;
                    border-radius: 10px;
                    color: #e5bd52;
                    text-transform: uppercase;
                    min-width: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .MuiBox-root{
                    h5{
                        margin: 0px;
                        font-size: 14px;
                        font-weight: 500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        width: 250px;
                    }
                    p{
                        margin: 0px;
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.6);
                    }
                }
            }
            .approvalListMiddle{
                width: 40%;
                .AccessList{
                    background: #f3f3f7;
                    padding: 13px 15px;
                    border-radius: 10px;
                    p{
                        margin: 0px;
                        font-size: 13px;
                        font-weight: 500;
                        display: flex;
                        gap: 5px;
                    }
                }
                .AccessList.removeAccessSect{
                    background: #feeceb;
                }
                .AccessLocationList{
                    display: flex;
                    gap: 10px;
                    margin-top: 5px;
                    p{
                        margin: 0px;
                        border: 1px solid rgba(0, 0, 0, 0.23);
                        font-size: 13px;
                        padding: 3px 10px;
                        border-radius: 50px;
                        span{
                            color: #002882;
                            font-weight: 600;
                        }
                    }
                }
            }       
            .approvalListRight{
                display: flex;
                gap: 10px;
                width: 30%;
                button.Mui-disabled{
                    opacity: 0.5;
                }
                button.approveBtn{
                    border: 1px solid #45bb78;
                    border-radius: 10px;
                    padding: 8px 15px;
                    font-size: 14px;
                    color: #45bb78;
                    font-weight: 600;
                    text-transform: capitalize;
                    width: 100px;
                    &:hover{
                        background-color: #fff;
                    }
                    span{
                        margin-right: 3px;
                        margin-top: -2px;
                        svg{
                            font-size: 16px;
                        }
                    }
                }
                button.rejectBtn{
                    border: 1px solid #f3493a;
                    border-radius: 10px;
                    padding: 8px 15px;
                    font-size: 14px;
                    color: #f3493a;
                    font-weight: 600;
                    text-transform: capitalize;
                    width: 90px;
                    &:hover{
                        background-color: #fff;
                    }
                    span{
                        margin-right: 3px;
                        margin-top: -2px;
                        svg{
                            font-size: 16px;
                        }
                    }
                }
            } 
        }
    }
    .collaboratorListSect{
        margin-bottom: 30px;
        > h3{
            font-size: 16px;
            // border-top: 1px solid rgba(0, 0, 0, 0.23);
            // padding-top: 15px;
        }
        .approvalListRight{
            gap:0px !important;
            button:hover{
                background-color: transparent !important;
            }
        }
    }
}
.collaboratorReasonModal{
    .popup-wrap{
        .header-wrapper{
            align-items: center;
            margin-top: 20px;
            padding-bottom: 0px;
            h1{
                margin: 0px;
                font-size: 16px;
            }
        }
        .collaboratorApprovalListScroll{
            padding-top: 20px;
            > .MuiTextField-root{
                width: 100%;
            }
        }
        .button-container{
            .deactive-btn{
                background-color: red;
                color: #fff;
            }
            .deactive-btn.Mui-disabled{
                opacity: 0.5;
            }
            .cancel-btn:hover{
                background-color: #E5E8EB;
            }
        }
    }
}