.mandate-status-model{

  .container{
    background-color: #fff;
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;

    .header-wrapper{
      display: flex;
      justify-content: space-between;
      p{
        color: #000;
        font-size: 16px;
        font-weight: 600;
      }
      svg{
        cursor: pointer;
      }
    }

    .body-wrapper{
      .field-container{
        padding-top: 20px;

        .label-text{
          color: #727376;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .MuiTextField-root{
          width: 100%;

          .MuiInputBase-multiline{
            border-radius: 10px;
          }
        }
        .MuiInputBase-formControl{
          border-radius: 10px;
        }

        .man-option-tag{
          width: 270px;
        }

        .Mui-focused{
          fieldset{
            border: 1px solid rgba(0, 0, 0, 0.23) !important;
          }
          
        }
        .status-tag{
          border-radius: 5px;
          background: #EFF6FC;
          color: #0E94F4;
          font-size: 14px;
          font-weight: 400;
          padding: 4px 10px;
        }

        .remark-text{
          color: #000;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .footer-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 20px;
      gap: 15px;
    }
  }

}

ul[aria-labelledby="mandate-status-label"]{
  max-height: 180px;
  .status-tag{
    border-radius: 5px;
    background: #EFF6FC;
    color: #0E94F4;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 10px;
  }
}
ul[aria-labelledby="mandate-status-label-filter"]{
  max-height: 40px;
  .status-tag{
    border-radius: 5px;
    background: #EFF6FC;
    color: #0E94F4;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 10px;
  }
}