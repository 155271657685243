.login-form-container-grid {
  max-width: 70rem;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32), 0px 4px 8px rgba(91, 104, 113, 0.24);
  border-radius: 10px;
  max-height: 44rem;
}
.forgot-password-link {
  font-size: 0.9rem !important;
  text-align: right;
  margin-bottom: 1rem !important;
}

.login-screen-error-note {
  color: rgb(206, 16, 16);
  font-size: 14px !important ;
}
.underMaintenanceBlk{
  display: flex;
  flex-direction: column !important;
  align-items: center;
}
.underMaintenanceBlk .underMaintenanceSect{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  gap: 15px;
}
.underMaintenanceBlk .underMaintenanceSect .login-screen-welcome-note{
  font-weight: 600 !important;
  font-size: 17px !important;  
}
.loginRightTopLogoSect{
  display: flex;
  justify-content: space-between;
  margin: 15px 0px 60px 0px;
  width: 100%;
}
/* @media (max-width: 1000px) {
  .loginRightTopLogoSect{
    gap: 30px;
  }
} */
 .loginForgotPassword{
  display: flex;
  justify-content: end;
 }
 .loginForgotPassword p{
    color: rgb(54, 131, 220);
    cursor: pointer;
    width: fit-content;
  }
  .loginForgotPassword p:hover{
    text-decoration: underline;
  }