.mandate-card-wrapper {

    border-radius: 10px;
    flex-shrink: 0;
    border: 1px solid #D9D9D9;
    width: 300px;

    .mandate-card-container {
      
        padding: 10px 10px 0 10px;

        .mandate-card-detail {
            width: 100%;
            display: flex;
            gap: 5px;
            .mandateCardjobOrderInfo{
                display: flex;
                align-items: center;
                gap: 5px;
                span{
                    font-size: 13px;
                    color: #002882;
                }
            }
            .mandate-card-title {
                color: #000;
                font-family: Sora;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                gap: 5px;
                align-items: center;
            }

            .mandate-card-id {
                color: #727376;
                font-family: Sora;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .assign-block{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .change-option{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap:5px;

                .edit-icon {
                    cursor: pointer;
                    path {
                        stroke: #0E94F4;
                    }
                }
                .disableIcon{
                    opacity: 0.5;
                    pointer-events: none;
                }

                .unassign-icon{
                    cursor: pointer;
                }
            }
        }
    }
}

.mandate-card-client {
    border-radius: 0px 0px 10px 10px;
    background: #F3F3F7;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 8px;
    font-size: 13px;
    font-weight: 400;
    color: #000;

    .d-flex{
        display: flex;
        gap: 4px;
        align-items: center;
        max-width: 95px;
        .overflow-restriction{
          overflow: hidden;
          width: 10rem;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        .createdByText{
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-wrap: nowrap;
            width: 70px;
            display: block !important;
        }
        .workFlowClientName{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-wrap: nowrap;
            width: 70px;
            display: block !important;
        }
  
        svg{
          width: 16px;
          height: 16px;
          path{
            stroke: #002882 !important;
          }
        }
      }

}

.candidate-allocation-avt-popup{
    .cand-popup-container{
        padding: 20px;
        .cand-wrap{
            display: flex;
            gap: 10px;
            padding: 4px 0;
            align-items: center;

            .cand-name{
                color: #000;
                font-family: Sora;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}