@mixin centerText {
    align-self: center;
    text-align: center;
    font-family: Sora;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;
  }

.recruiter-tag-wrapper{

    border-radius: 10px;
    flex-shrink: 0;
    border: 1px solid #D9D9D9;
    width: 210px;
    margin-right: 191px;
        .recruiter-container{

        display: flex;
		justify-content: space-between;
		padding: 10px;
        .recruiter-detail{
            color: #000;
            font-family: Sora;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            gap: 5px;
            align-items: center;

            .recruiter-title{
                width: 155px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                color: #000;
            }
            .recruiter-id{
                color: #727376;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    .mandate-drop-container{
        border-radius: 0px 0px 10px 10px;
        background: #F3F3F7;
        display: flex;
        flex-direction: row;
		justify-content: space-between;
        gap: 10px;
        padding: 8px 8px 6px;
        font-size: 13px;
        font-weight: 400;
        color: #000;

        .mandate-group{
            display: flex;
            flex-direction: row;
            width: 160px;
            justify-content: space-between;
            .recruiterCardInfo{
                display: flex;
                gap: 5px;
                align-items: center;
                svg{
                    margin-top: -3px;
                    height: 20px;
                }
                div{
                    height: 16px;
                    margin-top: -5px;
                }
            }
        }
        > span{
            display: flex;
        }

        .mandate-icon{
            fill:#002882
        }
    
    }

}
.recruiter-tag-wrapper.active{
    border-color: #1976d2;
    border-width: 2px;
}
.sticky-left{
    position: sticky;
    left: 0;
    background-color: #FFFFFF;
    z-index: 1;
}

.Target-container {

    width: 130px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
 

    .box {

       
        width: 70px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid #CFD1D2;
         
     

        .job-count{
            @include centerText;
            width: 100%;
            color: #000;
            span{
                font-size: 18px;
            }
        }
        .job-count-target{
            @include centerText;
            width: 100%;
            color: #000;
        }
    }

    .target-box {
        background: #F3F3F7;
    }



    .side-line{
        height: 30px;
        flex-basis: 1%;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    
    .target p{
        span{
            color: #727376 !important;
        }        
        font-size: 13px !important;
        color: gray !important;
    }
    .good p{
        span{
            color: #80CEA1 !important;
        }
        font-size: 13px !important;
        color: gray !important;
    }

    .critical p{
        span{
            color: #f34637 !important;
            margin-right: 2px;
        }
        font-size: 13px !important;
        color: gray !important;
    }
    .attention p{
        span{
            color: #FDD080 !important;
        }
        font-size: 13px !important;
        color: gray !important;
    }


}


.recruiter-view-header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    padding-bottom: 16px;

    .my-team-container {
        display: flex;
        flex-direction: row;
        width: 210px;
        align-items: center;


        .my-team {
            color: #000;
            font-family: Sora;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
        }
    }

    .recruiter-view-header-text {
        @include centerText;

        color: #4E4E4E;
    }

}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
    .recruiter-tag-wrapper{
        margin-right: 220px;
    }
    .week-view-table .recruiter-tag-wrapper{
        margin-right: 200px;
    }

}
@media screen and (min-width: 1500px) {
    .week-view-table .recruiter-tag-wrapper{
        margin-right: 215px;
    }
}