.callNotification{
    position: absolute;
    right: 80px;
    top: 60px;
    background-color: #fff;
    z-index: 9999;    
    border-radius: 15px;
    box-shadow: 0px 0px 4px #cecece;    
    .callNotificationSect{
        padding: 20px 20px 20px 20px;
        align-items: center;
        display: flex;
        gap: 15px;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            width: calc(100% - 30px);
            height: 2px;
            background-color: #ff6e00;
            top: 0px;
            left: 15px;
        }
        .callNotificationContent{
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            h4{
                margin: 0px;
                font-size: 15px;
                font-weight: 600;
            }
            p{
                margin: 0px;
                font-size: 13px;
                font-weight: 400;
                color: grey;
            }
        }
        .callUserImg{
            width: 55px;
        }
        .callGifImg{
            width: 40px;
        }
    }    
}