@import '../../../styles/globals';

.candidate-sidebar-section-box {
    height: calc(100vh - 5.2rem);
    // margin-left: 1.25rem;
    display: flex;
    flex-direction: row;
    .candidate-sidebar-filter-form {
        height: 100%;
        width: 100%;
    }
    .candidate-sidebar-filter-form-mobile {
        position:fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        .candidate-sidebar-body-section {
            height: calc(100vh - 9rem);
        }
    }

    .candidate-sidebar-filter-form, .candidate-sidebar-filter-form-mobile {
        &.show-form {
            display: block;
        }
        &.hide-form {
            display: none;
        }
    }

    .candidate-sidebar-filter-menu {
        width: 3.75rem;
        // border-left: 1px solid #E5E8EB;
        height: 100%;
        padding-top: 1.25rem;
        align-items: right;
        justify-content: right;
        flex-direction: column;
        align-items: end;
    }



    .sidebar-menu-box {
        width: 3.125rem;
        height: 2.5rem;
        margin-bottom: 0.5rem;
        cursor: not-allowed;

        &.active {
            cursor: pointer;
            background: #F0F6FB;
            border-left: 1.5px solid #002882;
            svg {
                path {
                    stroke: #002882;
                }
            }
        }

        svg {
            path {
                stroke: #B3B5B7;
            }
        }

        .sidebar-menu-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

    }

    .candidate-sidebar-header-section {
        display: flex;
        height: 3.5rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E5E8EB;

        .candidate-sidebar-header-title {
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;

            color: #000000;
        }

        .candidate-sidebar-close-icon {
            font-size: .9875rem;
            color: #727376;
            cursor: pointer;
        }
    }

    .candidate-sidebar-body-section {
        height: calc(100vh - 260px);
        overflow: auto;
        overflow-x: hidden;
        padding-left: 1rem;
        padding-right: 1rem;
        @include scrollbars();
        // margin-right: 0.5rem;
        padding-top: 1.25rem;

        .input-section-title-container {
            margin-top: .375rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1.3125rem;
            .input-section-title {
                font-weight: 500;
                font-size: .775rem;                
                color: #002882;
                margin-right: 0.5rem;
            }
            .input-section-line {
                height: 1px;
                border: 1px solid #E5E8EB;
                flex: 1;
            }
        }

        .sidebar-filter-field-label {
            color: #727376;
            font-size: 0.8rem;
        }

        .sidebar-filter-radio-label .MuiFormControlLabel-label {
            font-size: 0.8rem;
            color: #000000;
        }

        .sidebar-filter-radio-button {
            color: #FF6E00;
            font-size: 0.7rem;
        }

        .sidebar-filter-container {
            margin-bottom: 1.3125rem;
            .sidebar-filter-dropdown-label {
                // top: -6px;
                font-size: 0.7rem;
                line-height: 0.7rem;
            }
            .sidebar-filter-dropdown {
                height: 2.325rem;
                // .MuiInputBase-root {
                //     // width: 100%;
                //     height: 2rem;
                // }
                .MuiInputBase-input {
                    padding-right: 0px;
                    font-size: 0.7rem;
                }
            }

        }

        .range-form-control {
            margin-top: 0.4rem;
            margin-left: 0.9rem;
            padding-right: 1.8rem;
            margin-bottom: 2.5rem;
            position: relative;
            .select-unit-type-container {
                position: absolute;
                top: 0.1rem;
                right: 3.4rem;
                font-size: 0.6rem;
            }
            .select-unit-dropdown {
                position: absolute;
                height: 0.8rem;
                top: 0rem;
                right: -3.3rem;
                font-size: 0.5rem;
                box-shadow: none;
                color: #002882;
                .MuiOutlinedInput-notchedOutline {
                    border: 0px;
                }
            }
            .range-label {
                margin-top: 0em;
                margin-left: -0.8rem;
                font-size: 0.7rem;
            }

            .MuiSlider-rail {
                background: #D9D9D9;
            }
            .MuiSlider-track {
                color:#FF6E00;
            }
            .MuiSlider-thumb {
                border: 1px solid #FF6E00;
                background-color: #fff;
            }
            .MuiSlider-root {
                margin-top: 0.8rem;
            }
        }

        .last-active-wrapper {
            margin-top: 1.3125rem;
        }


    }

    .candidate-sidebar-footer-section {
        height: 5rem;
        display: flex;
        border-top: 1px solid #CFD1D2;
        align-items: center;
        justify-content: center;

        .candidate-sidebar-footer-button {
            width: 5rem;
            height: 2.2rem;
            border-radius: .5rem;
            font-size: .775rem;
            color: #FFFFFF;
            text-transform: none;

            &.apply-button {
                background: #002882;
            }
            &.reset-button {
                background: #E5E8EB;
                margin-right: .625rem;
            }
        }
    }

}

.advanceFilterCandidate .MuiAutocomplete-root .MuiInputBase-root{
    height: 40px;
    padding-top: 5px;
}
.advanceFilterCandidate .MuiAutocomplete-root .MuiInputBase-root input{
    font-size: 13px;
}
.advanceFilterCandidate .MuiAutocomplete-root label{
    transform: translate(14px, 10px) scale(1);
    font-size: 12px;
}
.advanceFilterCandidate .MuiAutocomplete-root label.Mui-focused,
.advanceFilterCandidate .MuiAutocomplete-root label.MuiFormLabel-filled{
    transform: translate(14px, -9px) scale(1);
    font-size: 9px;
}
.advanceFilterCandidate .MuiAutocomplete-root label.Mui-focused + .MuiInputBase-root fieldset legend,
.advanceFilterCandidate .MuiAutocomplete-root label.MuiFormLabel-filled + .MuiInputBase-root fieldset legend{
    font-size: 9px !important;
}
.candiFilterLoader{
    height: calc(100vh - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    p{
        margin: 0px;
        font-size: 12px;
    }
}