.monthlyFunActivity{
    position: fixed;
    width: 100%;
    z-index: 9999;
    height: 100vh;
    background: #0000004f;
    display: flex;
    justify-content: center;
    align-items: center;    
    .monthlyFunActivitySect{
        background: #ebedff;
        width: 50%;
        min-height: 300px;
        border-radius: 20px;
        padding: 20px;
        position: relative;
        .close{
            position: absolute;
            right: 20px;
            cursor: pointer;
            background: #3c3c3c;
            border-radius: 30px;
            padding: 3px;
            color: #fff;
            z-index: 9;
        }
        .monthlyFunActivitySectContent{
            position: relative;
            .monthlyFunActivitySectContentBlk{
                padding: 40px 10px 0px 170px;
                h4{
                    font-size: 18px;
                    margin: 0px;
                    font-weight: 600;
                }
                p{
                    font-size: 14px;
                    font-size: 18px;
                    font-weight: 600;
                }
                // q{
                //     &::before{
                //         font-size: 40px;
                //         margin-right: 5px;
                //         line-height: 0px;
                //     }
                //     &::after{
                //         font-size: 40px;
                //         margin-left: 5px;
                //         line-height: 0px;
                //     }
                // }
            }
            img{
                position: absolute;
                top: 90px;
                width: 100px;
                left: 20px;
            }
        }  
        .aprilFoolSect{
            display: flex;
            justify-content: space-around;
            min-height: 260px;
            align-items: center;
            h4{
                margin-top: 60px;
                font-size: 20px;
                margin-bottom: 0px;
            }
            p{
                margin: 0px;
                font-size: 16px;
                font-weight: 600;
                span{
                    color: #fbfbfb;
                    font-weight: 600;
                    background: #e04b65;
                    border-radius: 10px;
                    padding: 0px 10px;
                    text-transform: uppercase;
                }
            }
        }      
    }
}