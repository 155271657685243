.skills-wrap {
    .skill-sec-main {
        // border-bottom: 1px solid #E5E8EB;

        last-child {
            border-bottom: none;
        }

        .skill-header {
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            font-family: 'Sora';
        }

        .skill {
            display: flex;
            flex-direction: column;

            table {
                border-spacing: 0px;
            }
                thead 
                    th{
                        color: #727376;
                        font-size: 12px;
                        font-weight: 500;
                        padding: 10px 20px;
                        text-align: left;
                        background: #F3F3F7;
                        &:nth-child(1){
                            border-radius: 10px 0px 0px 10px;
                        }
                        &:last-child{
                            border-radius: 0px 10px 10px 0px;
                        }
                    }
                    
                    
                tbody 
                    tr 
                        td{
                            color: #000;
                            font-size: 12px;
                            font-weight: 500;
                            padding: 10px 20px;
                            text-align: left;
                            border-bottom: 2px solid#F3F3F7;
                        }
            .skill-sec {
                display: flex;
                flex-wrap: wrap;
                // display: grid;
                // grid-template-columns: 190px 1fr;
                align-items: center;

                p {
                    color: #727376;
                    font-size: 13px;
                    margin: 8px 0 8px 0;
                    font-weight: 400;
                    width: 190px;
                }

                .MuiLinearProgress-root {
                    width: 180px;
                    height: 10px;
                    background-color: #E6EDF4;
                    border-radius: 0 5px 5px 0;

                    .MuiLinearProgress-bar {
                        background-color: #2BAD63;
                        border-radius: 0 5px 5px 0;
                    }
                }
            }
            .skillNoDataFound{
                min-height: 150px;
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .skill-body {
            display: flex;
            flex-wrap: wrap;
            grid-column-gap: 10px;
            align-items: center;

            p {
                color: #000000;
                background: #EFEFEF;
                border-radius: 5px;
                font-size: 12px;
                font-weight: 400;
                font-family: 'Sora';
                padding: 10px;
                margin: 5px 0px;
            }
        }
    }
}