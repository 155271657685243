.site-tree-search-value {
    color: #f50;
    font-weight: 500;
    text-decoration: underline;
  }

  .roleTreeViewSect .ant-tree-treenode{
    margin-bottom: 5px;
  }
  .roleTreeViewSectScroll .roleTreeViewSect{
    float: left;
    min-width: 50%;
  }
  .roleTreeViewSectScroll .roleTreeViewSect .roleTreeViewSectBlk{
    width: 100%;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .roleTreeViewSectScroll .roleTreeViewSect .roleTreeViewSectBlk > .ant-tree{
    overflow: auto;
    max-height: calc(100vh - 190px);
    padding-top: 10px;
  }
.roleTreeViewSectScroll .roleTreeViewSect .roleTreeViewSectBlk > h5{
    margin: 0px;
    background: #f3f3f7;
    padding: 10px 20px;
    font-weight: 500;
    color: gray;
    text-transform: uppercase;
    font-size: 12px;
  }
  .roleTreeViewSectScroll .roleTreeViewSect .roleTreeViewSectBlk .ant-input-group-wrapper{
    position: relative;
  }
  .roleTreeViewSectScroll .roleTreeViewSect .ant-input-group-wrapper::before{
        content: "";
        width: 17px;
        height: 16px;
        background-image: url(../../assets/icons/search.svg);
        position: absolute;
        z-index: 9;
        left: 10px;
        top: 16px;
    }
    .roleTreeViewSectScroll .roleTreeViewSect .ant-input-group-wrapper input{
      border: none;
      font-size: 16px;
      padding: 10px 10px 10px 40px;
      border-bottom: 1px solid #e4e4e4;
      border-radius: 0px;
    }
    .roleTreeViewSectScroll .roleTreeViewSect .ant-input-group-wrapper input:focus{
      box-shadow: none;
    }    
    .roleTreeViewSectScroll .roleTreeViewSect .ant-input-group-wrapper input::placeholder{
      color: #b2b2b2;
    }
    .roleTreeViewSectScroll .roleTreeViewSect .ant-input-group-wrapper .ant-input-group-addon{
          display: none;
    }
    .roleTabListTransferSect{
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 0px;
      align-items: center;
      padding: 0px 30px 30px 30px;
    }
    .roleTabListTransferSect .listTreeAction{
      width: 150px;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    .roleTabListTransferSect .listTreeAction > span{
      background: black;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }
    .roleTabListTransferSect .listTreeAction > span svg{
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }
    .roleTabListTransferSect .roleTreeViewSectBlk .leftGrid .MuiButtonBase-root.checked,
    .roleTabListTransferSect .roleTreeViewSectBlk .rightGrid .MuiButtonBase-root.checked{
      background: #e4ecff;
      color: #000;
    }
    .roleTabListTransferSect .roleTreeViewSectBlk .leftGrid .MuiButtonBase-root.checked .MuiTypography-root,
    .roleTabListTransferSect .roleTreeViewSectBlk .rightGrid .MuiButtonBase-root.checked .MuiTypography-root{
      font-weight: 600;
    }
    .hideTransferList{
      display: none !important;
    }