.list-wrapper{
  background-color: #fff;
  margin: 20px 0px;
  padding: 1rem;
  border-radius: 10px;  
  .mandateListTableScroll{
    overflow-x: auto;    
    height: calc(100vh - 280px);
    table th .MuiBox-root{
      display: flex;
      align-items: center;

      svg{
        margin-top: -1px;
        cursor: pointer;
      }
    }
    .listViewOpenStatus{
      color: #33a4f6 !important;
      background: #eff6fc;
      padding: 5px 14px;
      border-radius: 4px;
      font-size: 13px !important;
      font-weight: 400 !important;
    }
    .listViewDraftStatus{
      color: #e2950c !important;
      background: #fcf4e5;
      padding: 5px 14px;
      border-radius: 4px;
      font-size: 13px !important;
      font-weight: 400 !important;
    }
    .listViewHoldStatus{
      color: #ecbc64 !important;
      background: #fcf4e5;
      padding: 5px 14px;
      border-radius: 4px;
      font-size: 13px !important;
      font-weight: 400 !important;
    }
    .listViewCloseStatus{
      color: #f56256 !important;
      background: #feeceb;
      padding: 5px 14px;
      border-radius: 4px;
      font-size: 13px !important;
      font-weight: 400 !important;
    }
    .listViewOtherStatus{
      color: #000 !important;
      background: #e5e8eb;
      padding: 5px 14px;
      border-radius: 4px;
      font-size: 13px !important;
      font-weight: 400 !important;
    }
    .listViewRejectStatus{
      color: red !important;
      background: #FFE8E7;
      padding: 5px 14px;
      border-radius: 4px;
      font-size: 13px !important;
      font-weight: 400 !important;
    }
    .listInProgressStatus{
      color: rgb(255, 110, 0) !important;
      background-color: rgb(255, 243, 235);
      padding: 5px 14px;
      border-radius: 4px;
      font-size: 13px !important;
      font-weight: 400 !important;
    }
    .listViewArchiveStatus{
      color: rgb(110, 70, 174) !important;
      background-color: rgb(243, 233, 255);
      padding: 5px 14px;
      border-radius: 4px;
      font-size: 13px !important;
      font-weight: 400 !important;
    }
  }
  .load-more-section-loading-container{
    height: calc(100vh - 270px);
    position: absolute;
    width: calc(100% - 380px);
    background: rgba(255, 255, 255, 0.6117647059);
    z-index: 1;
    margin: 0px;
    > span{
      margin-top: calc(100vh - 500px);
    }
  }

  .head-wrapper{
    background-color: #F3F3F7;
    border-radius: 5px;
  }

  .table-head{
    text-transform: uppercase;
    th{
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #727376;
      border-bottom: 1px solid #F3F3F7 !important;

      &:nth-child(2),&:nth-child(3),&:nth-child(5),&:nth-child(9){
        min-width: 140px;
      }
      &:nth-child(4){
        min-width: 180px;
      }
    }
  }
  .table-body{
    border: 1px solid #E5E8EB;
    tr{
      cursor: pointer;      
    }

    .title-card{
      display: flex;
      gap: 5px;
      align-items: center;
      .title{
        display: flex;
        gap: 10px;
        align-items: center;
        p{
          font-size: 13px;
          font-weight: 400;
          color: #000;
          text-overflow: ellipsis !important;
          overflow: hidden;
          white-space: nowrap; 
          max-width: 200px;
        }
      }
      .mandate-id{
        font-size: 12px;
        font-weight: 400;
        color: #727376;
      }

      .image-container{
        position: relative;
        p{
          background: #f1813f;
          color: #fff;
          width: 30px;
          height: 30px;
          border-radius: 30px;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          margin: 0px;
          padding-top: 2px;
          text-transform: uppercase;
        }
      }
      .mandate-image {
        cursor: pointer;
      }
      
      .checkbox {
      display: none;
      }
      
      // .image-container:hover .mandate-image {
      //   display:none;
      // }
      
      // .image-container:hover .checkbox {
      //   display:inline-flex;
      
      // }
    }

    .primary-text{
      font-size: 12px; 
      font-weight: 500; 
      color: #000;
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 140px;
    }
    .secondary-text{
      font-size: 12px; 
      font-weight: 400; 
      color: #4E4E4E;
    }

    .avatar-container{
      display: flex;
      align-items: center;
      gap: 10px;
      min-width: 200px;

      .group-avatar{
        div{
          width: 30px;
          height: 30px;
          font-size: 16px;
        }
      }
    }
  }
}
.avatar-list-items .group-avatar .MuiAvatar-circular:hover {
  margin-top: -4px;
}