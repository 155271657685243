.progress-container{
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999999;

  .MuiLinearProgress-indeterminate{
    z-index: 9999999;
    background-color: #f7c39c !important;

    .MuiLinearProgress-bar1Indeterminate{
      background-color: #ff6e00 !important;
    }
    .MuiLinearProgress-bar2Indeterminate{
      background-color: #ff6e00 !important;
    }
  }

  .MuiLinearProgress-determinate{
    z-index: 9999999;
    background-color: #f7c39c !important;
    .MuiLinearProgress-bar1Determinate{
      background-color: #ff6e00 !important;
    }
  }
}