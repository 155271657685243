.forgot-password-form-container-grid {
    max-width: 70rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32), 0px 4px 8px rgba(91, 104, 113, 0.24);
    border-radius: 10px;
    /* height: 33rem; */
}
.resetPass{
    background: #FEE4E4;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 5px;
    width: max-content;
}