.drawer-header {
  display: flex;
  align-items: center;
  align-content: space-between;
  padding-left: 20px !important;
  padding-top: 12px !important;
}
.drawer-header img{
  cursor: pointer;
}
.list {
  height: 100%;
}
body .xpheno-rightNavBar,body .xpheno-rightNavBar .MuiPaper-root{
  margin-top: 0px;
  background-color: #f9fafc;
  border: none;
  box-shadow: 0px 0px 5px #e2e2e2;
}
body .xpheno-rightNavBar .MuiPaper-root nav > div{
  margin-bottom: 15px;
}
/* body .xpheno-rightNavBar .MuiPaper-root nav{
  margin-top: 20px;
} */
.rightPanelLogo{
  text-align: center;
  margin: 15px 0px 0px 0px;  
}
.rightPanelLogo img{
  height: 35px; 
  }
  .rightPanelLogo.expand img{
    width: 24px;
  }