.MuiPopover-root {
    z-index: 999999 !important;
}

.candidate-listing-wrapper {

    .candidate-section-box {
        background-color: #FFF;
        border-radius: .625rem;
    }

    .candidate-listing-container {
        display: flex;
        height: calc(100vh - 132px);
        overflow-y: auto;
        padding-right: 8px;
        .candidate-listing-left-section {
            flex-grow: 1;
            width: calc(100% - 320px);
            // overflow: hidden;
        }
        .candidate-listing-right-section {
            .candidate-sidebar-filter-form {
                background-color: #fff;
                min-width: 14rem;
                max-width: 14rem;
                // position: absolute;
                // display: none;
                // right: 8.9rem;
            }
        }

    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    
    }
    ::-webkit-scrollbar-thumb {
        background: #DBDBDB !important;
        border-radius: 10px;
    }

    .MuiSlider-valueLabelOpen::before{
        top: -8px;
    }
    .MuiSlider-valueLabelOpen{
        transform: translateY(100%) scale(1) !important;
        top: 0px !important;
        background-color: #000000;
    }

    .sticky-filter{
        position: sticky;
        top: 0;
    }

    @import 'CandidateHeaderSearch';
    @import 'CandidateSearchResultSection';
    @import 'CandidateSidebarSectionBox';
}
.responsive-list-menu{
    // max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
    white-space: unset !important;
    font-size: 12px !important;
}
.cvUploadProfileSect{
    display: flex;
    justify-content: space-between;
    // padding-right: 90px;
    margin-bottom: 15px;
    align-items: center;

    .breadcrumb {
        margin: 0px !important;
    }
    .download-btn button{
        max-width: 40px;
        min-width: 40px;
        svg{
            path{
                stroke: #fff;
            }
        }
    }    
}
.candidateSearchTopSect{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
}
.candiateCreatePopup{
    ul.MuiList-root{
        li{
            font-size: 14px;
            gap: 5px;
            svg{
                font-size: 20px;
            }
            &:nth-child(2){
                svg{
                    margin-right: 2px;
                    margin-left: 4px;
                } 
            }
        }
    }
}