.view-box-style{
    border-radius: 10px !important;
    padding:30px 10px 10px 10px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    #scrollableContent {
        height: 100%; /* 100% height to fill the container */
        overflow: scroll; /* Enable scrolling within the content */
        padding-left: 10px;
      }
  
      /* Custom scrollbar style */
      #scrollableContent::-webkit-scrollbar {
        width: 5px; /* Set the width of the scrollbar */
      }
  
      #scrollableContent::-webkit-scrollbar-thumb {
        background-color: #DBDBDB; /* Scrollbar thumb color */
      }
    .legend-box{
        // width: 50%;
        // min-width: 500px;
        display: flex;
        height:40px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .legend-box-sub{
          // width: 50%;
          // min-width: 500px;
          display: flex;
          height:40px;
          flex-direction: row;
          align-items: center;
          justify-content: left;
        }

        .legend-group {
            display: flex;
            flex-direction: row; 
            align-items: center;

        }

        .legend-label{
            color: #000;
            font-family: Sora;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        
    }
}
.workMagBulkAllBtn{
  margin-right: 30px;
  p{
    font-size: 14px;
    font-weight: 500;
  }
}
.bulkAllocationBlk{
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-right: 20px;

  .mandate-common-dropdown{
    width: 300px;
  }
}
.bulkAlloactionRecuriterTable{
  margin-top: 15px;
  .mandate-table-head .mandate-header-row2{
    display: table-row;
    th{
      min-width: 200px;
    }
  }
}
.allocationViewTopCardLeft{
  display: flex;
  gap: 30px;
  > h1{
    font-size: 16px;
    font-weight: 600;
  }
  .legend-group{
    display: flex;
    gap: 7px;
    span{
      width: 12px;
      height: 12px;
      border-radius: 2px;
    }
  }
}