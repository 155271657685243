.remark-model {


  .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }

  .model-container {
    background-color: #fff;
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;

    .header-wrapper {
      display: flex;
      justify-content: space-between;

      .heading {
        color: #000;
        font-family: Sora;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .body-wrapper {
      .field-container {
        padding-top: 20px;

        .rowContainer {
          display: flex;
          gap: 170px;

          .row-text {
            color: #000;
            font-family: Sora;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: nowrap;

          }
        }
        .status {
          color: #002882;
          border-radius: 5px;
          border: 1px solid #E5E5E5;
          background: #ECEFF1;
          padding: 5px;
          min-width: 110px;
          font-family: Sora;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          text-align: center;
          max-width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
      }

        .label-text {
          color: #727376;
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .MuiTextField-root {
          width: 100%;

          .MuiInputBase-multiline {
            border-radius: 10px;
          }
        }

        .mandate-location-tag {
          border-radius: 8px;
          background: #F0F6FB;
          width: fit-content;
          padding: 10px 15px;
          display: flex;
          flex-direction: column;

          .loc-text {
            color: #002882;
            font-size: 14px;
            font-weight: 400;
          }

          .loc-id {
            color: #727376;
            font-size: 12px;
            font-weight: 400;
          }
        }

        .MuiInputBase-formControl {
          border-radius: 10px;
        }

        .man-option-tag {
          width: 270px;
        }

        .Mui-focused {
          fieldset {
            border: 1px solid rgba(0, 0, 0, 0.23) !important;
          }

        }

        .status-tag {
          border-radius: 5px;
          background: #E5E9F3;
          color: #002882;
          font-size: 14px;
          font-weight: 400;
          padding: 4px 10px;
        }

        .remark-text {
          color: #000;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

ul[aria-labelledby="status-select-label"] {
  max-height: 200px;

  .status-tag {
    border-radius: 5px;
    background: #E5E9F3;
    color: #002882;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 10px;
  }
}