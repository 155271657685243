
.call-container{
  width: 400px;
  height: 450px;
  border-top: 3px solid #FF6E00; 
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px #cbcbcb;

  .header-tab{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #E5E8EB;
    align-items: center;

    .header-icons{
      display: flex; 
      gap: 20px;
      align-items: center;
      svg path {
        stroke: #B3B5B7 !important;
      }

      .min-icon{
        display: flex;
        align-items: center;
        padding: 8px 4px;
      }
    }
  }
  .primary-text{
    font-weight: 600;
    font-size: 1rem;
    color: #000;
  }

  .form-container{
    padding: 1rem;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid #E5E8EB;

    .connected-call-wrapper{
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      align-items: center;

      .candidate-name{
        font-weight: 500;
        font-size: 18px;
        color: #000;
      }
      .candidate-number{
        font-weight: 400;
        font-size: 14px;
        color: #727376;
      }

      .record-container{
        padding-top: 25px; 
        display: flex; 
        align-items: center; 
        gap: 6px;

        .duration{
          font-weight: 400;
          font-size: 14px;
          color: #727376;
        }
      }
    }
  }
  
  .MuiInputBase-adornedStart{
    align-items: baseline !important;
    .MuiTypography-root{
      color: #000 !important;
    }
  }
  .MuiOutlinedInput-root{
    border-radius: 10px !important;
  }

  .footer{
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }
  .disabled-btn{
    opacity: 0.5;
    color: #fff !important;
  }

  .adornment{
    margin-right: 20px;
  }
  .adornment-hide{
    display: none;
  }
}

.popup-container{
  .MuiPopover-paper{
    left: calc(100% - 460px) !important;
    position: relative;
    z-index: 1;
    overflow: visible;
    background: transparent;
    box-shadow: none;
  }
}

.minimize-container{
  width: 245px;
  height: 265px;
  background: #fff;
    border: 1px solid grey;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

    &::before{
      content: "";
      width: 100%;
      height: 4px;
      background: #FF6E00;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  .header-tab{
    display: flex;
    justify-content: space-between;

  }
  .primary-text{
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
  .connected-call-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .record-container{
      padding-top: 10px;
      display: flex;
      gap: 6px;
      align-items: center;

      .duration{
        font-weight: 400;
        font-size: 14px;
        color: #000;
      }
    }
  }
  .footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .hangup-btn{
      background-color: #E51E1E;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}