.cat-main {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    align-items: center;
    gap: 12px;
    color: #727376;
    font-size: 13px;
    justify-content: flex-start;
    border-bottom:2px solid #E5E8EB;    
    flex-wrap: wrap;
}

.eachcards {
    cursor: pointer;
    padding: 0 12px 0 12px;
}

.eachcards-active {
    border-bottom:2px solid #002882;
    color: #002882;
    padding: 0 12px 0 12px;
    cursor: pointer;
}