.custom-date-filter-popup {
    /* padding: 20px;
    border-radius: 8px; */
  }
  
  .custom-date-filter-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff !important;
  }
  
  .custom-date-filter-container .custom-date-filter-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .custom-date-filter-container .custom-date-filter-header .demo-date-filtering-inputs > p.MuiTypography-root{
    font-size: 12px;
  }
  .custom-date-filter-container .custom-date-filter-header .demo-date-filtering-inputs > .date-range-buttons
  .date-type-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .date-type-options li {
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .custom-date-inputs {
    display: flex;
    gap: 10px;
  }
  
  .date-input {
    flex: 1;
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
  }
  
  .custom-date-filter-datepicker {
    display: flex;
    justify-content: center;
  }
  
  .custom-date-filter-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .apply-button {
    background-color: #007bff;
    color: #fff;
  }
  
  .cancel-button {
    background-color: #ccc;
    color: #000;
  }


.date-range-buttons {
  display: flex;
  gap: 5px;
  row-gap: 8px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 0px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.date-range-button {
  border-radius: 40px; /* Reduced border-radius */
  text-transform: capitalize;
  outline: none;
  border: 1px solid #CFD1D2;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px; /* Reduced font size */
  font-weight: 500;
  padding: 3px 8px; /* Added padding for better appearance */
  margin-bottom: 5px;
}

.date-range-button.date-range-buttonnormal {
  border-radius: 40px;
  text-transform: capitalize;
  outline: none;
  border: 1px solid #CFD1D2;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 8px; /* Matching padding */
}

.date-range-button.active,.date-range-button.active:hover {
  background-color: #000080;
  text-transform: capitalize;
  color: #fff;
  border: 1px solid #000080;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 500;
  padding: 3px 8px; /* Matching padding */
}



.date-range-fields{
    display: flex;
    justify-content:space-between;
    margin-bottom: 0px;
    column-gap: 30px;
    font-size: 12px;
}

.mbsc-ios.mbsc-popup-body{
    border:1px solid #fff !important;
    background-color: #fff !important;
    border-radius: 10px !important;
}
.DateRangeField .mbsc-page .MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-root{
    position: relative;
    right: 0px;
}
.DateRangeField .mbsc-page .MuiFormControl-root .MuiInputBase-root input {
    font-size: 12px;
    padding-left:0px;
}
.DateRangeField .mbsc-page .MuiFormControl-root .MuiInputBase-root input::placeholder{
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}


.daterangecontrolui{
  border-radius: 10px !important;
  border: 0.5px solid #ccc;
  background-color: #fff !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.daterangecontrolui .mbsc-calendar-scroll-wrapper>div>div{
  /* background-color: red !important; */
  display: flex !important;
  column-gap: 30px !important;
}

.daterangecontrolui .mbsc-datepicker .mbsc-calendar-slide{
  /* background-color: red !important; */
  /* display: flex !important; */
  /* padding-inline: 5px !important; */
  /* column-gap: 10px !important; */
}

.daterangecontrolui .mbsc-ios.mbsc-datepicker .mbsc-calendar, .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell, .mbsc-ios.mbsc-datepicker .mbsc-calendar-slide {
  background: #fff;
  border-radius: 10px;
}
.daterangecontrolui .mbsc-datepicker{
  border-color: #D9D9D9 !important;
  border: 1px;
}
.daterangecontrolui .mbsc-datepicker .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab .mbsc-calendar .mbsc-calendar-body-inner .mbsc-calendar-slide .mbsc-calendar-cell-inner div{
  font-size: 12px !important;
  font-weight: 500;
  color: #000;
}

.daterangecontrolui .mbsc-ios.mbsc-range-hover:before{
  height: 24px !important;
}

.daterangecontrolui .mbsc-ios.mbsc-range-day:after {
  background-color: #cbe2fb !important;
  height: 22px;
}

.daterangecontrolui .mbsc-calendar-month{
  color: #000;
  font-weight: 600;
}
.daterangecontrolui .mbsc-calendar-year{
  color: #000;
  font-weight: 600;
}


.daterangecontrolui .mbsc-ios.mbsc-calendar-week-day {
  font-size: .625em;
  height: 1.7em;
  line-height: 1.7em;
  font-weight: 700;
  color: grey;
}
.datePickerPopup .mbsc-popup-anchored{
  border-radius: 10px;
  box-shadow: none !important;
  border: 1px solid #D9D9D9 !important;
  overflow: hidden;
}
.datePickerPopup .mbsc-popup-anchored .mbsc-popup-body-anchored .mbsc-popup-content{
  padding: 15px 0px;
}
.datePickerPopup .mbsc-popup-anchored .mbsc-popup-body-anchored .mbsc-popup-content .daterangecontrolui .mbsc-datepicker .mbsc-datepicker-tab-wrapper .mbsc-calendar-header .mbsc-calendar-controls > button > span{
  background: #F1F6FE;
  border-radius: 20px;
  padding: 4px;
}
.datePickerPopup .mbsc-popup-anchored .mbsc-popup-body-anchored .mbsc-popup-content .daterangecontrolui .mbsc-datepicker .mbsc-datepicker-tab-wrapper .mbsc-calendar-header .mbsc-calendar-controls > .mbsc-calendar-title-wrapper button{
  font-size: 14px;
}
.daterangecontrolui .mbsc-datepicker .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab .mbsc-calendar .mbsc-calendar-body-inner .mbsc-calendar-slide .mbsc-calendar-table .mbsc-calendar-row .mbsc-ios.mbsc-range-day-start.mbsc-ltr:after{
  margin-left: -11px !important;
}
.daterangecontrolui .mbsc-datepicker .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab .mbsc-calendar .mbsc-calendar-body-inner .mbsc-calendar-slide .mbsc-calendar-table .mbsc-calendar-row .mbsc-ios.mbsc-range-day-end.mbsc-ltr:after{
  margin-right: -11px !important;
}
.daterangecontrolui .mbsc-datepicker .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab .mbsc-calendar .mbsc-calendar-body-inner .mbsc-calendar-slide .mbsc-calendar-table .mbsc-calendar-row .mbsc-calendar-cell.mbsc-selected .mbsc-calendar-cell-inner{
  border-radius: 20px;
}
.daterangecontrolui .mbsc-datepicker .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab .mbsc-calendar .mbsc-calendar-body-inner .mbsc-calendar-slide .mbsc-calendar-table .mbsc-calendar-row .mbsc-calendar-cell.mbsc-ranger-hover .mbsc-calendar-cell-inner{
  border-radius: 20px !important;
}

.daterangecontrolui .mbsc-windows.mbsc-selected.mbsc-range-day .mbsc-calendar-cell-text{
  color: #fff !important;
  font-size: 8px !important;
  font-weight: 600 !important;
}

.daterangecontrolui .mbsc-ios.mbsc-datepicker .mbsc-selected .mbsc-calendar-day-text{
  color: #fff !important;
  font-size: 8px !important;
  font-weight: 600 !important;
}

.DateRangeField .mbsc-page .MuiFormControl-root .MuiButtonBase-root{
  position: absolute;
  left: -12px;
}

.DateRangeField .mbsc-page .MuiFormControl-root .MuiInputBase-root input {
  font-size: 12px;
  padding-left: 18px;
}
.custom-date-filter-container .date-range-fields .MuiInputBase-root input[disabled]{
  -webkit-text-fill-color: #000;
  font-weight: 500;
}
.singleDatePicker .mbsc-popup-content{
  padding: 1px 0px !important;
}
.singleDatePicker .mbsc-popup-content .daterangecontrolui{
  border: none;
}