.comments-main {
    .comments-insert-part {
        .comment-box {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;

            .commentand-attachwrap {
                display: flex;
                flex-direction: column;
                border: 1px solid #D9D9D9;
                min-width: calc(100% - 200px);
                border-radius: 10px;

                div {
                    .Mui-focused {
                        border-color: #757575;
                        border: none;
                    }
                }

                .comment-text {
                    border: none;
                    border-radius: 8px;
                    box-sizing: border-box;
                    // padding: 10px;
                    max-width: inherit;
                    width: 100%;

                    .MuiInputBase-colorPrimary {
                        fieldset {
                            border: none;
                        }
                    }
                }

                .filename-spectifed {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin: 10px;

                    span {
                        border-bottom: #000000;
                        color: #000000;
                        text-decoration: underline;
                    }

                    .candidate-sidebar-close-icon {
                        cursor: pointer;
                    }
                }
                .error-msg{
                    color: #FF0000;
                    margin: 10px;
                }
            }

            .commentand-attachwrap:hover {
                border: 1px solid #0E94F4 !important;
            }   
            .commentand-attachwrap:focus-within {
                border: 1px solid #0E94F4 !important;
            }

            .attach-holder {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 10px;
                background-color: #E5E8EB;
                cursor: pointer;
            }

            .post-button {
                color: #fff;
                background-color: #002882;
                border-radius: 8px;
                width: 82px;
                font-weight: 500;
                height: 40px;
                cursor: pointer;
            }

            .button-disable {
                opacity: 0.5;
            }
        }

    }

    .comment-list {
        display: flex;
        align-items: center;
        gap: 10px;

        .comment {
            font-family: 'Sora';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #000000;

            .comment-name-date {
                display: flex;
                align-items: center;
                gap: 10px;

                p {
                    color: #0f6cbd;
                }

                .comment-date-only {
                    color: #727376;
                }
            }

            .msg {
                margin-top: 0px;
                word-break: break-word;
            }
            .show-attach{
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    .icon-file-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
}