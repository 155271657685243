.numberFieldSect{
    > label{
        position: absolute;
        top: -10px;
        font-size: 12px;
        background: #fff;
        padding: 0px 5px;
        left: 8px;
        span{
            color: red;
        }
    }
    .numberFieldSectIncDec{
        padding: 0px;
        height: 56px;
        box-shadow: none;
        border-color: rgba(0, 0, 0, 0.23);
        button{
            width: 45px;
            height: 28px;
            margin-left: -24px;
            background-color: #d9d9d9;
            &:hover{
                svg{
                    color: #000;
                }
            }
            svg{
                width: 18px;
            }
        }
        input{
            width: calc(100% - 17px);
        }
        &:hover{
            border-color: #DAE2ED;
        }
    }
    .numberFieldSectIncDec.base--focused{
        border-color: #DAE2ED;
        box-shadow: none;
    }
}
.numberFieldSect.hideNumberArrow{
    .numberFieldSectIncDec{
        padding: 0px;
        height: 48px;
        box-shadow: none;
        border-color: rgba(0, 0, 0, 0.23);
        button{
            display: none;
        }
    }
}
.cardHeadingStrip{
    font-size: 16px;
    font-weight: 600;
    position: relative;
    span{
        background: white;
        z-index: 9;
        position: relative;
        padding-right: 10px;
    }
    &::before{
        content: "";
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.23);
        position: absolute;
        top: 12px;
    }
}
.candidateSkillsSect{
    margin-top: 30px;
    > .MuiFormControl-root{
        .MuiAutocomplete-root{
            .MuiFormControl-root{
                .MuiInputBase-root{
                    background: transparent;
                    border: 1px solid rgba(0, 0, 0, 0.23);
                    padding: 15px;
                    &::after,&::before{
                        display: none;
                    }
                    .MuiButtonBase-root{
                        border-radius: 5px;
                        background: #efefef;
                        border: none;
                        span{
                            font-weight: 500;
                        }
                    }
                    .MuiAutocomplete-endAdornment{
                        display: none;
                    }
                } 
                > label[data-shrink=false]{
                    transform: translate(16px, 24px) scale(1);
                }
                > label[data-shrink=true]{
                    transform: translate(2px, -30px) scale(0.75);
                }
            }
        }        
    }    
}
.candidateAttach{
    border: 1px dashed #002882;
    padding: 15px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    float: left;
    min-width: 300px;
    .candidateAttachFileSect{
        display: flex;
        align-items: flex-start;
        gap: 10px;
        padding-left: 15px;
        padding-right: 30px;
        margin-bottom: 10px;
        min-height: 50px;
        position: relative;
        .closeAttach{
            color: red;
            font-size: 18px;
            position: absolute;
            right: 8px;
            cursor: pointer;
        }
        p{
            margin: 0px;
            font-size: 14px;
            text-decoration: underline;
        }
    }
    .candidateAttachActionSect{
        display: flex;
        align-items: center;
        gap: 10px;
        border-top: 1px solid rgba(0, 0, 0, 0.23);
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        justify-content: center;
        p{
            margin: 0px;
            font-size: 14px;
        }
    }
}
.cvAttachLink{
    font-size: 14px;
    text-decoration: underline;
    color: #002882;
}
.disableEndDate .endDataField.datePicker,.disableEndDate .yearOfPass{
    pointer-events: none;
    background: #f2f2f2;
    border-radius: 10px;
}