.jobOrderTable{
	height: calc(100vh - 250px);
	table{
		thead{
			tr{
				th{
					&:nth-child(1){
						width: 100px;
					}
					> div{
						height: 15px;
						svg{
							top: -4px !important;
						}
					}
				}
			}
		}
		tbody{
			tr{
				td{
					&:nth-child(1){
						span{
							width: 150px;
							display: block !important;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
					}
				}
			}
		}
	}
	.job-order-container {
		position: absolute;
		background-color: white;
		width: 350px;
		height: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 10px;
		padding: 24px;
		max-height: calc(100vh - 200px);
	  
		.job-heading {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  width: 100%;
		  padding-bottom: 20px;
	  
		  .heading-text {
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
				  color: #000;
		  }
		}
	  
		.location-wrap {
			  padding-bottom: 20px;
		  .loc-container {
			padding: 10px 15px;
			width: fit-content;
			border-radius: 8px;
			background-color: #f0f6fb;
	  
			.loc-text {
			  color: #002882;
			  text-align: center;
			  font-size: 14px;
			  font-weight: 400;
			  line-height: 24px;
			}
		  }
		}
		  .jo-status-wrap{
			  height: auto;
			  max-height: 390px;
			  overflow-y: auto;
			  padding: 5px 0;
	  
			  .jostatus-wrap{
				  display: flex;
				  flex-direction: column;
				  gap: 5px;
				  padding-bottom: 20px;
	  
				  .status-code{
					  color: #000;
					  font-size: 14px;
					  font-weight: 400;
					  line-height: normal;
				  }
				  .status-name{
					  color: #0E94F4;
					  font-size: 14px;
					  font-weight: 400;
					  line-height: normal;
					  padding: 4px 10px;
					  background-color: #EFF6FC;
					  width: fit-content;
					  border-radius: 5px;
				  }
				  .joactive-tag{
					  color: #0E94F4;
					  background-color: #EFF6FC;
				  }
				  .joprogress-tag{
					  color: #FF6E00;
					  background-color: #FFF3EB;
				  }
				  .josuccess-tag{
					  color: #F34334;
					  background-color: #FFE8E7;
				  }
			  }
		  }
	  }
	  
}