.box-style {
  border-radius: 10px !important;
  padding: 0px;
  margin-top: 16px;
  box-shadow: none !important;
}

.mandate-list {
 /* padding: 10px; */
  /* border-right: 1px solid #E5E8EB; */
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 8px; */
  color: #000000 !important;
  font-size: 14px !important;
}

.mandate-list .list-title {
  cursor: pointer;
  padding: 15px;
  border-right: 1px solid #E5E8EB;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #000000 !important;
  font-size: 14px !important;
  line-height: 24px;
  font-weight: 500;
  position: relative;
  .topline{
    position: absolute;
    top: 0;
  }
}


.mandate-list .mandate-list-title {
  color: #002882;
  cursor: pointer;
  padding: 15px;
  border-right: 1px solid #E5E8EB;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px !important;
  line-height: 24px;
  font-weight: 500;
  position: relative;

   svg{
    path {
      fill: #002882;
      stroke: #002882;
    }
  }

  .topline{
    background-color: #002882;
    width: calc(100% - 6px);
    height: 3px;
    left: 3px;
    position: absolute;
    top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

 .mandate-list .approval-list-title {
  color: #002882;
  cursor: pointer;
  padding: 15px;
  border-right: 1px solid #E5E8EB;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px !important;
  line-height: 24px;
  font-weight: 500;
  position: relative;
   svg {
    path {
      // fill: #002882;
      stroke: #002882;
    }
  }
  .topline{
    background-color: #002882;
    width: calc(100% - 6px);
    height: 3px;
    left: 3px;
    position: absolute;
    top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
} 

.mandate-list .mandate-count {
  margin-left: 0.2rem;
  color: #002882 !important;
  font-size: 14px !important;
  line-height: 24px;
}

.add-new-icon {
  margin-right: 10px;
}

.filter-box-style {
  border-radius: 10px !important;
  padding: 10px 20px;
  margin-top: 16px;
}

.masonry-label {
  border-radius: 10px !important;
  padding: 10px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.filter-inputs {
  padding: 15px 0px;
}

.filter-label {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.reset-label {
  color: #002882;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 2px;
  margin-left: 2px;
}

.search-btn {
  background-color: #000000;
  color: white;
}

.filter-input-field {
  border-radius: 10px !important;
  width: 283px;
}

.client-input-field {
  border-radius: 10px;
  width: 281px;
}

.title-label {
  margin-top: 1px;
  margin-left: 4px;
}

.tab-divider {
  margin-left: 5px !important;
  color: #d9d9d9;
}

.search-button-filter {
  color: #fff !important;
}

.filter-search-btn {
  color: #fff;
  margin-left: 1rem;
}

.search-button-filter-box {
  margin-left: 1rem;
}

.class-new-button {
  display: none;
  /* visibility: hidden; */
}

.date-picker-class .MuiFormLabel-root.Mui-error {
  color: #727376;
  /* color:#FF6E00; */
}

.date-picker-class .MuiOutlinedInput-root .Mui-error .MuiOutlinedInput-notchedOutlin {
  border-color: #727376;
}

.date-picker-class.MuiFormControl-root.MuiTextField-root .fieldset {
  border-color: #727376;
  width: 200px !important;
}

.DatePicker-text .Mui-error.fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #727376;
}

@media (max-width: 768px) {
  .filter-parent-box-mob {
    flex-wrap: wrap;
  }
}

@media (max-width: 576px) {
  .filter-parent-box-mob {
    flex-wrap: wrap;
  }
}

@media (max-width: 330px) {
  .filter-action-btns {
    flex-wrap: wrap;
  }

  .filter-action-divider {
    display: none;
  }
}

.auto-complete-input-container .MuiInputBase-colorPrimary {
  width: 100%;
  padding-right: 0 !important;
}

.auto-complete-input-container .MuiTextField-root {
  max-width: 280px;
}

.filterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
}

.customefilterGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)) !important;
  .MuiAutocomplete-root label.MuiInputLabel-shrink{
    transform: translate(14px, -9px) scale(0.75) !important;
    font-size: 1rem !important;
  }
}
.mandateFilterToggleBtn button{
  border: 2px solid #dcddde;
}
.mandateFilterToggleBtn button.Mui-selected{
  border: none;
}
.toggle-btn svg {
  fill: #000 !important;
}

.toggle-btn .Mui-selected {
  background-color: #FF6E00 !important;
  pointer-events: none;
}

.toggle-btn .Mui-selected svg {
  fill: #fff !important;
}

.filter-box-style{
  .filter-btn-container{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #CFD1D2;
    padding-top: 10px;

    .search-bth{
      padding: 8px 20px;
    }

    .btn-class{
      font-size: 14px;
      font-weight: 400;
      p{
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .datePicker-text{
    width: calc(100% - 20px);
    .MuiFormLabel-root{
      color: #B3B5B7;
      font-size: 14px;
    }
    .MuiInputBase-adornedEnd{
      font-size: 14px !important;
      height: 40px;
    }
  }
  .clientName-filter-wrap{
    .MuiAutocomplete-inputRoot{
      font-size: 14px !important;
    }
  }
}
.select-filter-mandate  .MuiInputLabel-root{
  color: #B3B5B7 !important;
  font-size: 14px !important;
}
.select-filter-mandate label:not(.Mui-focused):not(.MuiFormLabel-filled){
  transform: translate(14px, 10px) scale(1);
}
.select-filter-mandate fieldset{
  border-radius: 10px;
}
.select-filter-mandate label + .MuiInputBase-root:hover fieldset{
  border-color: rgba(0, 0, 0, 0.23);
}
.select-filter-mandate label.Mui-focused + .MuiInputBase-root fieldset{
  border-color: rgba(0, 0, 0, 0.23);
  border-width: 1px;
}
.mandateFilterToggleBtn .Mui-disabled{
  opacity: 0.4;
  pointer-events: none;
  cursor: none;
}
.filter-action-btns .MuiButtonBase-root.Mui-disabled{
  background-color: #002882;
  opacity: 0.5;
  color: #fff;
}

// user role based filter style
.user-role-based-container{
      border-top: 1px solid #CFD1D2;
      padding: 10px 0px 20px 0px;
  .user-role-based-hierarchy{
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    .user-role-based-hierarchy-row{
      display: flex;
      width: 100%;
      gap: 15px;
      align-items: center;
      .user-role-basedFields{
        display: flex;
        width: calc(100% - 0px);   
        > .MuiFormControl-root:nth-child(1){
          fieldset{
            border:1px solid #CFD1D2;
            border-radius: 10px 0px 0px 10px;
          }
        } 
        > .MuiFormControl-root:nth-child(2){
          fieldset{
            border:1px solid #CFD1D2;
            border-radius: 0px 10px 10px 0px;
            border-left: 0px;
          }
        }
        > .MuiFormControl-root.cancelBtnAdded{
          fieldset{
            border-radius: 0px !important;
            // border-right: 0px;
          }
        }
        .MuiFormControl-root{
          margin: 0px;
          width: 100%;
          label:not(.Mui-focused):not(.MuiFormLabel-filled){
            transform: translate(14px, 13px) scale(1);
          }
          label.Mui-focused,label.MuiFormLabel-filled{
            background: #fff;
            padding: 0px 5px;
            transform: translate(8px, -9px) scale(0.75);
          }
          .MuiInputBase-root{
            .MuiSelect-select{
              padding: 13px 40px 13px 10px !important;
            }
            input {
              padding: 7.5px 4px 7.5px 5px;
            }
            fieldset{
              // border: 0px;              
            }
          }
          .MuiInputBase-root.Mui-focused{
            fieldset{
              border-color: #1976d2;
              border-width: 2px;
            }
          }
        }   
        > span{
          width: 1px;
          background: #CFD1D2;
        }   
        .userRoleCancelBtn{
          background-color: rgb(255,230,230);
          padding: 10px 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px 10px 10px 0px;
          min-width: auto;
          cursor: pointer;
          svg{
            width: 15px;
            height: 13px;
          }
        }
      }  
      .userRoleFilterAction {
        display: flex;
        gap: 10px;
        .disablePlus{
          opacity: 0.5;
          pointer-events: none;
        }        
        .userRoleAddBtn{
          background: #002882;
          width: 50px;
          height: 50px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg{
            path{
              fill: #fff
            }
          }
        }
      }    
    }
    .dashboard-user-filter{
      .user-role-basedFields{
        .MuiFormControl-root{
          label:not(.Mui-focused):not(.MuiFormLabel-filled){
            transform: translate(14px, 9px) scale(1);
            font-size: 14px;
          }
          label.Mui-focused,label.MuiFormLabel-filled{
            background: #fff;
            padding: 0px 5px;
            transform: translate(8px, -9px) scale(0.75);
          }
          .MuiInputBase-root{
            .MuiSelect-select{
              padding: 8px 40px 7px 10px !important;
            }
          }
        }
      }
      .userRoleFilterAction {     
        .userRoleAddBtn{
          width: 38px;
          height: 38px;
          min-width: auto;
        }
      }
    }
    .cancelState.user-role-based-hierarchy-row{
      .user-role-basedFields{
        .MuiFormControl-root{
          width: calc((100% - 38px) / 2);
        }
      }
    }
    .addState.user-role-based-hierarchy-row{
      .user-role-basedFields{
        width: calc(100% - 53px);
        .MuiFormControl-root{
          width: 50%;
        }
      }
    }
  }
}