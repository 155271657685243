.bulkAllocationAssignSect{
    display: flex;

    .MuiAutocomplete-root{
        .MuiFormControl-root{
            .MuiInputBase-root.Mui-focused{
                fieldset{
                    border-color: rgba(0, 0, 0, 0.23);
                    border-width: 1px;
                }
            }
            .MuiInputBase-root:hover{
                fieldset{
                    border-color: rgba(0, 0, 0, 0.23);
                }
            }
            .MuiInputBase-root{
                padding-right: 20px;
                .MuiChip-root{
                    border-radius: 5px;
                    font-size: 12px;
                }
            }
            fieldset{
                border-radius: 10px;
            }
        }
    }
    .MuiFormControl-root.MuiTextField-root, .MuiFormControl-root{
        fieldset{
            border-radius: 10px;
        }
        .MuiInputBase-root:hover{
            fieldset{
                border-color: rgba(0, 0, 0, 0.23);
            }
        }
        .MuiInputBase-root input{
            font-size: 14px;
        }
        // svg{
        //     width: 16px;
        //     height: 16px;
        // }
    }
    .infoSection{
        font-size: 12px;
        display: flex;
        align-items: center;
        margin: 0px;
        gap:5px;
        margin-top: 5px;
        svg{
            width: 16px;
        }
    }
    .MuiSelect-select{
        font-size: 14px;
    }
}
.bulkAllocationListBlk{
    margin-top: 30px;
    .bulkAllocationListSect{
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            font-size: 14px;
            font-weight: 500;
            margin: 0px;
        }
        .bulkAllocationListAction{
            display: flex;
            gap: 10px;
        }
    }
}
.bulkAllocationTableSect {
    .MuiTableContainer-root{
        margin-top: 30px;
        box-shadow: none;
        border: 1px solid #E5E8EB;
        border-radius: 5px;
        min-height: 330px;
        position: relative;

        table {
            thead{
                tr{
                    &:nth-child(1){
                        background: #F3F3F7;
                    }
                    &:nth-child(2){
                        th .MuiInputBase-root{
                            // width: 200px;
                            input {
                                padding: 7px 10px 7px 0;
                                font-size: 13px;
                            }
                            fieldset {
                                border-radius: 6px;
                                border-color: #CFD1D2;
                            }
                        }
                        th{
                            padding: 10px 0px;
                            border-bottom: 1px solid #CFD1D2;
                        }
                    }
                    border: none;
                    th{
                        padding: 5px 0px;
                        border: none;
                        p{
                            color: #727376;
                            font-weight: 400;
                            font-size: 12px;
                            display: flex;
                            padding: 0px;
                            margin: 0px;
                            align-items: center;
                        }          
                        &:nth-child(1){
                            .MuiCheckbox-root{
                                padding-top: 0px;
                                padding-bottom: 0px;
                            }
                            // svg{
                            //     color: #B3B5B7;
                            // }
                        }
                        &:nth-child(5){
                            p{
                                margin: 0px;
                                line-height: 0px;
                            }
                        }
                    }
                }
            }
            tbody{
                td{
                    padding: 15px 0px;
                    .MuiAutocomplete-root{
                        fieldset{
                            border-radius: 10px;
                        }
                        .MuiInputBase-root{
                            padding-right: 10px;
                        }
                        .MuiInputBase-root:hover{
                            fieldset{
                                border-color: rgba(0, 0, 0, 0.23);
                            }
                        }
                    }
                    .bulkAllocationListLocation{
                            padding-right: 20px;
                            fieldset{
                                border-radius: 10px;
                            }
                            .MuiInputBase-root:hover{
                                fieldset{
                                    border-color: rgba(0, 0, 0, 0.23);
                                }
                            }
                            .MuiInputBase-root.Mui-focused{
                                fieldset{
                                    border-color: rgba(0, 0, 0, 0.23);
                                    border-width: 1px;
                                }
                            }
                            .MuiInputBase-root .MuiSelect-select .MuiCheckbox-root{
                                display: none;
                            }
                    }
                    .bulkAssigneeListDatePicker{
                        width: 215px;
                        fieldset{
                            border-radius: 10px;
                        }
                        .MuiInputBase-root:hover{
                            fieldset{
                                border-color: rgba(0, 0, 0, 0.23);
                            }
                        }
                        .MuiInputBase-root input{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .MuiSelect-select{
        font-size: 14px;
    }
    
}
.allocationHistoryTableSect{
    width: 700px;
    padding: 0px 20px 20px 20px;
    overflow: hidden;
    h3{
        margin: 20px 0px;
        font-size: 14px;
        font-weight: 600;
    }
    .allocationHistoryScrollSect{
        height: 365px;
        overflow-y: auto;
        .MuiTableContainer-root{
            box-shadow: none;
            border: 1px solid #E5E8EB;
           
        }
        
        thead{
            tr{
                background: #F3F3F7;
                th{
                    padding: 10px;
                    color: #727376;
                    font-size: 12px;
                    &:nth-child(1){
                        padding-left: 25px;
                    }
                }            
            }
        }
        tbody{
            tr{
                td{
                    padding: 8px 0px;
                    &:nth-child(1){
                        padding-left: 25px;
                    }
                }
                &:last-child{
                    td{
                        border-bottom: none;
                    }
                }
            }
        }
    }
    
}
.bulkAllocationScrollSect{
    padding-top: 10px;
    height: calc(100vh - 210px);
    overflow: auto;
    padding-right: 20px;
}
.bulkAllocationScrollSect + .MuiBox-root .Mui-disabled{
    opacity: 0.5;
}
.location-mandate-dropdown .MuiCheckbox-root {
    display: none;
}
.bulkAllocationAssigneeField{
    .MuiInputBase-root{
        position: relative;
        &::before{
            content: "";
            width: 18px;
            height: 18px;
            background-image: url(../../../assets/images/bulkAssignSearch.png);
            position: absolute;
            right: 10px;
            top: 20px;
            background-repeat: no-repeat;
            background-size: 100%;
        }
        padding-right: 30px !important;
        .MuiAutocomplete-endAdornment{
            position: absolute;
            right: 20px;
            .MuiAutocomplete-popupIndicator{
                visibility: hidden;
            }            
        }
    }
    .MuiInputBase-root.Mui-focused{
        padding-right: 0px !important;
        .MuiAutocomplete-endAdornment{
            position: relative;
            right: 9px;
        }
    }
}
.bulkAllocErrorText{
    display: flex;
    gap: 5px;
    margin-top: 5px;
    svg{
        width: 14px;
        path{
            fill:red
        }
    }
}
.ellipse1line{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.allocationDisable,.bulkAllocationListDisable{
    pointer-events: none;
    opacity: 0.5;
}