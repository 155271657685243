
.modal-cancel-btn,
.modal-assign-btn {
  border-radius: 8px !important;
  font-size: 14px !important;
}
.task-list-checkbox.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #0275d8 !important;
}
.task-assign-modal{
  .form-wrapper{

    .MuiDialogContent-root {
  .MuiBox-root{
    *{
      margin: 0 !important;
    }
    .error-span{
      margin-top: 10px !important;
    }
    .error-span-date{
      color: #d32f2f;
      font-size: 0.75rem;
      margin-top: 0px !important;
    }
    display: flex;
      flex-direction: column;
      gap: 18px;
  }
    }
  }
}
.dropdownSubtext {
  font-weight: 400;
  font-size: 12px  !important;
  line-height: 15px;
  color: #727376;
}
.css-1t8c7la-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color:#5e5b5d!important;
}
// .inner-textfield{
 
// }
.inner-textfield {
  // max-height: 50px;
  /* overflow: none; */
  box-sizing: border-box;
  .MuiTextField-root {
    padding-top: 10px;
    border: 1.5px solid;
    border-radius: 8px;
    .MuiInputBase-adornedEnd{
      padding: 0 10px 10px 10px !important;
    }

  }
  label{
    background-color: #fff;
  }

  // .MuiInputBase-formControl {
  //   max-height: 50px;
  //   overflow: auto;
  //   /* border: 1px solid; */
  //   // padding-top: 0;
  // }

  .MuiInputBase-formControl fieldset {
    border: none;
    outline: none;
  }
  ::-webkit-scrollbar {
    // display: none;
    width: 6px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f4f5f6;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #aaa;
    width:10px;
    text-overflow: ellipsis;
  }
}
#task-autocomp-popper {
  div {
    width: 100% !important;

    ul {
      width: 100%;
      max-height: 200px !important;

      li {
        // text-overflow: ellipsis;
        // overflow: hidden;      
        word-break: break-word;
        width: 100%;
      }
      li[aria-selected="true"]{
        background-color: #E0F1FE;
      }
    }
  }
}
#name-email-popper {
  div {
    width: 150% !important;

    ul {
      width: 100%;
      max-height: 200px !important;

      li {
        // text-overflow: ellipsis;
        // overflow: hidden;      
        word-break: break-word;
        width: 100%;
      }
    }
  }
}
.autofield-error{
  .MuiTextField-root {
    border-color: red !important;
    border-width: 1.5px !important;
  }
}
.error-span{
  color: #d32f2f; 
  font-size: 0.75rem; 
  margin: 10px 0 0 0  !important;
}
.form-wrapper{
    .datePicker{
      .MuiOutlinedInput-root {        
        border-radius: 8px;
    
      }
      svg{
        path{
          stroke: #727376;
        }
      }  
   }.autofield-error{
    .MuiInputBase-formControl {
      fieldset{
        border-color: red !important;
        border-width: 1.5px !important;
      }
    }
  }
   .scheduler-model-textbox{
    border: none;
  box-sizing: border-box;
.MuiTextField-root{
    width: 100%;
}
  width: 100%;
  .MuiInputBase-colorPrimary {
      fieldset {
          border-radius: 10px;
      }
  }
  & > label{
      font-size: 15px;
      margin: 0;
      padding-right: 8px;
      background-color: #FFFFFF;
  }
  &.Mui-focused{
      & > label{
          transform: translate(14px, -6px) scale(0.7) !important;
          background-color: #FFFFFF;
      }
  }
}
}