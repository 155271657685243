.query-builder-wrapper{
    display: flex;
    width: 100%;
    height: 100%;

    .read-only-btn{
        opacity: 0.5;
        cursor: not-allowed !important;
    }
.tabButton-color{
    border-radius: 4px 0 0 4px;
    border-left: 4px solid #002882;
    background-color: #F0F6FB;
    margin: 10px;
    width: 100%;
    height: 40px;
    padding: 10px;
    cursor: pointer;

    path {
        stroke: #002882 !important;
    }

    }

.tabButton-inactive{
    border-radius: 4px 0 0 4px;
    border-left: 4px ;
    margin: 10px;
    width: 100%;
    height: 40px;
    padding: 10px;
    cursor: pointer;

    path {
        stroke: rgb(179, 181, 183)!important;
    }
    }
    .side-nav-bar{
        height: 100%;
        width: 10%; 
        display: flex;
        flex-direction: column; 
        // padding-top: 1rem; 
        align-items: center;
        // padding-left: 0.5rem;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
    .insigts-text{
        color: #727376;
        font-weight: 400;
        font-size: 14px;
        margin: 10px;
    }
    .total-proflile-wrap{
        display: flex;
        flex-direction: row;
        gap:10px;
        align-items: baseline;
        .total-profile{
            font-size: 25px;
            color: #002882;
            margin: 10px;
        }
        .linkPathIcon{
            cursor: pointer;
            path{
                fill:#002882
            }
        }
    }
   
    .sidePanel-tab-heading{
        font-weight: 500;
        align-items: center;
        margin: 10px;
        margin-left: 0px;
    }
    .header-container{
        border-bottom: 1px solid #CFD1D2;
        padding-bottom: 8px;

        .builder-header{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .goback-button{
                width: 40px; 
                height: 40px;
            }

            p{
                font-weight: 500;
            }
        }
        .header-title-row{
            display: flex;
            justify-content: space-between;
            padding:0 10px 0 0;
            .close-icon-cls{
                cursor: pointer;
            }
        }
    
    }

    .list-query-container{
        height: calc(100% - 60px);
        overflow-y: scroll;

        .MuiSnackbar-root{
            top: 56px;
            right: 84px;
        }

        .list-detail-wrapper{
            padding: 1rem 0rem;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .list-query-headbtn{
                display: flex; 
                justify-content: space-between; 
                align-items: center;
            }
        }
        .list-divider{
            border-bottom: 1px solid #CFD1D2;
        }

        .no-data-container{
            height: 80%; 
            display: flex; 
            font-size: 14px !important; 
            color: #727376;
        }

        .list-status-btn{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;

            .RPA-btn{
                border: 1px solid #002882;
            }
            .status-btn{
                border: 1px solid #D9D9D9;
                gap: 4px;
            }
            .inprogress-btn svg circle{
                stroke: #0E94F4;
            }
            .failed-btn svg circle{
                stroke: #F34334;
            }
            .completed-btn svg circle{
                stroke: #2BAD63;
            }
            .queued-btn svg circle{
                stroke: #f4a236
            }
        }
        .title-wrap{
            cursor: pointer;
            .title{
                font-size: 14px;
                font-weight: 500;
                color: #002882;
                word-break: break-word;
                display: flex;
                align-items: center;
                gap: 5px;
                margin-bottom: 5px;
            }
        }
        .description{
            font-size: 14px;
            font-weight: 400;
            color: #000;
            word-wrap: break-word;
            margin-top: 10px;
        }
        .searchQueryLocation{
            font-size: 14px;
            font-weight: 400;
            color: #000;
            word-wrap: break-word;
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .initatedIndicator {
            margin-top: 10px;
            padding: 5px 10px 5px 10px;
            background-color:#F3F3F7;
            border-radius: 8px;
            .notice-text{
                font-size: 14px;
                line-height: 23px;
                color: #727376;                ;
                .remaining-time{
                    color: #002882;
                }
            }
        }
        .edit-btn svg path{
            stroke: #FF6E00;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    
    }
    ::-webkit-scrollbar-thumb {
        background: #DBDBDB;
        border-radius: 10px;
    }

    .btn-hidden{
        visibility: hidden;
    }

    .MuiInputBase-root {
        border-radius: 10px;
    }

    .add-skills {
        svg{
            path{
                stroke: #002882;
            }
        }
    }
    .add-skills:hover{
        cursor: pointer;
    }
    .MuiSlider-colorPrimary{
        color: #FF6E00;
    }
    .MuiSlider-thumb{
        width:15px;
        height:15px;
        background-color:#fff;
        border: 1px solid #FF6E00;
    }
    .MuiSlider-root {
        margin-left:10px;
        width: 90%;
    }
    .MuiGrid-root{
        margin-bottom:20px;
    }
    .MuiSlider-valueLabelOpen{
        transform: translateY(100%) scale(1) !important;
        top: 0px !important;
        background-color: #000000;
    }
    .MuiSlider-valueLabelOpen::before{
        top: -8px;
    }

    .Add-query-wrapper{
        height: calc(100% - 60px);
        overflow-y: scroll;
        
        .jobInformation-wrap{
            .common-grid-text{
                width: 100%; 
                display: flex; 
                flex-direction: column; 
                gap: 20px;
            }
        }
    .cancel-save-btn{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        // margin-top:20px;
        position: sticky;
        bottom: 0;
        z-index: 9;
        background-color: #fff;
        .save-btn{
            width: fit-content;
            background-color: #002882;
            color: #ffffff !important;
            padding: 5px 10px;
            text-transform: none;
        }
        .save-btn:hover{
            background-color: #002882;
            color: #ffffff !important;
        }
        .cancel-btn{
            width: fit-content;
            background-color: #D3D3D3;
            color: #000000;
            padding: 5px 10px;
            text-transform: none;
        }
       
    }
        .search-in-wrapper{
            // padding-top: 1rem;

            .search-container{
                display: flex; 
                justify-content: space-between; 
                width: 100%; 
                align-items: center;
                flex-wrap: wrap;

                .search-naukri-form{
                    font-size: 14px; 
                    display: flex; 
                    gap: 10px; 
                    align-items: center;
                    flex-wrap: wrap;

                    svg {
                        fill:#002882
                    }

                    .MuiInputBase-root{
                        height: 2rem !important;
                        font-size: 14px !important;
                    }
                }
            }
        }

        .location-wrapper{
            padding-top: 1rem; 
            width: 100%; 
            display: flex; 
            flex-direction: column; 
            gap: 20px;

            .annual-salary{
                display: flex; 
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap-reverse;

                .MuiInputBase-root{
                    height: 2rem !important;
                    font-size: 14px !important;
                }
            }
        }

        .employment-details{
            padding-top: 1rem;

            .header{
                color: #002882; 
                font-size: 16px;
                font-weight: 500;
            }

            .text-area{
                padding-top: 1rem; 
                width: 100%; 
                display: flex; 
                flex-direction: column; 
                gap: 5px;

                .switch-cmt{
                    display: flex; 
                    justify-content: flex-end; 
                    align-items: center;
                }
            }
            .notice-wrapper{
                .notice-container{
                    display: flex; 
                    flex-wrap: wrap; 
                    gap: 10px; 
                    padding-top: 1rem;
                }
            }
        }

        .additional-wrapper{
            padding-top: 1rem;

            .header{
                color: #002882; 
                font-size: 16px; 
                font-weight: 500;
            }

            .additional-container{
                padding-top: 1rem; 
                width: 100%; 
                display: flex; 
                flex-direction: column; 
                gap: 20px;
            }
        }

        .diversity-details {
            padding-top: 1rem;

            .header{
                color: #002882; 
                font-size: 16px;
                font-weight: 500;
            }
            .sub-title {
                margin: 16px 0;
                
            }
            .gender-container{
                display: flex; 
                flex-wrap: wrap; 
                gap: 10px; 
                padding-top: 1rem;
            }
            .checkbox-wrapper {
                display: flex;
                margin: 8px 0;
                align-items: center;
            }
        }
        .display-details {
            padding-top: 1rem;

            .header{
                color: #002882; 
                font-size: 16px;
                font-weight: 500;
            }
            .tag-inside{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
            }
            .sub-title {
                margin: 16px 0;
                .display-container{
                    .addIcon{
                      path{
                            stroke: #727376 !important;
                        }
                    }
                }
            }
            .display-container {
                display: flex; 
                flex-wrap: wrap; 
                gap: 10px; 
                padding-top: 1rem;
            }
        }

        .MuiInputBase-input{
            font-size: 14px !important;
        }
    }
}

.MuiInputBase-formControl {
    max-height: auto;
}
.inner-textfield {
    max-height: auto;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-bottom: 0px !important;
    padding-right: 0px !important;
}


.inner-textfield .MuiTextField-root {
    padding-top: 10px;
    border: 2.3px groove;
    border-radius: 8px;
}
.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    top: 4px;
}
.inner-textfield .MuiTextField-root:hover {
    padding-top: 10px;
    border: 1.5px solid;
    border-radius: 8px;
}
.excludeKeyword,.keySearchField {
    p{
    display: flex;
    font-size: 10px;
    align-items: center;
    gap: 3px;
    margin: 5px 0px 0px 0px;

    svg{
        font-size: 14px;
        color: #ff6e00;
    }
    }

}
.queryUserDetail{
    display: flex;
    justify-content: space-between;

    p{
        font-size: 12px;
        font-weight: bold;
    }
}
.disableSideMenu{
    pointer-events: none;
}