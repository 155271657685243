.Report-container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    background-color: #F3F3F7;
  }
.reportTableContSect{
  background-color: #fff;
  margin-top: 20px;
  border-radius: 10px !important;
  padding: 20px;
}
.submissionReportContainer{
  box-shadow: none !important;
  position: relative;
  height: calc(100vh - 300px);
  .MuiCheckbox-root svg{
    width: 22px;
    height: 22px;
    color: #b0b0b0;
  }
  table{
    thead{
      tr{
        &:first-child{
          background-color: #F3F3F7;
          th{
            padding: 15px 15px 10px 18px !important;
            cursor: auto;
            vertical-align: baseline;
            border: none;
            
            > div{
              display: flex;
              align-items: center;
              gap: 5px;
              line-height: 1;
              font-size: 12px;
              text-transform: uppercase;
              color: gray;     
              position: relative;    
              padding-right: 25px; 
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              svg{
                position: absolute;
                top: 0px;
                right: 0px;
                cursor: pointer;
              }
            }
          }
        }
        &:nth-child(2){
          th{
            &:nth-child(1),&:nth-child(2){
              background-color: #F5F1FB;
            }
          }
        }
        th{
          &:first-child{
            padding-left: 30px !important;
          }
          padding: 8px 16px !important;
          .MuiFormControl-root{
            width: 100%;
            min-width: 120px;
            .MuiInputBase-root{
              padding-left: 10px;
              input{
                padding: 7px 10px 7px 0;
                font-size: 13px;
              }
              fieldset{
                border-radius: 6px;
                border-color: #CFD1D2;
              }
            }
          }
          .reportTableSearchFieldMask{
            border-radius: 6px;
            border: 1px solid #CFD1D2;
            
            .MuiInputBase-root::after,.MuiInputBase-root::before{
              border: 0px !important;
            }
          }
          .reportTableSearchFieldMaskDisable{
            opacity: 0.4;
          }
        }
        th.reportTableHead-preferredLocation{
          min-width: 400px;
        }
        th.reportTableHead-nameOfTheCandidate,
        th.reportTableHead-jobTitle,
        th.reportTableHead-currentOrganization,
        th.reportTableHead-status{
          min-width: 200px;          
        }
      }
    }
    tbody{      
      .nodataFound{
        font-size: 20px;
        width: 100%;
        position: absolute;
        left: 40%;
        top: 50%;
        padding: 0px;
      }
      td{
        &:first-child{
          padding-left: 30px !important;
        }
        div{
          min-width: 150px;
        }
        .candidateName{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
          max-width: 150px;
        }
        &:nth-child(1),&:nth-child(2){
          background-color: #F5F1FB;
        }
        .candidateName.hyperMandate{
          text-decoration: underline;
          color: #002882 !important;
          cursor: pointer;
          font-size: 12px;
        }
        padding: 16px 16px;
        font-size: 13px;
        .primary-badge{
          padding: 5px 10px;
          border-radius: 6px;
          display: flex;
          background-color: #E5E9F3;
          color: #002882;
          width: fit-content;
        }
        .warm-badge{
          padding: 5px 10px;
          border-radius: 6px;
          display: flex;
          background-color: #FCF4E5;
          color: #E09000;
          width: fit-content;
        }
      }
      td.reportTbodyPreferredLoc{
        div{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      td.reportStatus{
        div{
          background: #e5e9f3;
          padding: 3px 10px;
          border-radius: 5px;
          color: #002882;
          float: left;
        }
      }
    }
  }
}
.reportColumnFilters{
  .MuiPaper-root{
    border: 1px solid #CFD1D2;
    border-radius: 10px;
    box-shadow: none;
    margin-top: 6px;
    .MuiFormControl-root{
      width: 100%;
      .MuiInputBase-root{
        input{
          padding: 14px 14px 10px 0;
        }
        fieldset{
          display: none;
        }
      }
    }
    form{
      .MuiBox-root{
        align-items: center;
        padding: 2px 6px;
        p{
          margin: 0;
        }
      }
      .selectAllCheckbox{
        background-color: #f4f5f6;
        border-top: 1px solid #e7e7e7;
      }
      .reportColumnCheckboxes{
        max-height: 300px;
        overflow: auto;
        padding: 0;
      }
    }
  }
}
.reportFilters.customefilterGrid{
  gap: 20px;
  padding-bottom: 20px;
  > .MuiAutocomplete-root{
    width: 100%;
    .MuiInputBase-root{
      padding: 5px 40px 5px 10px !important;
      fieldset{
        border-radius: 8px;
      }
    }
    .MuiFormControl-root{
      width: 100%;
      label:not(.Mui-focused):not(.MuiFormLabel-filled){
        transform: translate(14px, 9px) scale(1);
        font-size: 14px;
      }
      
      fieldset{
        border-radius: 8px;
      }
      .MuiInputBase-root{
        .MuiAutocomplete-endAdornment{
          .MuiAutocomplete-clearIndicator{
            display: none;
          }        
        }
      }
    }
  }
  .MuiFormControl-root{
    width: 100%;
    label:not(.Mui-focused):not(.MuiFormLabel-filled){
      transform: translate(14px, 13px) scale(1);
    }
    input{
      padding: 12px 15px;
    }
    fieldset{
      border-radius: 8px;
    }
    .MuiInputBase-root{
      .MuiAutocomplete-endAdornment{
        .MuiAutocomplete-clearIndicator{
          display: none;
        }        
      }
    }
  }
}
.reportTablePaginationSect{ 
  padding-top: 10px;
  > div{
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .MuiFormControl-root{
    width: 80px;
  
    .MuiInputBase-root {
      fieldset span{
        display: none;
      }
      .MuiSelect-select{
        padding: 8px 8px 8px 8px;
        font-size: 14px;
      }
    }
  }
  nav ul li {
    button{
      border: none;
      padding-top: 2px;

      &:hover{
        background-color: #002882;
        color:#fff;
      }
    }
    button.Mui-selected{
      border: 2px solid #002882;
      color: #002882;
      background-color: #fff;
      font-weight: 600;
      display: flex;
      align-items: center;
      pointer-events: none;
      
      &:hover{
        background-color: #fff;
      }
    }
    button.MuiPaginationItem-previousNext{
      background-color: #919395;
      color:#fff;

      &:hover{
        background-color: #002882;
      }
    }
  }
}
.reportCandidateNameNavigate{
  cursor: pointer;
  color: #002882;
  text-decoration: underline;
}.submissionFilterEnableField{
  position: relative;
}
.submissionFilterEnableField label{
  position: absolute;
  top: -8px;
  left: 15px;
  transform: none !important;
  font-size: 12px;
  transition: none !important;
  background: white;
  padding: 0px 5px;
}
.startEndField {
  position: relative;
  .reminder_text-danger{
    position: absolute;
    left: 0px;
    line-height: 10px;
    bottom: -12px;
    width: 260px;
    font-size: 10px;
  }
}
.DateRangeField > div > .mbsc-page{
  background-color: transparent !important;
}
.applicationUsage {
  .customefilterGrid{
    .dateYearFields{
      label:not(.Mui-focused):not(.MuiFormLabel-filled) {
          transform: translate(14px, 9px) scale(1);
          font-size: 14px;
      }
      label.MuiInputLabel-shrink{
        transform: translate(14px, -9px) scale(0.75) !important;
        font-size: 1rem !important;
      }
      .MuiInputBase-root{
        .MuiSelect-select{
          padding: 9px 40px 7px 10px !important;
          font-size: 14px;
        }
      }
    }
  }
}
.box-style.subScreen{
  background-color: transparent;
  .filter-parent-box{
    background-color: transparent;
  }
}
.dashboardFilterHeader{
  display: flex;
  flex-direction: column;
  .dashboardFilterHeaderTitle{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
  }
  .drillDownObject{
    display: flex;
    width: 100%;
    background-color: rgb(244, 249, 255);
    border-radius: 8px;
    padding: 8px 16px;
    position: relative;
    align-items: center;
    margin-top: 15px;
    &::before{
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 8px;
      height: 100%;
      background-color: rgb(0, 51, 160);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;      
    }
    h4{
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 700;
      margin: 0px;
    }
    ul{
      margin: 0px;
      list-style: none;
      margin: 0px;
      list-style: none;
      display: flex;
      gap: 15px;
      li{
        display: flex;
        align-items: center;
        gap: 10px;
        h5{
          margin: 0px;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.43;
          color: rgba(0, 0, 0, 0.6);
        }
        p{
          color: rgba(0, 0, 0, 0.87);
          background-color: rgba(0, 0, 0, 0.08);
          border: 1px solid rgb(0, 51, 160);
          border-radius: 16px;
          margin: 0px;
          font-size: 0.8125rem;
          padding: 5px 10px;
        }
      }
    }
  }
}
.drillDownBtn{
  border-color: #f4f9ff !important;
  padding: 8px 12px !important;
}
.drillDownBtn.active{
  border-color: #0033a0 !important;
}
.reportFilters .DateRangeField .datePicker .MuiInputBase-root input {
  padding: 8px 10px;
}
.reportFilters .DateRangeField .datePicker label:not(.Mui-focused):not(.MuiFormLabel-filled) {
  transform: translate(14px, 10px) scale(1) !important;
  font-size: 14px;
}