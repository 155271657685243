.candidate-details-main{
    gap: 18px;
    grid-template-columns: 1fr 350px;
    display: grid;
    background-color: transparent;
    min-height: 90vh;
    .task-loading-container, .load-more-section-loading-container {
        display: flex;
        margin-top: 2rem;
        justify-content: center;
    }
    .candidate-header{
        background: #FFFFFF;
        border-radius: 10px;
        width: 100%;
        padding: 20px;
    }
    .candidate-insights{
        background: #FFFFFF;
        border-radius: 10px;
        width: 100%;
    }
    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
    }
}

.candidateDetailToggle{
        gap: 18px;
        grid-template-columns: 1fr 60px;
        display: grid;
        background-color: transparent;
}