.popup-wrap{   

    .header-wrapper{
        display: flex; 
        padding-bottom: 16px; 
        justify-content: space-between;
    }

    .form-wrapper{
        display: flex; 
        flex-direction: column; 
        gap: 20px;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        padding-left: 32px;
        padding-right: 15px;
        padding-top: 10px;

        .timer-date-wrapper{
            width: calc(100% - 24px);
        }
    }

    .scheduler-model-textbox{
        border: none;
        box-sizing: border-box;
        width: 100%;
    .MuiTextField-root{
        width: 100%;
    }
    .MuiInputBase-colorPrimary {
        fieldset {
            border-radius: 10px;
        }
    }
      & > label{
          font-size: 15px;
          margin: 0;
          padding-right: 8px;
          background-color: #FFFFFF;
      }
      &.Mui-focused{
          & > label{
              transform: translate(14px, -6px) scale(0.7) !important;
              background-color: #FFFFFF;
          }
      }

      
    }
    .error-padding{
        margin: -8px 0 0 35px !important;
    }
    .scheduler-reactquill{
        width: 100%;
    .quill{
        .ql-toolbar{
            border-radius: 10px 10px 0 0
        }
        .ql-container{
            border-radius:  0 0 10px 10px
        }
    }
    }
    .timezone-dropdown{
        .MuiOutlinedInput-root{
            // padding: 0px;
            border-radius: 10px;
        }
    }
    .time-picker{
        .MuiOutlinedInput-root{
            border-radius: 10px;
        }
    }
    .date-picker{
        .MuiOutlinedInput-root{
            border-radius: 10px;
        }
    }

    .icon-wrapper-align{
        display: flex;
        align-items: center;
        gap: 10px;

        .iconPath{
            width: 24px !important;
            height: 24px !important;

            path{
                fill: #B3B5B7 !important;
            }
        }
        .no-fill{
            margin-top: 10px;
            path{
                fill: unset !important;
            }
        }
    }
    .schedulerCandidateMandateSel{
        padding-left: 33px;
        width: 250px;
    }

}
.input-rounded{
    border-radius: 10px !important;
}