.hiringTablePaginationSect{ 
    padding-top: 10px;
    > div{
      display: flex;
      align-items: center;
      gap: 20px;
    }
    .MuiFormControl-root{
      width: 80px;
    
      .MuiInputBase-root {
        fieldset span{
          display: none;
        }
        .MuiSelect-select{
          padding: 8px 8px 8px 8px;
          font-size: 14px;
        }
      }
    }
    nav ul li {
      button{
        border: none;
        padding-top: 2px;
  
        &:hover{
          background-color: #002882;
          color:#fff;
        }
      }
      button.Mui-selected{
        border: 2px solid #002882;
        color: #002882;
        background-color: #fff;
        font-weight: 600;
        display: flex;
        align-items: center;
        pointer-events: none;
        
        &:hover{
          background-color: #fff;
        }
      }
      button.MuiPaginationItem-previousNext{
        background-color: #919395;
        color:#fff;
  
        &:hover{
          background-color: #002882;
        }
      }
    }
  }
  .hiringTableSect table thead tr:nth-child(2) th:nth-child(2),
  .hiringTableSect table thead tr:nth-child(2) th:nth-child(1){
    background-color: transparent !important;
  }
  body .reportTableContSect.hiringTableSect table thead tr .dashboardListThead{
    background-color: #fff !important;
  }
  .button_active{
    position: relative;
  }
  .button_active::before{
    position: absolute;
    content: "";
    width: 90%;
    top: -1px;
    height: 3px;
    background-color: #002882;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .columnToggleBtn{
    position: absolute;
    top: 0px;
    right: 8px;
    z-index: 9;
    padding: 6px;
    border-radius: 4px;
    border: 2px solid rgb(255, 110, 0);
    height: 35px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    cursor: pointer;
  }
  .jobOrderAgeingHeaderSect{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    ul{
      list-style: none;
      padding: 0px;
      margin: 0px;
      display: inline-flex;
      gap: 15px;
      li{
        font-size: 13px;
        display: flex;
        align-items: center;
        gap: 5px;
        span{
          width: 8px;
          height: 8px;
          border-radius: 3px;
        }
      }
    }
  }
  .hiringTabTableSect{
    .hiringTabTableHead{
      background-color: #f3f3f7;
      border: none;
      padding: 10px 0px 0px 5px;
      .MuiTabs-root{
        .MuiTabs-scroller{
          .MuiTabs-flexContainer{
            .MuiButtonBase-root{
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;
              position: relative;
              &::after{
                content: "";
                width: 1px;
                height: 60px;
                background-color: #CFD1D2;
                position: absolute;
                left: 0px;
              }
              &:nth-child(1){
                &::after{
                  display: none;
                }
              }
              svg{
                margin-bottom: 0px;
              }
              > div{
                h4{
                  margin: 0px;
                  color: #727376;
                  font-size: 12px;
                  font-weight: 500;
                }
                p{
                  margin: 0px;
                  font-size: 24px;
                  color: #000;
                  text-align: left;
                }
              }        
            }
            .MuiButtonBase-root.Mui-selected + .MuiButtonBase-root{
              &::after{
                display: none;
              }
            }
            .MuiButtonBase-root.disableTabBtn{
              opacity: 0.3;
              pointer-events: none;
            }
            .MuiButtonBase-root.Mui-selected{
              &::after{
                display: none;
              }
              &::before{
                content: "";
                position: absolute;
                background-color: #002882;
                width: 90%;
                height: 4px;
                top: -1px;
                border-radius: 5px;
              }
              border:1px solid #E5E8EB;
              background: #fff;
              border-radius: 10px 10px 0px 0px;
              border-bottom: 0px;
            }            
          }
          .MuiTabs-indicator{
            display: none;
          }
        }
        
      }
    }
    > .MuiTabPanel-root{
      padding: 0px;
      >.MuiBox-root{
        padding: 0px;
        .hiringTablePaginationSect{
          padding: 0px 20px;
        }
      }
    }
  }
  .tabEmptyCase{
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 280px);
    border-radius: 10px;
  }
  .hiringDemandColumn{
    .MuiFormGroup-root{
      label{
        span{
          text-transform: capitalize;
        }
      }
    }
  }
  .hiringLocationColumn{
    margin: 0px;
    display: flex;
    gap: 5px;
    span{
      color: rgb(0, 51, 160);
      font-weight: 600;
    }
  }
  .candidateRedirect{
    text-decoration: underline;
    color: #002882 !important;
    cursor: pointer;
  }
  .clientColumn span{
    width: 200px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .jobTitleColumn span{
    width: 200px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .hiringTableTopSect .hiringDemandTableSect table thead tr:first-child th:last-child{
    padding-right: 50px !important;
  }