.aiImageSect{
    position: absolute;
    bottom: 15px;
    right: 15px;  
    .generateAiTooltip{
      position: absolute;
      left: -190px;
      top: 0px;
      background: #000;
      color: #fff;
      display: flex;
      padding: 10px 10px 10px 15px;
      border-radius: 6px;
      font-size: 14px;
      align-items: center;
      gap: 20px;
      &::before{
        content: "";
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid black;
        position: absolute;
        right: -8px;
      }
    }
    .aiImageBlk{
      border: 1px solid #8B86C4;
      border-radius: 10px;
      display: flex;
      padding: 11px 10px 10px 11px;
      background: #ECE8EE;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .aiImagePopover{
      position: absolute;
      border: 1px solid #8B86C4;
      background: #F2F2F8;
      border-radius: 10px;
      bottom: 55px;
      padding: 10px 20px;
      width: 310px;
      z-index: 9;
      right: 0px;
      .closeIcon{
        position: absolute;
        right: 10px;
        width: 20px;
        cursor: pointer;
      }
      h5{
        text-transform: uppercase;
        font-weight: 500;
        color: #4E4E4E;
        margin-bottom: 0px;
        margin-top: 5px;
      }
      ul{
        list-style: none;
        padding: 0px;
        li{
          display: flex;
          gap: 10px;
          font-size: 14px;
          margin-bottom: 0px;
          cursor: pointer;
          padding: 8px 5px 8px 10px;
          border-radius: 6px;
          border: 1px solid #f2f2f8;
          &:hover{
            background-color: #fff;
            border: 1px solid #e3e3e3;
          }
        }
      }
      .arrow-down {
        position: absolute;
        bottom: -11px;
        right: 12px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #282396;
      }
    }  
  }