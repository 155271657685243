#profileModal{
    position: fixed;
    overflow-y: auto;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 99999;
    .profileModalSect{
        .fixed-transition-opacity{
            transition-property: opacity;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
            position: fixed;
            width: 100%;
            height: 100vh;
            background-color: #8080808f;
            z-index: 100;
            .profileUploadDialogBox{
                vertical-align: middle;
                max-width: 24rem;
                margin: 0 auto;
                // height: 520px;
                background-color: white;
                position: relative;
                border-radius: 0.5rem;
                margin-top: 20px;
                .profileUploadDialogBoxBlk{
                    padding: 1.5rem;
                    height: 100%;
                    > .relative{
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .text-gray-800{
                            font-size: 16px;
                            text-align: center;
                            font-weight: 500;
                            line-height: 24px;
                        }
                    }
                    .border-dashed{
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        border-style: dashed;
                        border-color: #e5e7eb;
                        border-width: 1px;
                        padding: 1.5rem;
                        > .flex{
                            height: 250px;
                            display: flex;
                            justify-content: center;
                            .crop-container{
                                background: linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 0, linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 100%, linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 0, linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 100%, linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 0 0, linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 100% 0, linear-gradient(to top, #cbd4e1 8px, transparent 8px) 0 100%, linear-gradient(to top, #cbd4e1 8px, transparent 8px) 100% 100%;
                                position: relative;
                                width: 236px;
                                height: 236px;
                                background-repeat: no-repeat;
                                background-size: 70px 70px;
                            }
                        }
                    }
                }
                .profileUploadSetting{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 15px;
                    > .flex{
                        button{
                            background: transparent;
                            border: none;
                            cursor: pointer;
                        }
                    }
                    .anotherPicBtn{
                        background: gray;
                        border: none;
                        padding: 10px 20px;
                        border-radius: 20px;
                        color: #fff;
                        width: 100%;
                    }
                    .uploadProfileAction{
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        gap: 10px;
                        .cancel {
                            background: #ef4444;
                            border: none;
                            padding: 10px 20px;
                            border-radius: 30px;
                            color: #fff;
                        }
                        .doneSave{
                            width: 100%;
                            background: #3b82f6;
                            border: none;
                            padding: 10px 20px;
                            border-radius: 30px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.profileModalDisplayEnable{
    display: block;
}
.profileModalDisplaydisable{
    display: none;
}
.profileUploadImgSect{
    > img{
        width: 150px;
        height: 150px;
        border-radius: 15px;
    }
}