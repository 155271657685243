.remainders-wrapper{
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    // width: fit-content;
    height: 500px;
    overflow: auto;
    min-width: 500px;
    position: relative;

    .header-text{
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
    }

    .container{
        padding-top: 10px;
        .container-text{
            color: #727376;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
        }

        .remainders-list{
            .remainderNoData{
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                left: 0px;
                top: calc(50% - 75px);
                font-size: 14px;
                gap: 10px;
                color: #727376;
                margin-top: 20px;
                img{
                    width: 140px;
                }
            }
            .remainder{
                display: flex;
                // justify-content: space-between;
                gap: 20px;
                border-bottom: 1px solid #D9D9D9;
                padding: 15px 0px;
                cursor: pointer;

                .timer-container{
                    border-radius: 5px;
                    background: #F0F6FB;
                    padding: 8px;
                    position: relative;
                    min-width: 76px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .topline{
                        position: absolute;
                        width: 70%;
                        height: 5px;
                        background-color: #002882;
                        top: 0;
                        border-bottom-left-radius: 25px;
                        border-bottom-right-radius: 25px;
                    }
                    div:nth-child(2){
                        color: #002882;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                    }
                    div:nth-child(3){
                        color: #000;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        padding-top: 4px;
                    }

                }

                .event-details{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    .event-name{
                        color: #000;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                    }
                    .event-assign{
                        display: flex;
                        gap: 15px;

                        .secondory-text{
                            color: #000;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            min-width: 100px;
                        }
                    }
                }

                .now-blk{
                    width: 59px;
                    height: 32px;
                    border-radius: 5px;
                    background: #E8F5E9;
                    color: #2BAD63;
                    font-size: 14px;
                    font-weight: 600;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: auto;
                }

                .hidden-btn{
                    visibility: hidden;
                }
            }
        }
    }

}
* ::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}

* ::-webkit-scrollbar-track {
    border-radius: 10px;

}

* ::-webkit-scrollbar-thumb {
    background: #DBDBDB;
    border-radius: 10px;
}
.user-menu ul{
    padding-left: 10px;
    padding-right: 10px;
    min-width: 230px;
    padding-bottom: 20px;

    .profileContentSect {
        display: flex;
        gap: 10px;
        // border-bottom: 1px solid #CFD1D2;
        padding-bottom: 10px;

        img{
            width: 40px;
            height: 40px;
            border-radius: 10px;
            border: 1px solid #e9e9e9;
        }
        .profileContentDetailSect{
            .nameField{
                font-size: 14px;
                font-weight: 700;
            }
            .userRole{
                font-size: 10px;
                font-weight: 500;
            }
        }
    }
    .profileContentSectRole{
        display: flex;
        background: #F2F2F2;
        padding: 10px;
        border-radius: 40px;
        gap: 10px;
        p{
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 5px;
            span{
                line-height: 10px;
                margin-top: 3px;
                font-size: 13px;
            }
        }
        p.middleLine{
            background: #B3B5B7;
            width: 2px;
        }
    }
    .userAccountSwitchList,.copilotSwitchList{
        border-top: 1px solid #CFD1D2;
        margin-top: 20px;
        padding: 0px 10px;
        max-height: 200px;
        overflow: scroll;
        h3{
            color: #727376;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
            margin-bottom: 0px;
        }
        ul{
            list-style: none;
            padding: 0px;
            li{
                cursor: pointer;
                h4{
                    margin-bottom: 0px;
                    font-size: 12px;
                    font-weight: 500;
                    margin-top: 10px;
                }
                h5{
                    margin-top: 0px;
                    margin-bottom: 15px;
                    font-weight: 400;
                    color: #727376;
                    display: flex;
                    gap: 5px;
                    line-height: 16px;
                    font-size: 12px;
                    span.middleLine{
                        background: #B3B5B7;
                        width: 2px;
                    }
                }                
            }
        }
    }    
    li{
        button{
            padding-top: 3px;
            padding-bottom: 3px;
            padding-left: 0px;
        }
    }
} 