.login-page-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  background-repeat : no-repeat;
  background-size: cover;
  background-image: url('../src/assets/images/LoginBgImg.svg');
  background-color: #F3F3F7;
}
.login-button {
  width: 100%;
  height: 53px;
  background-color: #2D2E2E !important;
  padding-top: 0.8rem !important;
  margin-top: 20px !important;
  margin-inline: auto !important;
  color: #FFF !important;
  text-transform: capitalize !important;
}
.login-button :hover{
  background-color: #000 !important;
  color: #FFF !important;
}
.filter-reset-button {
  width: 8rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter-reset-label {
  margin-left: .375rem;
}
.filter-search-button {
  width: 6rem;
  height: 2.5rem;

  background: #000000;
  color: #FFFFFF !important;
  border-radius: 8px;
}

.form-container-grid {
  max-width: 70rem;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32), 0px 4px 8px rgba(91, 104, 113, 0.24);
  border-radius: 10px;
}

.login-screen-welcome-text {
  font-weight: 600 !important;
  font-size: 18px !important;
}
.login-screen-welcome-note {
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: rgb(27 28 30 / 70%);
  margin: 5px 0px 30px 0px !important;
  max-width: 260px;
}
.underMaintenanceSect .login-screen-welcome-note{
  max-width:fit-content;
}
.underMaintenanceSect + img{
  width: 280px;
}
.eye-icon {
  color: #002882;
}

.eye-icon {
  color: #002882;
}
.back-button {
  cursor: pointer;
}

.login-screen-verified-text {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #002882;
  margin-top: 2px !important;
}
.login-screen-verified-text-container{
  margin-top: 14px;
  margin-bottom: 14px;
}

.login-screens-container-grid {
  width: 100%;
}
.login-screens-container-sub-grid {
  padding-top: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
  .MuiGrid-container{
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}

.common-load-more {
  font-style: normal;
  font-weight: 400;
  font-size: .875rem;
}

.MuiFormLabel-root.MuiInputLabel-shrink.Mui-focused{
  color: #5e5b5d ;
}

.MuiAutocomplete-popper *::-webkit-scrollbar {
  // display: none;
  width: 6px;
  height: 10px;
}
.MuiAutocomplete-popper *::-webkit-scrollbar-track {
  background-color: #f4f5f6;
  border-radius: 10px;
}
.MuiAutocomplete-popper *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aaa;
  width:10px;
  text-overflow: ellipsis;
}
.MuiTooltip-popper {
  z-index: 999999999 !important;
}
.syncGoogleBtn img{
  background-color: #fff;
  padding: 5px;
  border-radius: 7px;
  margin-right: 5px;
}
.welcomeCardSect{
  background-color: #002882;
  background: url(../src/assets/images/welcome_card_img_2.svg) top left no-repeat, url(../src/assets/images/welcome_card_img_1.svg) top 17px right 50px no-repeat, linear-gradient(to right, #002882, #002882) ;
  padding: 25px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}
.welcomeCardSect div{
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  color: #CCDBF2;
  gap: 5px;
}
.welcomeCardSect div span{
  letter-spacing: 1px;
  font-size: 16px;
  color: #fff;
}
.welcomeCardSect b{
  font-weight: 600;
}
.marb0{
  margin-bottom: 0;
  padding-left: 28px;
}

.marb0 div{
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  color: #CCDBF2;
  gap: 5px;
}
.marb0 div span{
  letter-spacing: 1px;
  font-size: 20px;
  color: #fff;
}
@media (max-width: 1000px) {
  .loginRightTopLogoSect{
    img{
      width: 130px;
    }
  }
}
@media screen and (max-width: 750px) and (min-width: 600px) {
  .loginRightTopLogoSect{
    flex-direction: column;
    gap: 30px;
  }
}
@media screen and (max-width: 600px){
  .loginRightTopLogoSect{
  justify-content: left !important;
  gap: 40px;
  }
}
.confettiEffectSect{
  background: #0000004f;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  .close{
    background: rgb(255 255 255 / 50%);
    border-radius: 30px;
    padding: 4px;
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 220px;
    font-size: 30px;
  }
  canvas{
    z-index: 999999;
  }
  .confettiEffectSectCard{   
    padding: 140px 60px 70px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    position: relative;

    .confettiEffectSectCardStar{
      background-image: url(./assets/images/confettiEffectStar.gif);
      background-size: 426px 242px;
      width: 426px;
      height: 242px;
      position: absolute;
      top: 270px;
      left: 65px;
    }
    h4{
      font-size: 14px;
      color: #000;
      font-weight: 500;
      padding: 0px 30px;
      text-align: center;
      margin-top: 60px;
      margin-bottom: 0px;
    }
    .cardTopSect{
      display: flex;
      flex-direction: column;
      align-items: center;
      p.wellDone{
        color: #fff;
        text-transform: uppercase;
        margin: 5px 0px 10px 0px;
        line-height: 15px;
      }
      img{
        width: 35px;
      }
      .confettiEffectCardContBlk{  
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        p.name{
          margin: 0px;
          color: #fff;
          font-weight: 500;
          font-size: 16px;
          line-height: 15px;
        }
        p.role{
          margin: 0px;
          font-size: 12px;
          font-weight: 300;
          color: #f3f3f3;
        }
    }
    }
    
    .congrateText{
      font-size: 13px;
      color: #000;
      display: flex;
      gap: 5px;
      margin-top: 15px;
      margin-bottom: 10px;
      font-weight: 500;
      img{
        width: 20px;
        height: 20px;
      }
    }
    .confettiEffectSectCardTeam{
      display: flex;
      margin-top: 10px;      
      border-radius: 50px;
      padding: 18px 10px;
      width: 350px;
      justify-content: center;
      .MuiBox-root{
        padding: 0px 10px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        &:nth-child(1){
          border-right: 1px solid #ffffff75;
        }
        &:last-child{
          border-right: 0px solid #ffffff75;
        }
        p.name{
          margin: 0px;
          font-size: 13px;
          color: #fff;
          width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // text-align: center;
        }
        p.role{
          margin: 0px;
          font-size: 11px;
          font-weight: 300;
          color: #fff;
        }
      }
    }
    
  }
  .confettiEffectSectCardSelected{
    background-image: url(./assets/images/confettiEffectBannerBg.png);
    background-size: cover;
    width: 574px;
    height: 580px;
    .confettiEffectSectCardTeam{
      background: linear-gradient(90deg, rgba(172,33,88,1) 0%, rgba(13,57,215,1) 100%);
    }
  }
  .confettiEffectSectCardJoined{
    background-image: url(./assets/images/confettiEffectJoinedBannerBg.png);
    background-size: cover;
    width: 574px;
    height: 580px;
    .confettiEffectSectCardTeam{
      background: linear-gradient(90deg, rgba(138,42,87,1) 0%, rgba(65,16,125,1) 100%);
    }
  }
}
.confettiEffectSect3 {
  > .close{
    right: 60px;
  }
  .confettiEffectSectCard{
    padding: 130px 50px 70px 80px;
    background-size: 554px 580px;
  }
}
// .rotateHtml{
//   animation: rotation 2s infinite linear;
// }
// @keyframes rotation {
//   50% {
//     transform: rotate(180deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
.rotateHtml {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

#myDiv{
  background-color: blue;
  height: 200px;
  width: 200px;
}
.zohoAnalyticsReport{
  width: 100%;
  height: calc(100vh - 120px);
  border: 0px;
  border-radius: 15px;
}
.dashboardScreenSect{
  > .MuiGrid-root.MuiGrid-container{
    margin-top: 0px;
    padding: 0px;
    > .MuiGrid-root.MuiGrid-item{
      // padding-left: 0px;
      /* margin: 10px 0px; */
      padding-top: 20px;  
    }
  }
}