.mandate-full-page{
    background-color: #fff !important;
    border-radius: 10px !important;
    margin-right: 0.5rem !important;
	padding: 1rem;
}
.mandateContentView .mandate-full-page{
    padding: 10px 0px 0px 0px;
}
.mandate-full-page-box{
    row-gap: 0.5rem;
}

.data-on-fullwidth{
    width: 100;
    display: flex;
    row-gap: 3;
}
.mandate-full-page-box{
    justify-content: space-between;
}

.mandate-detail-job-desp{
    margin-left: 0.5rem;
}

.mandate-grid-item-field-name {
    font-weight: 400;
    font-size: .875rem;
    color: #727376;
}

.mandate-grid-item-field-value {
    font-weight: 400;
    font-size: .875rem;
    color: #000000;
}

.mandate-parser-grid-items-container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
}

.mandate-parser-grid-items-container-right-section {
    border-left: 1px solid #CFD1D2;
}

.mandate-grid-item-field-name, .mandate-grid-item-field-value {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mandate-grid-item-field-name-nowrap{
    width: 100%;
    font-weight: 400;
    font-size: .875rem;
    color: #727376;
}

.mandate-data-box .mandate-data-headings ,.mandate-data-box .mandate-data-details {
    font-size: 14px !important;
    line-height: 1.5;
    font-weight: 400;
    text-transform: capitalize;
}

.MuiTypography-root.MuiTypography-body1.primaryskill-content {
    width: 250px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


.mandate-data-details.job-desp{
    margin-right: 1rem;
}

.mandate-full-page{
    background-color: #fff !important;
    border-radius: 10px !important;
    margin-right: 0.5rem !important;
}
.mandate-full-page-box{
    row-gap: 0.5rem;
}

.data-on-fullwidth{
    width: 100;
    display: flex;
    row-gap: 3;
}
.mandate-full-page-box{
    justify-content: space-between;
}

.mandate-detail-job-desp{
    margin-left: 0.5rem;
}

.mandate-data-box .mandate-data-headings ,.mandate-data-box .mandate-data-details {
    font-size: 14px !important;
    line-height: 1.5;
    font-weight: 400;
}

.mandate-data-details.job-desp{
    margin-right: 1rem;
}
/* .MuiGrid-root.MuiGrid-grid-lg-8.mandate-full-page{
    max-width: 65.1%;
}
@media only screen 
  and (min-device-width: 360px) 
  and (max-device-width: 667px) 
   { 
    .MuiGrid-root.MuiGrid-grid-lg-8.mandate-full-page{
        max-width: 95%;
    }
} */
.mandate-user-label{
    font-weight: 400;
font-size: 14px;
}
.mandate-data-box.data-box-flex{
    width: 90%;
    display: flex;
}

.parsing-progress-cls{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    column-gap: 50px;
}

.hour-icon-cls{
    font-size: 50px !important;
    color: gray;
}

.parse-progress-test{
    font-size: 25px !important;
    color:gray ;
}
.mandate-divider{
    margin-top: 10px !important;
}

.primary-text-font{
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #000000;
    word-break: break-word;
}

.secondary-text-font{
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #727376;
    word-break: break-word;
}
.secondary-text-font-with-black{
    font-family: 'Sora';
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px !important;
    color: black;
    word-break: break-word;
}

.secondary-file-font{
    display: flex;
    flex-direction: column;
    color: #081F36 !important;
    text-decoration-line: underline;
}

.mandate-edit-btn .edit-btn {
    gap: 4px;
}

.mandate-edit-btn .edit-btn SVG path {
    stroke: #fff !important;
}

.mandate-detail-data .mandate-header-data{
    display: flex;
    justify-content: space-between;
}

.mandate-detail-data .mandate-edit-wrapper{
    margin-Right:"1rem"; 
    display: flex; 
    align-items: center;
    gap: 10px;
 }
 
.mandate-download-btn .edit-btn {
     gap: 4px;
 }
.mandate-download-btn > button{
     border: 1px solid #002882;
}
.mandate-detail-data .mandate-data-box{
    margin-top: 1.5rem;
}

.full-width-minscreen .margin-unset{
    margin-right: unset !important;
}

.mandate-detail-data .location-wrapper{
    width: 100%; 
    margin-top: 8px; 
}

.mandate-detail-data .location-wrapper .location-tag-container{
    width: 50%; 
    display: flex; 
    flex-wrap: wrap; 
    gap: 10px; 
    margin-left: 1rem; 
    margin-top: 8px;
}

.mandate-container-wrapper{
    height: calc(100vh - 72px);
    overflow-y: auto;
}

.sticky-filter{
    position: sticky;
    top: 0;
    align-self: flex-start;
}

.mandate-container-wrapper::-webkit-scrollbar {
    width: 5px;
}
.mandate-container-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;

}
.mandate-container-wrapper::-webkit-scrollbar-thumb {
    background: #DBDBDB;
    border-radius: 10px;
}

@media (max-width: 1200px) {

    .full-width-minscreen{
        max-width: unset !important;
        flex-basis: 100% !important;
    }
}
@media (mix-width: 900px) {
    .full-width-minscreen{
        max-width: unset !important;
        flex-basis: 100% !important;
    }
}
.upper-header-location-component{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.locationGrid{
    display: flex !important;
    gap: 20px !important;
    flex-wrap: wrap;
    justify-content: space-between;
}
.side-mandate-user{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: fit-content;
    height: fit-content;
}
.locationGrid .MuiBox-root{
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
}
.mandateDetToggleState .mandate-full-page{
    padding-left: 0px;
    padding-right: 0px;
}
.mandateDetToggleState .mandate-full-page .query-builder-wrapper > .MuiBox-root:nth-child(1){
    width: 0% !important;
    overflow: hidden;
}
.mandateDetToggleState .mandate-full-page .query-builder-wrapper > .side-nav-bar{
    width: 100%;
    border: none;
}
.mandateDetToggleState .mandate-full-page .query-builder-wrapper > .side-nav-bar svg{
    margin-left: 0px;
}