.candidate-search-results-section-box {
    min-height: calc(100vh - 16.8rem);
    margin-top: 1.25rem;
    padding: 1rem .875rem;
    position: relative;
    overflow: hidden;

    .candidate-loading-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .candidate-empty-search-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .empty-search-message {
            margin-top: 1rem;
            font-style: normal;
            font-weight: 400;
            font-size: .875rem;
            line-height: 18px;

            text-align: center;

            color: #727376;
        }
    }

    .candidate-search-result-header {
        display: flex;
        // justify-content: space-between;
        // align-items: center;
        margin-bottom: .6875rem;
        padding-bottom: 10px;
        border-bottom: 1px solid  #E5E8EB;
        .candidate-search-title {
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.25rem;
            color: #000000;
        }
        .candidate-search-inputs-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .checkbox-form-group {
                .checkbox-form-label .MuiFormControlLabel-label {
                    font-style: normal;
                    font-weight: 400;
                    font-size: .8125rem;

                    color: #002882;
                }
            }
            .initiate-rpa-button {
                display: inline-block;
                padding: .5rem 1.25rem;

                background: #000000;
                border-radius: .5rem;

                font-style: normal;
                font-weight: 400;
                font-size: .875rem;
                text-transform: none;

                display: flex;
                align-items: center;
                text-align: center;

                color: #FFFFFF;
                &:disabled {
                    opacity: 0.6;
                    cursor: not-allowed !important;
                    pointer-events: all;
                }
            }
        }

        

        }
        .match-criteria-chips {
            padding-bottom: 10px;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            align-items: 'center';

            .chips-inside {
                display: flex;
                flex-direction: row;
                border-radius: 5px;
                align-items: center;
                border-radius: 5px;
                background-color: #E5E8EB;
                height: 25px;
                .closeIcon{
                   
                       path{
                            stroke: #ff6e00;
                        }
                cursor: pointer;
                }
            }
        }
    .search-result-box {
        min-height: 9.125rem;
        border: 1px solid #E5E8EB;
        border-radius: 10px;
        margin-bottom: .9375rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: .9375rem;
        .search-result-body-wrap{
            display: flex;
            .circular-progress{
                width: 15%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 15px;
                margin: 10px;

                .circularProgressBox{
                    // position: absolute;
                    .circularProgressBG{
                        color: #eee !important;
                        position: absolute;
                        inset: 0;
                    }
                }
                .type-badge{
                    display: inline-block;
                    padding: 5px 10px;
                    border: 0.5px solid #1A85D6;
                    background-color: #EAF4FB;
                    color: #1A85D6                    ;
                    border-radius: 5px;
                    font-size: 12px;
                    line-height:1;
                }
            }
            .search-result-body {
                width: 85%;
                padding: .75rem .875rem;
                .search-result-body-title-row {
                    min-height: 2.6875rem;
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.25rem;
                    // background-color: lightcoral;
                    .search-result-item-checkbox {
                        // padding-left: 0px;
                        // padding-right: 1.1875rem;
                        margin-left: -7px;
                    }
                    .search-result-avatar {
                        width: 2.6875rem;
                        height: 2.6875rem;
                        font-style: normal;
                        font-weight: normal;
                        font-size: .875rem;
                    }
                    .search-result-candidate-data {
                        margin-left: .6875rem;
                        align-self: flex-start;
                        .search-result-candidate-name {
                            font-style: normal;
                            font-weight: 600;
                            font-size: .875rem;
                            color: #000000;
                            cursor: pointer;
                        }
                        .search-result-candidate-details {
                            display: flex;
                            align-items: center;
                            margin-left: -0.2rem !important;
                            flex-wrap: wrap;
                            .candidate-info-item {
                                display: flex;
                                align-items: center;
                                margin-right: .9375rem;
                                margin-bottom: 5px;
                                .candidate-info-item-icon {
                                    margin-right: .2656rem;
                                    display: flex;
                                    align-items: center;
                                    img {
                                        font-size: 1rem;
                                    }
                                }
                                .candidate-info-item-text {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: .8125rem;
                                    
                                    color: #727376;
                                }
                            }
                        }
                    }
    
                }
                .search-result-body-summary-text {
                    font-style: normal;
                    font-weight: 400; 
                    font-size: .8125rem;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-left: 2.3125rem;
                }
            }
        }
        .search-result-footer {
            padding-left: .9375rem;
            background: #F3F3F7;
            border-radius: 0px 0px 10px 10px;
            display: flex;
            align-items: center;
            padding-right: .9375rem;
            .footer-data-container {
                margin-right: .9375rem;
                font-style: normal;
                font-weight: 400;
                font-size: .8125rem;
                display: inline-block;
                .footer-data-label {                      
                    color: #727376;
                    display: inline-block;
                    margin-right: 0.3rem;
                }
                .footer-data-text {
                    color: #000000;
                    display: inline-block;
                    min-width: 80px;
                }
            }
        }
    }
}
