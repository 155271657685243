.appTrendsBtnMode{
    button{
        color: #000;
        text-transform: capitalize;
        padding: 6px 15px;
        font-size: 14px;
        border-radius: 7px;
        border-color: rgba(0, 0, 0, 0.23) !important;
    }
    button:not(.active):hover{
        background-color: #fff;
        color: #000;
    }
    .active{
        background-color: rgb(0,40,130) !important;
        color: #fff;
    }
}
.appTrendsBarChart{
    width: 100%;
    height: 490px;
    svg{
        > g{
            > g{
                &:nth-child(3){
                    > text{
                        font-weight: 600;
                        font-family: Sora !important;
                        font-size: 14px !important;
                    }
                }
                &:nth-child(2){
                    > text{
                        font-weight: 600;
                        font-family: Sora !important;
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
}
.roleWiseHeight{
    height: 450px;
}
.appTrendsSelectField{
    .MuiSelect-select{
        padding: 8px 40px 8px 10px;
        font-size: 14px;
        min-width: 120px;
    }
    fieldset{
        border-radius: 7px;
    }
}
.appTrendSect.DateRangeField{
    fieldset{
        border-radius: 7px;
    }
}
.switchToggleSect{
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    padding: 10px 15px;
    background-color: #e1f2eb;
    margin-right: 10px;
    p{
        margin: 0px;
        font-size: 14px;
        font-weight: 500;
    }
    .MuiSwitch-root > .MuiButtonBase-root.Mui-checked + .MuiSwitch-track{    
        background-color: #c8063a !important;
    }
}
.applicationUsage  .DateRangeField.appTrendSect {
    width: 210px;
    .date-filter-input-role{
        width: 100%;
    }
    .date-filter-input{
        width: 100%;
    }
}