.kanbanBoardContainer {
    width: calc(100%);
    background: #FFF;
    overflow-x: scroll;
    padding: 30px 30px 30px 0px;
    display: flex;
  
    .DnDcontainer{
        max-width: 1630px;
        width:1630px;
        overflow:hidden;
        display: flex;
        gap: 25px;
        height: 740px;
        flex-shrink: 0;
        align-items: center;
    }


    &::-webkit-scrollbar {
        height: 6px;
    }

    .columnContainer {
        display: flex;
        padding: 15px;
        gap: 10px;
        height: 100%;
        flex-shrink: 0;
        width: 250px;
        flex-direction: column;
        border-radius: 10px;
        background: #F3F3F7;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            display: none;
        }

        .columnHeader {
            display: flex;
            justify-content: space-between;

            .title {
                color: #727376;
                font-family: Sora;
                font-size: 12px;
                font-weight: 500;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: center;
            }

            .count {
                color: #FFF;
                font-family: Sora;
                font-size: 13px;
                padding: 2px;
                font-style: normal;
                text-align: center;
                font-weight: 500;
                line-height: normal;
                border-radius: 13.5px;
                background: #727376;
                width: 35px;
                height: 23px;
                flex-shrink: 0;
            }
        }

        .KanbanCard {
            touch-action: none;
            border-radius: 10px;
            border: 1px solid #E5E8EB;
            width: 220px;
            height:110px;
            background: #FFF;
            // width: 100%;
            padding: 15px;
            // height: 17%;
            flex-shrink: 0;
            opacity: 1 !important;

            div {
                .row1 {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .name {
                        color: #000;
                        padding-top: 5px;
                        font-family: Sora;
                        font-size: 14px;
                        font-style: normal;
                        white-space: nowrap;
                        font-weight: 600;
                        line-height: normal;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }

            .rowlocation {
                align-items: baseline;
                display: flex;

                svg {
                    align-self: center;
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;

                    path {
                        // fill: ;
                        stroke: #727376;
                    }
                }

                .location {
                    color: #727376;
                    padding: 2px;
                    font-family: Sora;
                    align-self: center;
                    font-size: 13px;
                    text-transform: capitalize;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

            }

            .rowStatus {

                display: flex;
                justify-content: space-between;
                padding: 10px 0px;

                .status {
                    color: #002882;
                    border-radius: 5px;
                    border: 1px solid #E5E5E5;
                    background: #ECEFF1;
                    padding: 5px;
                    min-width: 110px;
                    font-family: Sora;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                    text-align: center;
                    max-width: 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }




        }
    }


}


.KanbanCard {
    touch-action: none;
    border-radius: 10px;
    border: 1px solid #E5E8EB;
    width: 220px;
    height:110px;
    background: #FFF;
    padding: 15px;
    flex-shrink: 0;
    opacity: 1 !important;

    div {
        .row1 {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .name {
                color: #000;
                padding-top: 5px;
                font-family: Sora;
                font-size: 14px;
                font-style: normal;
                white-space: nowrap;
                font-weight: 600;
                line-height: normal;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .rowlocation {
        align-items: baseline;
        display: flex;

        svg {
            align-self: center;
            width: 16px;
            height: 16px;
            flex-shrink: 0;

            path {
                // fill: ;
                stroke: #727376;
            }
        }

        .location {
            color: #727376;
            padding: 2px;
            font-family: Sora;
            align-self: center;
            font-size: 13px;
            text-transform: capitalize;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

    }

    .rowStatus {

        display: flex;
        justify-content: space-between;
        padding: 10px 0px;

        .status {
            color: #002882;
            border-radius: 5px;
            border: 1px solid #E5E5E5;
            background: #ECEFF1;
            padding: 5px;
            min-width: 110px;
            font-family: Sora;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
            text-align: center;
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.popup-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    margin-left: 20px;
    border-radius: 10px;


}

.popup-Hover {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    margin: 0px 0 0 210px;
    border-radius: 10px;
    flex-shrink: 0;
    .hover-items {
        display: flex;
        flex-direction: column;
        padding: 10px;
        width: 200px;
        height: auto;
        flex-shrink: 0;
        border: 1px solid #E5E8EB;
        .hover-btn{
            color: #000;
            font-family: Sora;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            align-self: center;
        }
        .hover-btn:hover {
            color: #002882;
            width: 100%;
            background-color: #ECEFF1;
        }
    }
}

.more-options {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 180px;
    height: 150px;
    flex-shrink: 0;
    border: 1px solid #E5E8EB;

    .hide {
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        height:40px;
        .right-arrow-container{
            display: flex;
            justify-content: space-between;
        }

        .icon {
            color: #727376;
            align-self: center;
            width: 16px;
            height: 16px;
            font-size: 10px;
        }

        span {
            color: #000;
            font-family: Sora;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            align-self: center;

        }

    }
    .hide:hover {
        .icon{
            color: #002882;
        }
        span{
            color: #002882;

        }
        color: #002882;
        background-color: #ECEFF1;
    }
    .move:hover {
        .icon{
            color: #002882;
        }
        span{
            color: #002882;

        }
        color: #002882;
        background-color: #ECEFF1;
    } .delete:hover {
        .icon{
            path {
                stroke: #002882;
            }
        }
        span{
            color: #002882;

        }
        color: #002882;
        background-color: #ECEFF1;
    }

    .move {
        height:40px;
        justify-content: space-between;
        .move-container{
            display: flex;
            gap: 5px;

        }

        .icon {
            color: #FFF;
            stroke: #727376;
            align-self: center;
            width: 16px;
            height: 16px;
            font-size: 10px;
            stroke: #727376;
        }


        span {
            color: #000;
            font-family: Sora;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            align-self: center;

        }
    }

    .delete {
        display: flex;
        gap: 5px;
        justify-content: flex-start;

        height:40px;


        .icon {
            color: #727376;
            align-self: center;
            width: 16px;
            height: 16px;
            font-size: 10px;
        }


        span {
            color: #000;
            font-family: Sora;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            align-self: center;

        }
    }



}

.card-on-move {
   visibility: hidden;
}

