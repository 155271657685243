.list {
  height: 100%;
}
.right-router-item {
  height: 40px;
  margin-bottom: 10px !important;
  padding: 0px 10px;
}

.right-panel-toggle {
  position: absolute !important;
  bottom: 25%;
}
.right-nav-selected-route {
  background-color: #F0F6FB !important;
  height: 40px !important;
  border-radius: 5px !important;
  padding-left: 10px !important;
}

.right-nav-selected-route-highlighter {
  background:#002882;;
  border-radius: 5px 0px 0px 5px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 32px;
  width: 3px;
  left: 0px;
  position: absolute;
}
.xpheno-rightNavBar nav li >.MuiButtonBase-root:hover{
  background-color: transparent;
}
.xpheno-rightNavBar .addPageRouterIcon{
  width: 14px !important;
  height: 14px !important;
  /* margin-left: 3px; */
}
.xpheno-rightNavBar .addPageRouterIcon path{
  fill: #0e3489;
}
.notification-count-cls{
    position: absolute;
    width: 20px;
    height: 20px;
    left: 14px;
    top: -5px;
    background: #F34334;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 8px;
    padding: 1px;
}
.disableRightLink{
  pointer-events: none;
  opacity: 0.5;
}