.listTreeViewTransferBlk{
    display: flex;
    justify-content: space-between;
    > .MuiBox-root{
        width: 100%;
        border: 1px solid #d4d4d4;
        border-radius: 10px;
        overflow: hidden;
        h5{
            margin: 0px;
            background: #f3f3f7;
            padding: 10px 20px;
            font-weight: 500;
            color: gray;
            text-transform: uppercase;
            font-size: 12px;
        }
        > .ant-input-group-wrapper{
            position: relative;
            &::before{
                content: "";
                width: 17px;
                height: 16px;
                background-image: url(../../../assets/icons/search.svg);
                position: absolute;
                z-index: 9;
                left: 10px;
                top: 16px;
            }
            input{
                border: none;
                font-size: 16px;
                padding: 10px 10px 10px 40px;
                &::placeholder{
                    color: #b2b2b2;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            .ant-input-group-addon{
                display: none;
            }
        }
        .listTreeViewCheckAll{
            background: #fafafa;
            padding: 10px 20px;
            border-top: 1px solid #e0e0e0;
        }
        > .ant-tree{
            padding-top: 20px;
            height: calc(100vh - 520px);
            overflow: auto;  
            .ant-tree-node-content-wrapper{
                background-color: transparent;
                cursor: auto;
            }                      
        }
    }
    .listTreeAction{
        width: 150px;
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        > span{
            background: black;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            svg{
                color: #fff;
                font-size: 24px;
                cursor: pointer;
            }
        }        
    }
    .listTreeViewTransferLeft{
        > .ant-tree{
            .treeViewDisable{
                .ant-tree-checkbox,.ant-tree-node-content-wrapper{
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
        }
    }
    .ant-tree-treenode{
        margin-bottom: 5px;
        .ant-tree-title{
            // font-weight: 500;
        }
    }
    // .ant-tree-treenode.ant-tree-treenode-switcher-open{
    //     .ant-tree-title{
    //         font-weight: 500;
    //     }
    // }
}
.searchKeyFound .ant-tree-node-content-wrapper .ant-tree-title{
    color: #f50;
    font-weight: 500;
  }