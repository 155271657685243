.MuiOtpInput-Box .MuiInputBase-root {
    border-radius: 10px;
    height: 75px;
    width: 85px;
}
.MuiOtpInput-TextField .Mui-focused fieldset{
    border-radius: 10px !important;
    border: 1px solid #c8d0d4 !important; 
}

.MuiOtpInput-Box {
    margin-bottom: 2rem;
}

.otp-form-container-grid {
    max-width: 70rem;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32), 0px 4px 8px rgba(91, 104, 113, 0.24);
    border-radius: 10px;
    /* height: 33rem; */
}