.notification-container{
    height: 100%;
    .notify-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 20px;
        border-bottom: 1px solid #E5E8EB;

        .header-text{
            color: #000;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
        }
    }

    .toggle-container{
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .toggle-switch{
            display: flex;
            align-items: center;
            .MuiSwitch-root{
                height: 14px;
                width: 30px;
                margin-right: 5px;
                padding: 0 2px 0 0;
                .MuiSwitch-track{
                background-color: #b0bec5;
                opacity: 1;
                }
                .MuiButtonBase-root{
                padding: 1px;
                .MuiSwitch-thumb{
                    width: 12px;
                    height: 12px;
                    background-color: #eceff1;
                    box-shadow: none;
                }
                &.Mui-checked{
                    transform: translateX(14px);
                    .MuiSwitch-thumb{
                    background-color: #0E94F4;
                    }
                    ~ .MuiSwitch-track{
                    background-color: #0E94F4;
                    opacity: 0.3;
                    }
                }
                }
            }
        }

        .primary-text{
            color: #000;
            font-size: 14px;
            font-weight: 400;
        }

        .markasread-text{
            color: #002882;
            font-size: 13px;
            font-weight: 400;
            cursor: pointer;
        }
    }

    .notify-warpper{
        height: calc(100vh - 150px);
        overflow-y: scroll;
        .keys-data-wrapper{
            padding: 8px 0;
            .keys-text{
                color: #727376;
                font-size: 15px;
                font-style: normal;
                padding: 0 0 0 20px;
                font-weight: 500;
                text-transform: capitalize;
            }
            .nitfy-data{
                padding: 2px;
                display: flex;
                flex-direction: column;
                gap: 3px;
                .list-wrapper{
                    margin: 0;
                    display: flex;
                    gap: 10px;
                    padding: 10px 15px;
                    cursor: pointer;
                    p{
                        margin: 0;
                        font-size: 14px;
                    }
                    span{
                        font-size: 12px;
                        color: #727376;
                    }
                    &.active{
                        background-color: #F0F6FB;
                        border-radius: 6px;
                    }
                    .content-row-cls{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        .active-blue-cls {
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background: #0E94F4;
                            }
                    }
                }
                .unknownCandidate{
                    cursor: default;
                }
                .list-wrapper{
                    .list-data-container{
                        .statusApprover{
                            background-color: #E8F5E9;
                            color: #2BAD63;
                            padding: 5px 10px;
                            border-radius: 5px;
                            margin-left: 5px;
                        }
                        .statusReject{
                            background-color: #FEECEB;
                            color: #F34334;
                            padding: 5px 10px;
                            border-radius: 5px;
                            margin-left: 5px;
                        }
                        .statusPending{
                            background-color: #FCF4E5;
                            color: #E09000;
                            padding: 5px 10px;
                            border-radius: 5px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
        .noNotifications {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #727376;
    
            .nodata-text {
                color: #727376;
                text-align: center;
                font-family: Sora;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 14px;
            }
    }
    .notifull{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        flex-direction: column;
        .font-cls {
                color: #727376;
                text-align: center;
                font-family: Sora;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                margin-top: 15px;
                /* 153.846% */
            }
    }
}
.rightPanelContainer{
    width: 360px;
    height: 100vh;
    position: sticky;
    top: 0;
    overflow: hidden;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    z-index: 9;
}
.callLog-container{
    .MuiTabs-root{
        padding: 10px;
        .MuiTabs-scroller{
            background: #f3f3f7;
            border-radius: 10px;
            button{
                font-size: 12px;
                text-transform: capitalize;
                min-width: 80px;
                min-height: auto;
                color: #000;
                position: relative;
            }
            button.Mui-disabled{
                opacity: 0.5;
            }
            button.Mui-selected{
                color: #002882;
                background: #fff;
                border: 1px solid #E5E8EB;
                border-radius: 10px;
                &::before{
                    content: "";
                    position: absolute;
                    width: calc(100% - 15px);
                    height: 6px;
                    background-color: #002882;
                    top: -3px;
                    border-radius: 10px;
                }
            }
            .MuiTabs-indicator{
                display: none;
            }
        }
    }
}
.callLogScrollSect{
    height: calc(100vh - 50px);
    overflow: auto;
    padding: 24px;
    .todayTitle{
        margin: 0px;
        font-weight: 500;
        font-size: 12px;        
    }
    .candidateMissed{
        color: red;
    }
    .unauthorizedText{
        font-size: 14px;
        position: absolute;
        top: 45%;
        left: 90px;
    }
    .callLogCardSect{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 0px;
        cursor: pointer;
        .callLogProfile{
            display: flex;
            align-items: center;
            gap: 10px;            
            .MuiAvatar-root{
                width: 35px;
                height: 35px;
            }
            .candidateNameCircle{
                background: #f0f6fb;
                width: 40px;
                height: 40px;
                font-size: 14px;
                border-radius: 30px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                text-transform: uppercase;
            }
            .callLogProfileContent{
                h4{
                    font-size: 13px;
                    font-weight: 500;            
                    margin: 0px;
                    display: flex;
                    gap: 5px;
                    span.name{
                        max-width: 100px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                    }
                    span.phone{
                        font-size: 13px;
                        font-weight: 500;            
                        margin: 0px;
                    }
                }
                h4.noGap{
                    gap: 0px;
                }
                p{
                    margin: 0px;
                    font-size: 12px;
                    color: #727376;
                }
            }
        }
    }
}
.callLogListUnknow{
    cursor: default !important;
}
.allNotificationView{
    .notify-header{
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 20px;
        height: 50px;
        .searchSect{
            display: flex;
            align-items: center;
            gap: 5px;
            input{
                border: none;
                width: 150px;
                &::placeholder{
                    color: #B3B5B7;
                }
                &:focus-visible{
                    outline: none;
                }
            }
        }
    }
    .notificationBodySect{
        background-color: #fff;
        border-radius: 10px;
        .toggleActionSect{
            .MuiToggleButtonGroup-root{
                gap: 15px;
                margin: 20px 15px 15px 20px;
                .MuiButtonBase-root{
                    border: 1px solid #E5E8EB;
                    background: #fff;
                    border-radius: 10px;
                    padding: 6px 10px;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: #727376;
                    span{
                        color: #000;
                        margin-left: 5px;
                    }
                }
                .MuiButtonBase-root.Mui-selected{
                    background: #002882;
                    color: #fff;
                    span{
                        color: #fff;
                    }
                }
            }
        }
        .toggle-container{
            .markasread-text{
                display: flex;
                align-items: center;
                gap: 5px;
                svg{
                    font-size: 16px;
                }
            }
        }        
        .notify-warpper{
            height: calc(100vh - 270px);
            .keys-text{
                font-size: 14px;
            }
            .nitfy-data{
                padding: 0px 15px;
                margin-top: 15px;
                > .list-wrapper.active{
                    position: relative;
                    &::after{
                        content: "";
                        background-color: #0E94F4;
                        width: 5px;
                        height: 90%;
                        position: absolute;
                        top: 5%;
                        left: 0px;
                        border-radius: 0px 10px 10px 0px;
                    }                    
                }
                .list-wrapper.active + .list-wrapper.unread{
                    border-top: 0px;
                    .keys-text{
                        font-size: 14px;
                    }
                }
                > .list-wrapper.unread{
                    border-top: 1px solid #E6EDF4;
                    border-radius: 0px;
                }                
            }
        }
    }
    .backToTopSect{
        background: #fff;
        padding: 15px;
        margin-top: 15px;
        border-radius: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        p{
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            color: #0E94F4;
            font-size: 14px;
        }
    }
}
.viewAllNotificationSect{
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
    font-size: 14px;
    color: #002882;
    font-weight: 600;
    gap: 5px;
    cursor: pointer;
    svg{
        font-size: 18px;
    }
}