body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding: 0px  !important;  to stop padding from mui dropdown
  overflow:initial !important; to stop padding from mui dropdown */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.disable-pointer-events,
.disableBody{
  pointer-events: none;
}
@media screen and (min-width:1600px) {
  body .MuiDrawer-docked{
    display: flex;
    flex: 0 0 auto !important;
  }
  body .appbar {
    width: 1290px;
    left: calc((100% - 50%) - 800px);
  }
  body .appbar.headerShrink{
    width: 1470px;
    left: calc((100% - 50%) - 800px);
  }
  .xpheno-rightNavBar .MuiPaper-root{
    right: calc((100% - 50%) - 800px);
  }
  .rightToggleMenu .MuiPaper-root{
    left: calc((100% - 50%) - 800px);
  }
  body{
    max-width: 1600px;
    margin: 0 auto !important;
  }
}
.mandate-profile .empty-data-card{
  position: relative;
  margin-top: 100px;
}
.empty-data-card {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-css > span:nth-child(1){
  margin-top: 1em;
  margin-bottom: 0em;
  display: flex;
}
.content-css > span p{
  margin: 0px;
}
.unAuthorizedMessage{
  background: #f34334;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.MuiMenu-list[aria-labelledby="primarySkills-label"],
.MuiMenu-list[aria-labelledby="secondarySkills-label"]{
  width: 250px !important;
}
.mandateTitleSect{
  display: flex;
  gap: 15px;
  align-items: center;
}
.mandateTitleSect p{
  margin-bottom: 4px !important;  
}
.mandateTitleSect p:nth-child(2){
  font-weight: 400 !IMPORTANT;
  font-size: 13px !important;
  color: #000;
  background: #fff;
  padding: 4px 7px;
  border-radius: 5px;
  margin: 0px !important;
  border: 1px solid #bfbfbf;
}
.scheduleOffScreen{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scheduleOffSection{
  display: flex;
  height: 100vh;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.scheduleOffSection .scheduleOffScreen > img{
  margin-bottom: 50px;
}
.scheduleOffSection .scheduleOffScreen h4{
  font-size: 24px;
  margin: 0px;  
}
.scheduleOffSection .scheduleOffScreen p{
  margin: 0px;
  font-size: 15px;
  color: #727376;
  margin-top: 5px;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons a.introjs-nextbutton{
  background: #002882;
  color: #fff;
  text-shadow: none;
  border-radius: 5px;
  box-shadow: none;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons a.introjs-prevbutton{
  background: #E5E8EB;
  color: #000;
  text-shadow: none;
  border-radius: 5px;
  box-shadow: none;
}
.introjs-helperLayer{
  box-shadow: #ff5500 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
.introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltip-header a.introjs-skipbutton{
  background: #002882;
  border-radius: 40px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 16px;
  color: #fff;
  top: 15px;
  right: 10px;
  padding: 0px;
}
.schedulerSect .breadcrumb{
  margin-bottom: 0px !important;
}
.schedulerSect .mbsc-font{
  font-family: Sora !important;
  font-weight: 400;
}
.schedulerSect .mbsc-calendar-header .mbsc-calendar-controls .md-seach-header-bar label{
  width: 350px;
}
.schedulerSect .mbsc-calendar-header .mbsc-calendar-controls .cal-header-picker .mbsc-segmented{
  background-color: #f7f7f7;
  border-radius: 0px;
}
.schedulerSect .mbsc-calendar-header .mbsc-calendar-controls .cal-header-picker .mbsc-segmented label .mbsc-segmented-button{
  border: 1px solid #D9D9D9;
  padding: 2px 15px;
  background: #fff;
}
.schedulerSect .mbsc-calendar-header .mbsc-calendar-controls .cal-header-picker .mbsc-segmented label:nth-child(1) .mbsc-segmented-button{
  border-radius: 5px 0px 0px 5px;
}
.schedulerSect .mbsc-calendar-header .mbsc-calendar-controls .cal-header-picker .mbsc-segmented label:nth-child(5) .mbsc-segmented-button{
  border-radius: 0px 5px 5px 0px;
}
.schedulerSect .mbsc-calendar-header .mbsc-calendar-controls .cal-header-picker .mbsc-segmented label.mbsc-segmented-item-checked .mbsc-segmented-button{
  border-color: #002882;
  background: #002882;
  color: #fff;
}
.schedulerSect .mbsc-calendar-header .mbsc-calendar-controls .cal-header-nav button{
  font-size: 12px;
}
.schedulerSect .mbsc-calendar-body .mbsc-calendar-month-title{
  font-size: 16px;
}
.schedulerSect .mbsc-calendar-body .mbsc-calendar-day-text{
  font-size: 12px;
}
.backToOwner{
  margin: 0px;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 10px;
  font-weight: 500;
  color: #002882;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  svg{
    width: 18px;
  }
}