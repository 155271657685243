.gpt-dialog-container {
  padding: 20px;
  width: 100%;
  // height: 100%;
  height: 90vh;

  .dialog-header {
    padding: 0 0 15px;
    border-bottom: 1px solid #cfd1d2;

    .icon-wrap{
      display: flex;
      gap: 10px;
      padding-bottom: 10px;
    }

    .header-text {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }
  }

  .dialog-chat-container {
    padding: 20px 15px;
    height: 65%;
    overflow-y: auto;

    .chat-wrapper {
      .ai-chat-wrap {
        display: flex;
        gap: 4px;
        width: 90%;
        padding-top: 15px;

        .chat-text-wrap {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .msg-cont {
          display: flex;
          .answer-conatiner {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .chat-text {
              padding: 10px;
              color: #000;
              // font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              border-radius: 8px;
              background: #f3f3f7;
              position: relative;
            }
            .copy-icon-container {
              width: 15px;
              margin-top: 10px;
              cursor: pointer;              
            }
          }

          .chat-btn {
            color: #0e94f4;
            // font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            border-radius: 3px;
            border: 1px solid #0e94f4;
            padding: 8px 10px;
            cursor: pointer;
          }
        }
      }
      .user-text-wrapper {
        width: 100%;
        padding-top: 15px;
        display: flex;
        justify-content: flex-end;

        .user-text {
          padding: 10px;
          border-radius: 8px;
          background: #ff6e00;
          color: #fff;
          // font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          width: fit-content;
          max-width: 95%;
        }
      }
    }
  }

  .footer-chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cont-hide{
      visibility: hidden;
    }

    .regenerate-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      padding: 10px 0;
      width: 80%;

      cursor: pointer;

      svg {
        width: 15px;
        height: 15px;
      }
      .regen-text {
        color: #002882;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
      }
    }

    .input-wrapper {
      width: 80%;
      padding-bottom: 20px;
      .text-field {
        width: 100%;

        .MuiInputBase-formControl {
          border-radius: 6px;
          background: #fff;
          box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.12);
        }
        .MuiInputBase-root {
          // font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .footer-hint {
      width: 80%;
      color: #444654;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
    }
  }
  .date-text-cls{
color: #727376;
font-family: Sora;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */
margin-left: 2px;
  }
  .chat-col-cls{
    display: flex;
    flex-direction: column;
  }
    .load-text-cls {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .font-cls{
        font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: right;
      color: #0E94F4;
      margin-right: 4px;
      }
    }
}
