.view-wrap {
    padding-right: 15px;
    overflow:scroll;
    height: 70vh;
    .list-detail-wrapper {
        padding: 10px;
        margin: 15px 0 15px 0;
        display: flex;
        flex-direction: column;
        gap: 4px;
        border: 1px solid #CFD1D2
        ;
        border-radius: 8px;
        .list-query-headbtn {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .list-status-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
    
            .RPA-btn {
                border: 1px solid #002882 !important;
            }
    
            .status-btn {
                border: 1px solid #D9D9D9;
                gap: 4px;
            }
    
            .inprogress-btn svg circle {
                stroke: #0E94F4;
            }
    
            .failed-btn svg circle {
                stroke: #F34334;
            }
    
            .completed-btn svg circle {
                stroke: #2BAD63;
            }
    
            .queued-btn svg circle {
                stroke: #f4a236
            }
        }
    }
    .keyword-boolen{
        margin: 15px 0 15px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
    }
    .initatedIndicator {
        margin: 15px 0px 15px 0;
        padding: 5px 10px 5px 10px;
        background-color:#F3F3F7;
        border-radius: 8px;
        .notice-text{
            font-size: 14px;
            line-height: 23px;
            color: #727376;                ;
            .remaining-time{
                color: #002882;
            }
        }
    }
    .section-heading{
        font-size: 12px;
        line-height: 23px;
        color: #727376; 
    }
    .location-wrap{
        display: grid;
        grid-template-columns: 50% 50%;
        flex-direction: row;
        justify-content: space-between;
    }
    .exp-wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .employDetails-wrap{
        margin-top: 15px;
        .current-company{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;
        }
    }
    .blue-heading {
        color: #002882;
            &::before {
                width: 0 !important;
            }
            span{
                padding-left: 0;
            }
    }
    .subtext{
        font-size: 14px;
        word-wrap: break-word;
    }
    .keyword-section{

            .keywords{
                font-size: 14px;
                margin: 10px;
                padding: 5px 10px 5px 10px;
                background-color: #EFEFEF; 
            }   
    }
}