.linkedin-profie-wrap {
    margin: 10px 0 0 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .head {
        font-size: 14px;
        font-weight: 600;
    }
   

    .card-boxes-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;

        .card-box {
            border: 1px solid #E5E8EB;
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            overflow: hidden;

            .upper {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;

                img {
                    padding: 2px 6px;
                    border-radius: 100%;
                    background: #E5E8EB;
                    height: 30px;
                    width: 30px;
                    margin-left: 5px;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    margin: 10px 0 10px 0;

                    span {
                        color: #727376;
                        font-size: 12px;
                    }
                }
            }

            .lower {
                background-color: #F3F3F7;
                font-size: 13px;
                padding: 5px 5px 5px 5px;

                span {
                    color: #727376;
                }
            }
        }
    }

    .personal-details {
        border-bottom: 1px solid #E5E8EB;
        padding-bottom: 10px;

        .candidate-name {
            font-size: 18px;
            font-weight: 600;
        }

        .detail-wrap {
            display: flex;
            flex-direction: row;
            gap: 15px;

            .each-details {
                gap: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    width: 17px;
                    height: 17px;
                    color: #727376;
                }

                span {
                    font-size: 13px;
                    color: #727376;
                    margin-right: 5px;
                }
            }
        }
    }

    .basic-info {
        border-bottom: 1px solid #E5E8EB;
        padding-bottom: 10px;

        .details {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            align-items: center;

            .details-every {
                margin-top: 10px;
                font-family: "Sora";
                display: flex;
                flex-direction: column;
                gap: 8px;
                min-width: 150px;

                .value {
                    color: #000000;
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: -10px;
                    &.urls{
                        word-break: break-all;
                    }
                }

                .label {
                    color: #727376;
                    font-size: 12px;
                    font-weight: 400;
                }

            }
        }
    }

    .description {
        border-bottom: 1px solid #E5E8EB;
        padding-bottom: 10px;

        .content {
            font-size: 14px;
            margin: 0;
        }
    }

    .experience {
        border-bottom: 1px solid #E5E8EB;
        padding-bottom: 10px;

        .each-experience-card {
            position: relative;

            .svgcontainer {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 4px;
                z-index: 20;
                background-color: #F3F3F7;

                img {
                    padding: 8px 8px 5px 8px;
                }
            }

            .svgcontainer-active {
                position: absolute;
                top: 0;
                left: 0;
                background-color: #E3F4EB;
                border-radius: 4px;
                z-index: 20;

                img {
                    padding: 8px 8px 5px 8px;
                }
            }

            .experience-content,
            .experience-content-last {
                border-left: 1px dashed #B3B5B7;
                margin-left: 17px;
                padding-left: 20px;
                margin-top: 5px;
                padding-top: 8px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .exp-title {
                    display: flex;
                    flex-direction: column;

                    .head {
                        font-weight: 500;
                    }

                    .date {
                        font-size: 13px;
                        color: #727376;
                    }
                }

                .description {
                    font-size: 12px;
                }

                .rolesandresp {
                    .head {
                        font-size: 12px;
                        color: #727376;
                    }

                    ul {
                        padding-left: 20px;
                        list-style: url("../../../../assets/icons/Polygon.svg");

                        li {
                            font-size: 13px;
                        }
                    }
                }

                .on-page {
                    .head {
                        font-size: 12px;
                        color: #727376;
                    }

                    ul {
                        padding-left: 20px;
                        list-style: url("../../../../assets/icons/Polygon.svg");

                        li {
                            font-size: 13px;
                        }
                    }
                }

                .off-page {
                    .head {
                        font-size: 12px;
                        color: #727376;
                    }

                    ul {
                        padding-left: 20px;
                        list-style: url("../../../../assets/icons/Polygon.svg");

                        li {
                            font-size: 13px;
                        }
                    }
                }

            }

            .experience-content-last {
                border: none;
            }
        }
    }

    .education {
        border-bottom: 1px solid #E5E8EB;
        padding-bottom: 10px;

        .academics {
            font-family: 'Sora';

            .degree {
                color: #002882;
                font-size: 14px;
                font-weight: 500;

            }

            .year {
                color: #727376;
                font-size: 13px;
                font-weight: 400;
                margin-top: -10px;
            }

            .clg {
                color: #000000;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    .certifications {
        border-bottom: 1px solid #E5E8EB;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .certificate-container {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .certification-box {
                border: 1px solid #D9D9D9;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 41px;
                height: 41px;

                img {
                    height: 30px;
                    width: 30px;
                }
            }

            .certification-details {
                display: flex;
                flex-direction: column;

                .course {
                    font-size: 14px;

                    img {
                        width: 10px;
                        margin-left: 2px;
                    }

                    a {
                        color: #0E94F4;
                        text-decoration: none;
                        margin-left: 5px;
                    }
                }

                .date {
                    font-size: 14px;
                    color: #727376;
                }
            }
        }
    }

    .skills {
        border-bottom: 1px solid #E5E8EB;
        padding-bottom: 10px;

        .skill-wrap {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

            .content {
                font-size: 13px;
                font-weight: 400;
            }

        }

    }

    .languages {
        border-bottom: 1px solid #E5E8EB;
        padding-bottom: 10px;

        .content {
            font-size: 13px;
        }

    }
    @media (max-width: 760px) {
        .personal-details {
            .detail-wrap {
                flex-wrap: wrap;
            }
        }
    }
}
 .nodata { 
    width: auto;
    display: flex;
    justify-content: center;
 }