
.custom-tooltip {
    background-color: #333;
    color: #fff;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.parentTree:hover .custom-tooltip {
    display: block;
}



.hiringDemandBgBlk{
    background-color: #fff;
    padding: 10px 30px 0px 30px;
    border-radius: 10px;
    // margin-top: 20px;
    .hiringDemandTitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hiringDemandTitleLeft{
            display: flex;
            align-items: center;
            gap: 5px;
            p{
                margin: 0px;
                display: flex;
                align-items: center;
                color: #002882;
                border: 1px solid #002882;
                padding: 6px 12px;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
                svg{
                    transform: rotate(90deg);
                    width: 18px;
                }
            }
            > svg{
                color: gray;
                font-size: 22px;
                cursor: pointer;
            }
        }
    }
    .hiringDemand{
        > ul, > ul > li > ul {
            padding: 0px;
            list-style: none;
            display: flex;
            position: relative;
            justify-content: space-between;
            &::before{
                content: "";
                width: 100%;
                height: 1px;
                background-color: #D9D9D9;
                position: absolute;
                top: 40px;
            }
            > li{
                display: flex;
                position: relative;
                align-items: baseline;        
                // cursor: pointer;    
                > div{
                    .childTree{
                        width: 100px;
                        height: 80px;
                        position: relative;
                        &::before{
                            content: "";
                            width: 1px;
                            height: 56px;
                            background-color: #D9D9D9;
                            position: absolute;
                            top: 5px;
                            left: 48px;
                        }
                        > svg{
                            transform: rotate(180deg);
                            position: absolute;
                            top: -15px;
                            right: 40px;
                            color: #002882;
                        }
                        .childTreeBlk{
                            position: absolute;
                            // width: 350px;
                            left: -259px;
                            top: 20px;
                            ul{
                                padding: 0px;
                                list-style: none;
                                display: flex;
                                position: relative;
                                justify-content: space-between;
                                gap: 30px;
                                &::before{
                                    content: "";
                                    width: 100%;
                                    height: 1px;
                                    background-color: #D9D9D9;
                                    position: absolute;
                                    top: 40px;
                                }
                                > li{
                                    display: flex;
                                    z-index: 999;
                                    position: relative;
                                    align-items: baseline;
                                }
                                > li.disbaleArchived{
                                    opacity: 0;
                                    pointer-events: none;
                                }
                            }
                            .parentTree{
                                border: 1px solid #A15F24;  
                                background-color: #FAF3ED;
                                width: 100px;
                                min-height: 80px;
                                cursor: pointer;
                                p{
                                    font-size: 12px;
                                    color: gray;
                                    font-weight: 500 !important;
                                    padding: 0px 5px;
                                    cursor: pointer;
                                }
                                span{
                                    cursor: pointer;
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: #000;
                                }
                            }
                        }
                    }
                    .parentTree{
                        width: 100px;
                        min-height: 80px;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        h5{
                            margin: 0px;
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 16px;
                            margin-top: 8px;
                        }
                        p{
                            margin: 0px;
                            display: flex;
                            gap: 5px;
                            font-size: 14px;
                            align-items: center;
                            letter-spacing: -1px;
                            font-weight: 600;
                            svg{
                                width: 14px;
                            }
                        }
                        .decrementClass{
                            color: red;
                        }
                        .incrementClass{
                            color: #2bad63;
                        }
                        .equalentClass{
                            color: #1540B6;
                        }
                        .launchIcon{
                            background: #fff;
                            color: #002882;
                            width: 27px;
                            height: 27px;
                            padding: 5px;
                            border-radius: 5px;
                            border: 1px solid gray;
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            display: none;
                        }                        
                    }
                    .parentTree.firstCard{
                        width: 200px !important;
                        min-height: 80px;
                    }
                }
                .parentCardSelected .parentTree{
                    cursor: pointer;
                    border: 1px solid #002882;  
                    background-color: #edf5fa;
                }
            }
            li.disableCard{
                opacity: 0;
                pointer-events: none;
            }
        }        
        .hiringDemandHeader{
            &::before{
                display: none;
            }
            > li {
                cursor: default;
                .parentTree.firstCard{
                    background: transparent;
                    border: none;
                    font-size: 16px;
                    text-transform: capitalize;
                    align-items: baseline;
                    justify-content: center;
                }
                .parentTree{
                    background-color: #f3f3f7;
                    width: 100px;
                    min-height: 80px;
                    text-transform: uppercase;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                    border-radius: 10px;
                    border: none;
                    font-weight: 600;
                    span{
                        position: absolute;
                        width: 90%;
                        height: 3px;
                        border-radius: 0px 0px 10px 10px;
                        top: 1px;
                        cursor: pointer;
                    }
                }
            }            
        }
    }
    .hiringDemandBody{
        display: table !important;
        width: 100%;
        margin: 0px;
        > li > ul{
            width: 100%;
            margin-bottom: 30px;
            > li{
                .parentTree.firstCard{                    
                    border: none;
                    font-size: 18px;
                    text-transform: capitalize;
                    text-align: left;
                    justify-content: left;
                    border-radius: 10px;
                    .firstCardSect{
                        display: flex;
                        align-items: center;
                        padding: 10px 15px;
                        gap: 15px;
                        width: 100%;
                        > svg{
                            background: #fff;
                            padding: 15px;
                            width: 84px;
                            height: 60px;
                            border-radius: 10px;                        
                        }
                        .firstCardSectContent{
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            width: 100%;
                            h4{
                                margin: 0px;
                                font-size: 24px;
                                line-height: 16px;
                                margin-top: 8px;
                            }
                            p{
                                margin: 0px;
                                font-size: 14px;
                            }
                            .leftCardArrowSect{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                margin-top: 8px;
                                svg{
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
                .parentTree.firstCard.client{
                    background: #fcf4e5;
                }
                .parentTree.firstCard.mandate{
                    background: #f0f6fb;
                }
                .parentTree.firstCard.JobOrders{
                    background: #f6efff;
                }
                .parentTree{
                    background-color: #fff;
                    border: 1px solid #D9D9D9;
                    font-size: 18px;
                    text-transform: capitalize;
                    text-align: center;
                    justify-content: center;
                    border-radius: 10px;
                    align-items: center;
                    display: flex;                    
                }                
                > div > .parentTree{
                    &:not(.firstCard){
                        &:hover{
                        background-color: #F0F6FB;
                        align-items: baseline;
                        padding-left: 10px;                        
                        .launchIcon{
                            display: block;
                        }
                    }
                }
            }
            }
            li.firstCardLi{
                cursor: default;
            }
            li.hiringBody1{
                .childTree{
                    .childTreeBlk{
                        left: -129px !important;
                    }
                }
            }
            li.hiringBody7{
                .childTree{
                    .childTreeBlk{
                        left: -389px !important;
                    }
                }
            }
            li:not(.firstCardLi){
                .parentTree {
                    cursor: pointer;
                }
            }
        }        
    }
}


// dashboard list header part css

.hiringInfoSection{
    ul{
      margin: 0px;
      list-style: none;
      display: inline-flex;
      gap: 10px;
      background: #c1a5102e;
      border-radius: 5px;
      padding: 2px 12px;
  
      li{
        list-style: none;
        padding: 0px;
        display: inline-flex;
        gap: 10px;
        align-items: center;    
        font-size: 13px;  
        span.circle{
          width: 7px;
          height: 7px;
          display: flex;
          background: #bc9a23;
          border-radius: 10px;
        }
        span.number{
          color: #2250d3;
          font-weight: 600;
        }
      }
    }
  }
  .hiringExport{
    display: flex;
    gap: 5px;
    svg{
      width: 15px;
    }
  }
  .dashboardDetailHeaderTitle{
    span{
        font-size: 13px;
    }
  }
  .lostOpptCard {
    svg{
        position: absolute;
        top: 3px;
        right: 5px;
        width: 14px;
    }
  }