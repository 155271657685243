.basic-detail-main {
    .basic-detail-sec-main {
        border-bottom: 1px solid #E5E8EB;

        last-child {
            border-bottom: none;
        }

        .detail-header {
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            font-family: 'Sora';
        }
        .linkedin{
            color:#FF6E00;
        }
        .details {
            grid-template-columns: 45fr 30fr 30fr 30fr 30fr;
            display: grid;
            align-items: flex-start;
            word-wrap: break-word;
            gap: 10px;


            .details-every {
                font-family: 'Sora';
                

                .value {
                    color: #000000;
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: -10px;
                }
                .ctc{
                    svg{
                        font-size: 13px;
                    }
                }

                .label {
                    color: #727376;
                    font-size: 12px;
                    font-weight: 400;
                }

            }
        }

        .academics {
            font-family: 'Sora';

            .degree {
                color: #002882;
                font-size: 14px;
                font-weight: 500;
                
            }

            .year {
                color: #727376;
                font-size: 13px;
                font-weight: 400;
                margin-top: -10px;
            }

            .clg {
                color: #000000;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .detail-resume {
            display: flex;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            color: #000000;
            font-size: 14px;
            .insert-file {
                color: #FF6E00;
                border: 1px solid #E5E8EB;
                border-radius: 10px;
                padding: 4px;
                width: 30px;
                height: 30px;
            }

            .download-file {
                color: #727376;
                margin-left: 10px;
                width: 30px;
                height: 30px;
            }
        }
    }

}
.audit-log-table{
    width: 100%;
    .d-flex{
      display: flex;
      align-items: center;
      gap: 10px;
    }
    thead{
      tr{
        th{
          padding: 12px;
          color: #727376;
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
          line-height: normal;
          background-color: #F3F3F7;
          border-bottom: unset;
        }
        th:nth-child(1){
          border-top-left-radius: 5px;
          padding-left: 25px;
        }
        th:nth-child(4){
          border-top-right-radius: 5px;
        }
      }
    }
    tbody{
      border: 1px solid #E5E8EB;
      tr{
        td{
          padding: 10px 12px;
          line-height: normal;
        }
        td:nth-child(1){
          padding-left: 25px;
        }
      }

      .user-detail{
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
      .ellipsisText1{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .primary-text{
        color: #000;
        font-size: 14px;
        font-weight: 400;
      }
      .secondary-text{
        color: #727376;
        font-size: 14px;
        font-weight: 400;
      }
      .text-ellips{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis !important;
        width: 148px;
        display: block;
      }
    }
  }
  .socialLinkList{
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    > div{
        border: 1px solid #E5E8EB;
        padding: 10px;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            float: left;
        }
    }
  }