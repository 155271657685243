.bulk-uploader-result-page {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(9, 10, 12, 0.5058823529);
  align-items: center;
  justify-content: center;

  .add-skil-box {
    width: 360px;
    height: 600px;
    background-color: #f1f3f5;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgb(47 47 49 / 20%);
    border-radius: 20px;
    background: #ffffff;
    border-radius: 10px;
    .header-wrapper {
      font-weight: 600;
      display: flex;
      margin-top: 25px;
      align-items: center;
      .close-icon {
        position: relative;
        cursor: pointer;
        right: -300px;
        top: 2px;
        border-radius: 12px;
        width: 20px;
        height: 20px;
      }
    }
    .skill-wrapper {
      display: block;
      overflow: auto;
      height: 450px;
    }
    .brand-wrapper {
      font-size: 14px;
      font-weight: 400;
      display: flex;
      gap: 10px;
      padding: 0px 20px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      //   height: 68px;
      .selected {
        color: #fb6c00;
      }
      .skill-style {
        // position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 100px;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 40px;
        left: 623px;
        top: 136px;
        font-family: 'Sora';
        font-weight: 400;
        background: #efefef;
        border-radius: 8px;
        word-break: break-all;
      }

      .skill-style-selected {
        // position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 100px;
        padding-left: 20px;
        padding-right: 20px;
        min-height: 40px;
        left: 623px;
        top: 136px;
        font-family: 'Sora';
        font-weight: 600;
        background: #fb6c00;
        border-radius: 8px;
        word-break: break-all;
      }
      .brand {
        font-weight: 600;
        margin-left: 20px;
        margin-top: 10px;
      }
      .search-box {
        width: 215px;
        border: 1px solid #c1c1c1;
        border-radius: 6px;
        height: 33px;
        display: flex;
        align-items: center;
        padding-left: 17px;
        color: #c1c1c1;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        .search-icon {
          margin-left: -9px;
        }
      }
    }
    .brands-img-wrapper {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      .brand-img {
        width: 80px;
        height: 100%;
        align-items: center;
        justify-content: center;
        border: 1px solid #c1c1c1;
        margin-right: 3px;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        p {
          text-transform: capitalize;
        }
        img {
          width: 40px;
          height: 34px;
          margin-bottom: 13px;
        }
      }
      .active-brand-img {
        width: 80px;
        height: 100%;
        align-items: center;
        justify-content: center;
        border: 1px solid rgb(248 139 0);
        margin-right: 3px;
        border-radius: 7px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        background-color: #fff;
        color: rgb(248 139 0);
        p {
          text-transform: capitalize;
        }
        img {
          width: 40px;
          height: 34px;
          margin-bottom: 13px;
        }
      }
      .brand-img:hover {
        background-color: rgb(248 139 0);
        color: #f1f3f5;
      }
      // .brand-img-active{
      //     background-color: #FB6C00;
      //     color: #F1F3F5;
      // }
    }
    .choose-product {
      font-weight: 600;
      width: 131px;
      margin-left: 20px;
      margin-top: 17px;
    }
    .table {
      display: flex;
      height: 307px;
      flex-direction: column;
      overflow-y: auto;
      align-items: center;
      width: 100%;
      .header-1 {
        position: sticky;
        width: 100%;
        top: 0 !important;
        background: #fff;
        th {
          color: #fb6f05;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          border-bottom: 1px solid burlywood;
          &:nth-child(2),
          &:nth-child(1) {
            width: 100px;
          }
        }
      }
      .tbody {
        padding-top: 100px;
        overflow: auto;

        .add-product-row {
          .products-data {
            padding: 5px;
            img {
              width: 30px;
              height: 30px;
            }
          }
        }
        tr {
          .products-data {
            .checkbox {
              justify-content: center;
              margin: 0;
            }
          }
        }
      }
    }

    .button-wrapper {
      display: flex;
      position: absolute;
      right: 0;
      margin-right: 32px;
    }
    .button-wrapper1 {
      display: flex;
      margin-left: 11px;
      height: 50px;
      align-items: center;
      .next-btn {
        width: 73px;
        height: 25px;
        border: none;
        color: #fb6c00;
        background-color: snow;
        cursor: pointer;
      }
      .disabled-btn {
        width: 73px;
        height: 25px;
        border: none;
        background-color: snow;
        color: #c1c1c1;
        cursor: not-allowed;
      }
    }
  }
  .cancel-save-btn {
    padding-right: 20px;
  }
}
