.selected-container{
  padding-top: 10px;
  .primary-text{
    font-size: 14px; 
    font-weight: 500; 
    color: #000;
    padding-bottom: 10px;
  }

  .secondary-text{
    color: #727376;
    font-size: 12px;
    font-weight: 400;
  }

}

.mandate-tag-wrapper{
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  width: 100%;

  .hidden-btn{
    visibility: hidden;
  }

  .mandate-container{
    display: flex;
    justify-content: space-between; 
    padding: 10px;
    position: relative;

    .shareIconFixed{
      position: absolute;
      width: 16px;
      top: 38px;
      right: 6px;
    }

    .m-detail{
      display: flex; 
      gap: 5px; 
      align-items: center;
      width: calc(100% - 16px);

      .mtitle-wrap{
        width: calc(100% - 35px);
      }

      svg{
        border-radius: 50%;
      }

      .m-title{
        color: #000;
        font-size: 13px;
        font-weight: 400;
        white-space: nowrap;  
        overflow: hidden;
        text-overflow: ellipsis; 
      }

      .m-id{
        color: #727376;
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
      }
    }

    .close-btn{
      width: 12px;
      height: 12px;
      path{
        stroke: #F34334 !important;
      }
    }
  }

  .client-container{
    border-radius: 0px 0px 10px 10px;
    background: #F3F3F7;
    display: flex;
    gap: 10px;
    padding: 8px;
    font-size: 13px;
    font-weight: 400;
    color: #000;

    .d-flex{
      display: flex;
      gap: 4px;
      align-items: center;

      .overflow-restriction{
        overflow: hidden;
        width: calc(100% - 40px);
        text-overflow: ellipsis;
        cursor: pointer;
      }

      svg{
        width: 16px;
        height: 16px;
        path{
          stroke: #002882 !important;
        }
      }
    }

    .client-wrapper{
      width: 30%;
      overflow: hidden ;
      display: flex;
      gap: 4px;
      align-items: center;

      svg{
        width: 16px;
        height: 16px;
        path{
          stroke: #002882 !important;
        }
      }

      .client-name-text{
        white-space: nowrap; 
        width: calc(100% - 20px); 
        overflow: hidden;
        text-overflow: ellipsis; 
      }
    }

    .creator-wrapper{
      width: 70%;
      overflow: hidden;
      display: flex;
      gap: 4px;
      align-items: center;

      svg{
        width: 16px;
        height: 16px;
        path{
          stroke: #002882 !important;
        }
      }

      .creator-name-text{
        white-space: nowrap; 
        width: calc(100% - 20px); 
        overflow: hidden;
        text-overflow: ellipsis; 
      }
    }
  }
}

.mandate-common-dropdown{
  .MuiInputBase-adornedEnd{
    padding-right: 16px !important;
  }
  .MuiInputBase-root{
    border-radius: 10px;
  }
}
.noMandateFound{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: fit-content;
  color: #727376;
  svg{
    width: 65px;
    height: 65px;
  }
}