.sourced-profiles{
  padding: 15px 8px 15px 0px;
  height: calc(100% - 55px);
  overflow-y: auto;
  overflow-x: hidden;

  .mandate-location-dropdown{
    padding-bottom: 20px;
    width: 100%;

    .mandate-location-list{
      width: 100%;
    }

    .MuiInputBase-input{

      .primary-text{
          color: #000;
          font-size: 14px;
          font-weight: 400;
      }
  
      .secondary-text{
          color: #727376;
          font-size: 12px;
          font-weight: 400;
      }

    }
  }

  .job-total-count-cls-out{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:10px 15px 10px 0;
    .content-col-cls{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .primary-text{
      color: #727376;
      font-size: 13px;
      font-weight: 400;
      padding-bottom: 4px;
    }
    .secondary-text{
      color: #000;
      font-size: 20px;
      font-weight: 400;
    }
  }

  .flex-grid{
    display: flex;
    justify-content:space-between;
    padding-top: 14px;
    align-items: center;
    .left-cont{
      color: #000;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
    }

    .right-cont{
      color: #002882;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      gap: 4px;
      align-items: center;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .profile-list{
    padding: 20px 0px;
    .no-allocation{
      text-align: center;
    }
    .allocation-card{
      border-radius: 10px;
      border: 1px solid #CFD1D2;
      margin-bottom: 10px;
      .all-header{
        padding: 12px 14px;
        display: flex;
        gap: 10px;

        .rec-wrapper{
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .rec-name{
          color: #000;
          font-size: 14px;
          font-weight: 500;
          overflow: hidden;
          max-width: 250px;
          white-space:nowrap;
          text-overflow: ellipsis;
        }
        .rec-role{
          color: #727376;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .all-footer{
        background: #F3F3F7;
        display: flex;
        justify-content: space-between;
        border-radius: 0px 0px 10px 10px;
        padding: 10px 12px;
        align-items: center;

        .text-one{
          color: #727376;
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap;
        }

        .slider-bar{
          color: #2BAD63 !important;
          padding: 4px 0px;
          .MuiSlider-thumb{
            visibility: hidden !important;
          }
          .MuiSlider-rail{
            background-color: #FFF !important;
            height: 8px;
          }
          .MuiSlider-track{
            background-color: #2BAD63 !important;
            height: 8px;
          }
          .MuiSlider-colorPrimary{
            color: #2BAD63 !important;
          }
        }
      }
    }
    svg{
      width: 100%;
    }
    .sourcingTabSect{
      .sourcingTabHeader{
        .MuiTabs-root{
          min-height: auto;
          .MuiTabs-flexContainer{
            gap: 10px;
            .MuiButtonBase-root{
              font-size: 11px;
              padding: 7px 12px;
              border-radius: 5px;
              border: 1px solid #CFD1D2;
              cursor: pointer;
              font-weight: 500;
              min-height: auto;
              text-transform: capitalize;
              min-width: auto;
            }
            .MuiButtonBase-root.Mui-selected{
              background-color: #002882;
              color: #fff;
              border-color: #002882;
            }
          }      
          .MuiTabs-indicator{
            display: none;
          } 
        } 
      }
      > .MuiTabPanel-root{
        padding: 24px 0px 0px 0px;
      }
      .sourcingTabHeaderSearch{
        position: relative;
        margin-bottom: 20px;
        > svg{
          position: absolute;
          right: 15px;
          top: 15px;
          width: 25px;
          height: 25px;
          color: gray;
        }
        > .MuiTextField-root{
          width: 100%
        }
      }
      .allocationListHide{
        display: none;
      }
    }
  }
}

.mandate-location-lists-opt{
  display: flex;
  flex-direction: column;
  align-items: start !important;

  .primary-text{
      color: #000;
      font-size: 14px;
      font-weight: 400;
  }

  .secondary-text{
      color: #727376;
      font-size: 12px;
      font-weight: 400;
  }
}

ul[aria-labelledby="mandate-location-select-label"]{
  max-height: 200px;
}