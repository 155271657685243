
@mixin scrollbars($size: 5px, $foreground-color: #888, $background-color: white, $border-radius: 5px) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $border-radius;
    }
    
    &::-webkit-scrollbar-track {
        background: $background-color;
    }
    
    // For Internet Explorer
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}