body .mandates-card {
  background-color: #ffffff;
  border-radius: 10px !important;
  width: 100%;
  // border-top-style: solid;
  // border-top-color: #ff6e00;
  position: relative;
  box-shadow: none;

  .status-tag{
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 400;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    border-radius: 6px;
  }
}
.mandates-card::before{
  content: "";
  position: absolute;
  width: calc(100% - 16px);
  left: 8px;
  height: 3px;
  background-color: #ff6e00;
  top: 0px;
  border-radius: 0px 0px 5px 5px;
}
.rejected-card{
  background-color: #FFE8E7 !important;
}
.mandates-grid-header {
  padding: 10px !important;
  .image-container{
    position: relative;
    p{
      background: #f1813f;
      color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin: 0px;
      padding-top: 2px;
      text-transform: uppercase;
    }
  }
  .mandate-image {
    cursor: pointer;
  }
  
  .checkbox {
  display: none;
  }
  
  // .image-container:hover .mandate-image {
  //   display:none;
  // }
  
  // .image-container:hover .checkbox {
  //   display:inline-flex;
  
  // }
  
}
.mandates-grid-header-title{
  text-transform: capitalize;
}
.mandates-grid-header .mandates-grid-header-title {
  font-weight: 500;
  font-size: 13px;
  // line-height: 16px;
  color: #000;
  overflow: hidden;
  /* white-space:nowrap;  */
  max-width: 100% !important;
  text-overflow: ellipsis !important;
}
.mandates-grid-header .MuiCardHeader-content {
  width: 60%;
}
.mandates-grid-header-title {
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
}
.mandates-grid-header-subtitle {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  // color: #727376 !important;
  margin-right: 5px !important;
}
.mandates-grid-header .MuiCardHeader-avatar {
  margin-right: 10px !important;
}
.mandates-sub-header {
  display: flex;
  align-items: 'center';
  flex-wrap: 'wrap';
}
.mandate-card-list-icon {
  min-width: 30px !important;
}
.mandats-list {
  .MuiListItemText-root .MuiTypography-root{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .MuiListItemText-primary {
    font-weight: 500;
    font-size: 12px;
  }
}
.targe-submission {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #727376;
  margin-right: 10px !important;
}
.mandates-card-content {
  // padding: 0px 10px 10px 20px !important;
  padding: 0 12px 15px !important;

  .mandats-list{
    padding-bottom: 0 !important;
  }

  .date-font{
    .MuiListItemText-primary{
      font-size: 13px;
    }
  }
}
.card-bottom-icons {
  margin-right: 5px;
  min-width: 12px;
}

.mandate-grid {
  cursor: pointer;
}

.search-no-results-found {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
.no-result-found {
  text-align: center;
  /* height: 100vh; */
}

.no-data-container{
  width: 100%;
  background-color: #ffffff;
  padding: 15px;
}
.no-result-found {
  text-align: center;
  height:auto;
  align-items: center;
  align-content: center;
  margin: auto;
  align-self:center;
  height:"100%";

  /* height:75vh; */
}
.no-result-text {
  /* margin-top: 92; */
  display: flex;
  flex-direction: column  !important;
  justify-content: center;
}
.mytask-list-paper {
  height: calc(100%  - 30%);
    display: flex;
    justify-content: center;
    margin: 17px 0px 10px 0px;
}
.mytask-list{
  justify-content: center;
  height: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mandates-grid-header .mandate-action-header{
  padding-top: 4px; 
  padding-right: 6px;
  display: flex;
  flex-direction: row;

  button {
    padding: 0px;
  }
}
.bottom-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  border: 1px solid #D9D9D9;
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .bottom-reset-button {
    background: #E5E8EB !important;
    border-radius: 8px;
    color: #000000;
    padding: 8px 28px 8px 28px;
    text-transform: none;
    margin-right: 10px;
  }

  .bottom-assign-button {
    background: #002882 !important;
    border-radius: 8px;
    color: #fff;
    padding: 8px 28px 8px 28px;
    text-transform: none;
  }
}
.mandate-grid .draft-card{
  border-top-color: #B3B5B7;
}
.avatar-list-items{
  padding-top: 8px;

  .group-avatar{
    div{
      width: 30px;
      height: 30px;
      font-size: 16px;
    }
    .assignee-avatar:hover{
      margin-top: -4px;
    }
    .MuiTooltip-tooltip{
      background-color: #000 !important;
    }
  }
}

#long-menu{
  .menuuuu-item{
    font-size: 13px !important;
  }
}
.company-ellipsis-cls{
 .MuiListItemText-primary{
  width:150px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
 }
}
.mandateCardCreateAt{
  align-items: flex-start !important;
}
.mandateCardCreateAt svg{
  margin-top: 7px;
}
.gridCardAssignInfo{
  width: 100%;
  display: flex;
  align-items: center;  
  p{    
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0px;
    span{
      margin-top: 4px;
      font-size: 14px;
    }
  }
}