.jobOrderDetailSect{
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    .jobOrderDetailtopSect{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E5E8EB;
        padding-bottom: 10px;
        .jobOrderDetailtopLeft{
            h4{
                margin: 0px;
            }
        }
        .jobOrderDetailtopRight{
            display: flex;
            gap: 10px;
            >.MuiTextField-root{
                position: relative;
                width: 240px;
                label:not(.Mui-focused,.MuiFormLabel-filled){
                    transform: translate(44px, 15px) scale(1);
                    font-size: 14px;
                }
                &::before{
                    content: "";
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    top: 15px;
                    left: 15px;
                    background-image: url(../../../../assets/icons/search.svg);                    
                }
                .MuiInputBase-root{
                    height: 48px;
                    input{
                        padding-left: 50px;
                        font-size: 14px;
                    }
                    fieldset{
                        border-radius: 10px;
                    }
                }
                
            }
        }
    }
    .jobOrderDetailMandateSect{
        display: flex;
        margin-top: 20px;
        .jobOrderDetailMandateCard{
            width: 300px;
        }
    }
    .jobOrderToggleSect{
        display: flex;
        gap: 10px;
        margin-top: 20px;
        align-items: baseline;
        > p{
            color: #727376;
            font-size: 14px;
            margin: 0px;
        }
        .jobOrderToggleSectList{
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            p{
                margin: 0px;
                font-size: 14px;
                color: #838383;
                border: 1px solid #E5E8EB;
                padding: 5px 10px;
                border-radius: 5px;
                text-transform: capitalize;
                cursor: pointer;
                span{
                    font-weight: 600;
                    color: #000;
                }
            }
            p.active{
                border-color: #ff5500;
                color: #000;
            }
        }
    }
    .jobOrderFilterSect{
        display: flex;
        flex-direction: column;
        border: 1px solid #E5E8EB;
        padding: 15px 15px 20px 15px;
        margin-top: 30px;
        border-radius: 10px;
        >p{
            margin-top: 0px;
            font-weight: 600;
        }
        .jobOrderFilterSectItems{
            display: flex;
            gap: 20px;
            .MuiFormControl-root{
                width: 250px;
                label:not(.Mui-focused,.MuiFormLabel-filled){
                    transform: translate(15px, 14px) scale(1);
                    font-size: 14px;
                }
                .MuiInputBase-root{
                    &:hover{
                        fieldset{
                            border-color: rgba(0, 0, 0, 0.23);
                        }
                    }
                    height: 48px;
                    fieldset{
                        border-radius: 10px;
                    }
                }
                .MuiInputBase-root.Mui-disabled{
                    opacity: 0.4;
                }
                .MuiInputBase-root.Mui-focused{
                    fieldset{
                        border-color: rgba(0, 0, 0, 0.23);
                    }
                }
            }
        }
        .jobOrderFilterActionSect{
            display: flex;
            align-items: center;
            .MuiStack-root{
                .filter-search-btn{
                    button{
                        padding: 0px 20px;
                        font-size: 14px;
                    }
                } 
                > button{
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .jobOderTableSect{
        margin-top: 30px;
        .jobOderTableSectScroll{
            overflow: auto;
            max-height: calc(100vh - 150px);
            .jobOrderTableBlk{
                box-shadow: none;
                border: 1px solid #E5E8EB;
                border-radius: 5px;
                position: relative;
                min-height: calc(100vh - 490px);
                .candidate-empty-search-box{
                    position: absolute;
                    left: 45%;
                    top: 30%;
                }
                table {
                    thead{
                        tr{
                            &:first-child {
                                background-color: #F3F3F7;
                                th{
                                    padding: 15px 15px 10px 18px !important;
                                    cursor: auto;
                                    vertical-align: baseline;
                                    border: none;
                                    div{
                                        display: flex;
                                        align-items: center;
                                        gap: 5px;
                                        line-height: 1;
                                        font-size: 12px;
                                        text-transform: uppercase;
                                        color: gray;
                                    }
                                    &:nth-child(1){
                                        width: calc(100% / 3);
                                    }
                                    &:nth-child(2){
                                        width: calc(100% / 5);
                                    }
                                    &:nth-child(3){
                                        width: calc(100% / 5);
                                    }
                                    &:nth-child(4){
                                        width: calc(100% / 4);
                                    }
                                }
                            } 
                        } 
                    }
                    tbody{
                        tr{
                            td{
                                vertical-align: top;
                                padding-top: 20px;
                                padding-bottom: 20px;
                                .errorCase{
                                    p{
                                        font-size: 12px;
                                        color: red;
                                        margin-bottom: 0px;
                                    }
                                }
                                .jobOrderIdCard{
                                    display: flex;
                                    flex-direction: column;
                                    gap: 5px;
                                    h4{
                                        font-weight: 500;
                                        margin: 0px;
                                    }
                                    .MuiBox-root{
                                        display: flex;
                                        gap: 20px;
                                        align-items: center;
                                        margin-bottom: 15px;
                                        p{
                                            margin: 0px;
                                            display: flex;
                                            align-items: center;
                                            gap: 5px;
                                            color: #727376;
                                            font-size: 13px;
                                            span{
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 1;
                                                -webkit-box-orient: vertical;
                                            }
                                        }
                                    }
                                    h3{
                                        margin: 0px;
                                        background: #FFE8E7;
                                        width: fit-content;
                                        color: #F34334;
                                        font-weight: 500;
                                        font-size: 14px;
                                        padding: 5px 10px;
                                        border-radius: 5px;
                                    }
                                }
                                .exceptionStatusField,.exceptionStatusType{
                                    label:not(.Mui-focused,.MuiFormLabel-filled){
                                        transform: translate(15px, 14px) scale(1);
                                        font-size: 14px;
                                    }
                                    .MuiInputBase-root{
                                        &:hover{
                                            fieldset{
                                                border-color: rgba(0, 0, 0, 0.23);
                                            }
                                        }
                                        height: 48px;
                                        fieldset{
                                            border-radius: 10px;
                                        }
                                        .MuiSelect-select{
                                            span{
                                                font-size: 14px;
                                                padding: 5px 10px;
                                                border-radius: 5px;
                                            }
                                        }
                                    }
                                    .MuiInputBase-root.Mui-focused{
                                        fieldset{
                                            border-color: rgba(0, 0, 0, 0.23);
                                        }
                                    }
                                }
                                .remarksFieldSect{
                                    display: flex;
                                    align-items: flex-start;
                                    flex-direction: column;
                                    .remarkSect{
                                        position: relative;
                                        .MuiFormControl-root{
                                            width: 100%;
                                            .MuiInputBase-root{
                                                border-radius: 10px;
                                                &:hover{
                                                    fieldset{
                                                        border: 1px solid rgba(0, 0, 0, 0.23) !important;
                                                    }
                                                }
                                            }
                                            .MuiInputBase-root.Mui-focused{
                                                fieldset{
                                                    border: 1px solid rgba(0, 0, 0, 0.23) !important;
                                                }                                            
                                            }
                                            textarea{
                                                font-size: 12px;
                                                padding-right: 15px;
                                            }
                                        }
                                        p{
                                            margin: 0px;
                                            display: flex;
                                            font-size: 13px;
                                            gap: 5px;
                                            align-items: center;
                                            margin-top: 10px;
                                            color: #727376;
                                        }
                                        .remarkFileUpload{
                                            position: absolute;
                                            right: 15px;
                                            top: 15px;
                                            img{
                                            cursor: pointer;
                                            }
                                            
                                        }
                                        .candidateTrackFileUploaded{
                                            .remarkSect > .MuiTextField-root .MuiInputBase-root{
                                              padding-bottom: 60px;
                                            }
                                          }
                                    }    
                                    .remarkSect.uploadedRemarks{
                                        .MuiFormControl-root{
                                            .MuiInputBase-root{
                                                padding-bottom: 40px;
                                            }
                                        }
                                    }
                                    .remarkFileAttach{
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        position: absolute;
                                        bottom: 10px;
                                        left: 15px;
                                        width: 88%;
                                        border-top: 1px solid #D9D9D9;
                                        padding-top: 10px;
                                        gap: 10px;
                                        p{
                                          margin: 0px;
                                          display: flex;
                                          font-size: 13px;
                                          gap: 5px;
                                          align-items: center;
                                          color: #727376;
                                          overflow: hidden;
                                          text-overflow: ellipsis;
                                          display: -webkit-box;
                                          -webkit-line-clamp: 1;
                                          -webkit-box-orient: vertical;
                                          position: relative;
                                          padding-left: 20px;
                                          &::before{
                                            content: "";
                                            width: 18px;
                                            height: 20px;
                                            background-image: url(../../../../assets/icons/remarkAttachIcon.svg);
                                            position: absolute;
                                            left: 0px;
                                          }
                                        }
                                        .MuiBox-root{
                                          display: flex;
                                          gap: 10px;
                                          img{
                                            cursor: pointer;
                                          }
                                        }
                                      } 
                                    .candidateTrackFileUploaded{
                                        .remarkSect > .MuiTextField-root .MuiInputBase-root{
                                          padding-bottom: 40px;
                                        }
                                      }                                      
                                      .errorCase{
                                        p{
                                            font-size: 12px;
                                            color: red;
                                            margin-bottom: 0px;
                                        }
                                    }
                                }                                                
                                h5{
                                    font-weight: 500;
                                    font-size: 13px;
                                    margin: 0px;
                                    max-height: 100px;
                                    overflow: auto;
                                    word-break: break-all;
                                }
                                .remarksViewSect{
                                    h5{
                                        margin-bottom: 10px;
                                    }
                                    .remarksViewSectBlk{
                                        display: flex;
                                        p{
                                            margin: 0px;
                                            margin-left: 10px;
                                            text-decoration: underline;
                                            color: #004920;
                                            font-size: 14px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 1;
                                            -webkit-box-orient: vertical;
                                            width: 180px;
                                        }
                                        h4{
                                            margin: 0px;
                                            cursor: pointer;
                                        }
                                    }
                                }
                                .remarksActionSect{
                                    display: flex;
                                    button.Mui-disabled{
                                        opacity: 0.5;
                                    }
                                  }
                            }
                            &:last-child{
                                td{
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }
       
    }
    
}