.postjobs-wrapper{
    background-color: #fff;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding: 0px 15px;

    .nav-header{
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #E5E8EB;
    }
    .alert-header
    {
      margin:5px;
      font-size: 15px;
      border-radius: 10px;
      padding: 10px;
      &.red{
        background-color: #FEE4E4;
        &::before
        {
          content: "";
          background-image: url(../../../assets/icons/alert.svg);
          width: 18px;
          height: 18px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
        }
      }
    }
    #postjobsForm
    {
      padding-top:30px;
    }
    .header-text{
      font-family: 'Sora';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
    .container-wrapper{
      padding: 0rem 0.5rem 1rem;
  
      .row-grid{
        display: flex;
        gap: 20px;
        > .datePickerMandate{
          width: calc(25% - 15px) !important;
        }
        .job-title-box{
          position: relative;
          *::-webkit-scrollbar {
            // display: none;
            width: 6px;
            height: 10px;
          }
          *::-webkit-scrollbar-track {
            background-color: #f4f5f6;
            border-radius: 10px;
          }
          *::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #aaa;
            width:10px;
            text-overflow: ellipsis;
          }
        }
      }
      .pt-1{
        padding-top: 1.5rem;
      }
  
      .postjobs-slider{
        display: flex;
        gap: 20px;
        align-items: center;
        .range-slider{
          width:50%;
        }
        .range-select-container{
          width: 50%;
          margin-top: unset !important;
        }
        .MuiSlider-colorPrimary{
          color: #FF6E00;
        }
        .MuiSlider-thumb{
            width:15px;
            height:15px;
            background-color:#fff;
            border: 1px solid #FF6E00;
        }
        .MuiSlider-valueLabelOpen{
            transform: translateY(100%) scale(1) !important;
            top: 0px !important;
            background-color: #000000;
        }
        .MuiSlider-valueLabelOpen::before{
            top: -8px;
        }
        .select-container{
          margin-top: unset !important;
          > .MuiFormControl-root{
            min-width: 120px;
          }
        }
      }
    }
    .button-container{
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
  
      button{
        text-transform: none !important;
        border-radius: 8px;
        padding: 0.5rem 1rem;
      }
      button.Mui-disabled{
        opacity: 0.5;
      }
      .cancel-btn{
        background-color: #E5E8EB;
        color: #000;
      }
      .save-btn{
        background-color: #002882;
        color: #fff;
      }
      .draft-btn{
        color: #002882;
        border: 1px solid #002882;
      }
    }
    .helper-boxtext{
      display: flex;
      gap: 6px;
      align-items: flex-start;
    }
  
    .error{
      font-size: 12px;
      color: red;
    }
  
    .MuiInputBase-root{
      border-radius: 10px !important;
    }
    .Mui-expanded{
      margin: unset !important;;
    }
    .ruler-hr{
      width: calc(100% - 50px);
    }
    .quill{
      height: 240px;
      ::-webkit-scrollbar {
        width: 5px !important;
      }
      ::-webkit-scrollbar-track {
          border-radius: 10px !important;
      
      }
      ::-webkit-scrollbar-thumb {
          background: #DBDBDB !important;
          border-radius: 10px !important;
      }
    }
    .ql-toolbar.ql-snow {
      border: unset !important;
    }
    .ql-container.ql-snow{
      border: unset !important;
      height: calc(100% - 28%) !important;
    }
  
    .delete-icon{
      svg path{
        stroke: #F34334 !important;
      }
    }
  
    .select-unit-wrapper{
      display: flex;
      width: 60%;
      justify-content: space-between;
      align-items: center;
  
      .unit-container{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px !important;
        font-weight: 400 !important;
  
        .MuiInputBase-input{
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          font-size: 14px !important;
          font-weight: 400 !important;
          color: #002882 !important;
        }
        .MuiSelect-iconOutlined{
          top : calc(50% - 0.55em) !important;
        }
      }
    }
    .lable-text-select{
      font-size: 12px; 
      font-weight: 400; 
      color: #727376; 
      padding-bottom: 10px;
    }
    .accord-icon{
      path{
        stroke: #002882 !important;
      }
    }
    .location-dropdown{
      height: 100% !important;
      .MuiTextField-root{
        height: 100% !important;
      }
      .MuiAutocomplete-inputRoot{
        height: 100% !important;
        padding: 0px 9px !important;
      }
      .MuiAutocomplete-endAdornment{
        top: calc(50% - 14px) !important;
      }
    }
  
    .autofield-error{
      .MuiTextField-root {
        border-color: red !important;
        border-width: 1.5px !important;
      }
    }
    .error-span{
      margin-top: 10px !important;
    }
    .inner-textfield {
      // max-height: 50px;
      /* overflow: none; */
      box-sizing: border-box;

      .MuiTextField-root {
        padding-top: 10px;
        border: 1.5px solid;
        border-radius: 8px;
        .MuiInputBase-adornedEnd{
          padding: 0 10px 10px 10px !important;
        }
    
      }
      label{
        background-color: #fff;
      }
    
      .MuiInputBase-formControl {
        max-height: 50px;
        overflow: auto;
        /* border: 1px solid; */
        // padding-top: 0;
      }
    
      .MuiInputBase-formControl fieldset {
        border: none;
        outline: none;
      }
      ::-webkit-scrollbar {
        // display: none;
        width: 6px;
        height: 10px;
      }
      ::-webkit-scrollbar-track {
        background-color: #f4f5f6;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #aaa;
        width:10px;
        text-overflow: ellipsis;
      }
    }
    .MuiFormControl-root{
      .MuiInputBase-root:hover,.MuiInputBase-root.Mui-focused{
          fieldset{
              border-color: rgba(0, 0, 0, 0.23);
              border-width: 1px;
          }
      }
  }
  }
  

.bottom-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 70px;
    
    .bottom-cancel-button {
        background: #E5E8EB !important;
        border-radius: 8px;
        color: #000000;
        padding: 8px 28px 8px 28px;
        text-transform: none;
        margin-right: 10px;
    }

    .bottom-assign-button {
        background: #002882 !important;
        border-radius: 8px;
        color: #fff;
        padding: 8px 28px 8px 28px;
        text-transform: none;
    }
}
  @media (max-width: 992px) {
    .postjobs-wrapper{
      .container-wrapper{
        .row-grid{
          flex-direction: column;
          .datePickerMandate{
            width: calc(100%) !important;
          }
        }
      }
      .res-grid{
        width: 100% !important;
      }
    }
  }

  @media (max-width: 768px) {
    .postjobs-wrapper{
      .postjobs-slider{
        display: flex;
        flex-direction: column !important;
        .select-container{
          width: 100% !important;
        }
      }
      .custom-dropzone {
        width: 270px !important;
      }
    }

    .postjobs-location-field-model{
      .row-grid{
        flex-direction: column;

        .clientspoc-field{
          width: 100% !important;
        }
      }
      .res-grid{
        width: 100% !important;
      }
      .pricing-group{
        flex-direction: row !important;
      }

      .location-dropdown{
        div{
          div{
            input{
              padding: 16.5px 0px 16.5px 14px !important;
            }
          }
        }
      }
    }
  }

.postjobs-location-wrapper{
  .no-postjobs-location-text{
    color: #727376;
    font-family: Sora;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
  }

  .postjobs-location-tag{
    text-transform: none;
    background-color: #F3F3F7;
    border-radius: 8px;
    color: #002882;
    font-weight: 400;

    svg:nth-child(1){
      width: 14px;
      height: 14px;
      margin-left: 10px;
      path{
        stroke: #0E94F4 !important;
      }
    }

    svg:nth-child(2){
      width: 12px ;
      height: 12px;
      margin-left: 6px;
      path{
        stroke: #FF0000 !important;
      }
    }
  }
}

.postjobs-location-field-model{
  .row-grid{
    display: flex;
    gap: 20px;
  }
  .gray-out{
    opacity: 0.5;
  }

  .form-modal{
    width: 80%;
    max-width: 1000px;
    height: calc(100% - 60px);
    overflow-y: auto;
    position: relative;
  }

  .postjobs-slider{
    display: flex;
    gap: 20px;
    align-items: center;
    .range-slider{
      width:50%;
    }
    .range-select-container{
      width: 50%;
      margin-top: unset !important;
    }
    .MuiSlider-colorPrimary{
      color: #FF6E00;
    }
    .MuiSlider-thumb{
        width:15px;
        height:15px;
        background-color:#fff;
        border: 1px solid #FF6E00;
    }
    .MuiSlider-valueLabelOpen{
        transform: translateY(100%) scale(1) !important;
        top: 0px !important;
        background-color: #000000;
    }
    .MuiSlider-valueLabelOpen::before{
        top: -8px;
    }
    .select-container{
      margin-top: unset !important;
    }
  }

  .select-unit-wrapper{
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;

    .unit-container{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px !important;
      font-weight: 400 !important;

      .MuiInputBase-input{
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        color: #002882 !important;
      }
      .MuiSelect-iconOutlined{
        top : calc(50% - 0.55em) !important;
      }
    }
  }

  .ruler-hr{
    width: calc(100% - 70px);
  }
  .ruler-hr-spoc{
    width: calc(100% - 112px);
  }
  .bottom-buttons {
    padding-top: 20px;
    border-top: 1px solid #CFD1D2;
    margin-top: unset !important;
     }
  .MuiInputBase-root{
    border-radius: 10px !important;
  }
  .Mui-expanded{
    margin: unset !important;;
  }

  .location-dropdown{
    height: 100% !important;
    .MuiTextField-root{
      height: 100% !important;
    }
    .MuiAutocomplete-inputRoot{
      height: 100% !important;
      padding: 0px 9px !important;
    }
    .MuiAutocomplete-endAdornment{
      top: calc(50% - 14px) !important;
    }
  }
}

.jobPostSkillsSect{
  width: 100%;
  > .MuiFormControl-root{
      .MuiAutocomplete-root{
          .MuiFormControl-root{
              .MuiInputBase-root{
                  background: transparent;
                  border: 1px solid rgba(0, 0, 0, 0.23);
                  padding: 15px;
                  min-height: 110px;
                  display: flex;
                  align-items: baseline;
                  &::after,&::before{
                      display: none;
                  }
                  .MuiButtonBase-root{
                      border-radius: 5px;
                      background: #efefef;
                      border: none;
                      span{
                          font-weight: 500;
                      }
                  }
                  .MuiAutocomplete-endAdornment{
                      display: none;
                  }
              } 
              > label[data-shrink=false]{
                  transform: translate(16px, 24px) scale(1);
              }
              > label[data-shrink=true]{
                  transform: translate(2px, -25px) scale(0.75);
              }
          }
      }        
  }    
}
.jobLocationFields{
  width: 100%;
  > .MuiFormControl-root{
      .MuiAutocomplete-root{
          .MuiFormControl-root{
              .MuiInputBase-root{
                  background: transparent;
                  border: 1px solid rgba(0, 0, 0, 0.23);
                  padding: 8px;
                  &::after,&::before{
                      display: none;
                  }
                  .MuiButtonBase-root{
                      border-radius: 5px;
                      background: #efefef;
                      border: none;
                      span{
                          font-weight: 500;
                      }
                  }
                  .MuiAutocomplete-endAdornment{
                      display: none;
                  }
              } 
              > label[data-shrink=false]{
                  transform: translate(14px, 16px) scale(1);
              }
              > label[data-shrink=true]{
                transform: translate(10px, -8px) scale(0.75);
                background: #fff;
                padding: 0px 5px;
              }
          }
      }        
  }    
}
.discloseText{
  input{
    background: #efefef;
    border-radius: 10px;
  }
}
.postJobJoDescField{
  display: flex;
  align-items: center;
  justify-content: space-between;
  >span {
    padding: 0px;
  }
  > label{
    > .MuiStack-root{
      font-size: 12px;
      .MuiTypography-root{
        font-size: 12px;
      }
    }
    .MuiSwitch-root{
      width: 50px !important;
      height: 39px !important;
      > .MuiButtonBase-root{
        .MuiSwitch-thumb{
          width: 12px !important;
          height: 12px !important;
        }
        transform: translate(4px, 5px);
      }
      > .MuiButtonBase-root.Mui-checked{
        transform: translate(15px, 5px) !important;
      }
    }
  }
}