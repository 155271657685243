.insight-wraper{
    border-bottom:"1px solid #CFD1D2";
    padding-bottom: 8px;
    .insight-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.candidateDetailWraper{
    margin-top: 80px;
    text-align: center;
    margin-bottom: 80px;
    width: 100%;
}