.work-management-container-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    max-height: calc(100vh - 112px);
    overflow: auto;
    transition: all 0.5s;
    .col-one {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        width: calc(100% - 370px);
        transition: all 0.5s;
        // height: auto;
        .work-box-style{
            background: #1976d2;
            padding-left: 5px;
            height: 50px;
            overflow: hidden;
            display: table;
            box-shadow: none;
            border-radius: 5px !important;
            .work-parent-box{
                background: white;
                border-left: 7px solid #002882;
            }
        }
    }

    .col-two {
        display: flex;
        width: 360px;
        // height: auto;
        flex-direction: row;
        margin-right: 5px;
        background: #FFFFFF;
        border-radius: 10px;
        // margin-top: 20px;
        // pointer-events: none;
        // opacity: 0.6;        
        transition: all 0.5s;
        position: relative;
        .left-part{
            pointer-events: none;
            opacity: 0.6;
            background-color: #d2d2d2;
            transition: all 0.5s;
            overflow-x: hidden;
            overflow-y: scroll;
            // height: calc(100vh - 112px);
        }
    }

    .col-one .col-two {
        height: 100%;
    }

    .col-two {
        // display: grid;
        // grid-template-columns: 1fr 60px;
        // overflow: auto;
        // height: 80%;
        .left-part {
            display: flex;
            flex-direction: column;
            width: 300px;
            // height: max-content;
            
            .header-row-cls {
                padding: 20px 21px 18px 20px;
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: flex-start;

                .header-font {
                    font-family: Sora;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                }

                img {
                    height: 10px;
                    cursor: pointer;
                    fill: #727376;
                    stroke-width: 1px;
                    stroke: #727376;
                }
            }

            .divider-cls {
                stroke-width: 1px;
                stroke: #E5E8EB;
                border-top: 1px solid #E5E8EB;
                width: 100%;
                height: 1px;
            }

            .prof-details-container {
                padding: 17px 15px 28px 15px;
                display: flex;
                flex-direction: column;

                .prof-header-row {
                    display: flex;
                    flex-direction: row;

                    .left-cls {
                        display: flex;
                        margin-right: 10px;
                    }

                    .right-cls {
                        display: flex;
                        flex-direction: column;

                        .primary-text {
                            font-family: Sora;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 18px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #000000;
                            margin-bottom: 6px;
                        }

                        .secondary-text {
                            font-family: Sora;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #727376;
                        }
                    }
                }
            }

            .activity-container {
                display: flex;
                flex-direction: column;
                padding-right: 15px;
                padding-bottom: 20px;

                .left-row-cls {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .active-cls {
                        border-left: 4px solid #002882;
                        width: 4px;
                        height: 24px;
                        border-radius: 0px 10px 10px 0px;
                        // background: #002882;
                    }

                    .header-font {
                        color: #000;
                        font-family: Sora;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        margin-left: 10px;

                    }
                }


                .header-row {
                    display: flex;
                    flex-direction: row !important;
                    justify-content: space-between !important;
                    align-items: center;

                    img {
                        cursor: pointer;
                    }
                }

                .primary-text {
                    font-family: Sora;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #727376;
                }

                .secondary-text {
                    font-family: Sora;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                    margin-top: 4px;
                }

                .highlighted-text {
                    font-family: Sora;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #002882;
                }

                .non-highlight-text {
                    color: #000;
                    font-family: Sora;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .Mandate-col {
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    padding-left: 15px;

                    .row-cls {
                        display: flex;
                        flex-direction: row;
                        margin-top: 9px;

                        img {
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .hor-divider {
                stroke-width: 1px;
                stroke: #E5E8EB;
                border-top: 1px solid #E5E8EB;
                width: 100%;
                height: 1px;
            }
        }

        .right-part {
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 55px;
            height: 100%;
            right: 0px;
            padding-left: 10px;
            overflow-x: hidden;
            overflow-y: scroll;
            // height: calc(100vh - 112px);

            .side-menus {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                border-radius: 6px 0 0 6px;
                overflow: hidden;
                position: relative;
                &.active{
                    background: #F0F6FB;
                    &::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 5px;
                        transform: translateY(-50%);
                        height: calc(100% - 10px);
                        border-radius: 0 5px 5px 0;
                        background-color: #002882;
                    }
                }
            }

            .tabButton-color {
                border-radius: 4px 0 0 4px;
                border-left: 4px solid #002882;
                background-color: #F0F6FB;
                margin: 10px 0px 0px;
                width: 100%;
                height: 40px;
                padding: 10px;

                path {
                    stroke: #002882 !important;
                }
            }

            .tabButton-inactive {
                border-radius: 4px 0 0 4px;
                border-left: 4px;
                margin: 10px 0px 0px;
                width: 100%;
                height: 40px;
                padding: 10px;

                path {
                    stroke: rgb(179, 181, 183) !important;
                }
            }

            .side-nav-bar {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .divider-cls {
            width: 1px;
            height: 100%;
            background-color: #E5E8EB;
            stroke-width: 1px;
            stroke: #E5E8EB;
        }
    }

    .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 14px;
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    .mr-10 {
        margin-right: 10px;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mt-22 {
        margin-top: 22px;
    }

    .w-i {
        white-space: initial;
    }

    .w-100 {
        width: 100%
    }

    .w-45 {
        width: 45%;
    }

    .annual-cont-padding {
        padding-top: 18px !important;
        padding-right: 15px !important;
        padding-bottom: 15px !important;
    }
    .mandate-card-wrapper{
        width: 200px;
        .mandate-card-container{
            .mandate-card-detail{
                p{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }        
        .mandate-card-client{
            // flex-direction: column;
            gap: 0px;
        }
    }
}
.work-management-container-wrapper.wrkRecuriterToggle{
     .col-one{
        width: calc(100% - 71px);
        transition: all 0.5s;
     }
     .col-two{
        width: 55px;
        transition: all 0.5s;
        position: relative;
        .left-part{
            width: 0%;
            overflow: hidden;
            transition: all 0.5s;
        }
        .right-part{
            width: 55px;
            svg{
                margin: 10px 0px;
            }
        }
     }
}
.workManagTableKPISect{
    display: flex;
    gap: 60px;
    margin-left: 25px;
}
@media screen and (max-width: 1500px) and (min-width: 1200px) {
    .workManagTableKPISect{
        gap: 30px;
        margin-left: 40px;
    }
}
@media screen and (min-width: 1500px){
    .week-view-table-container .workManagTableKPISect {
        display: flex;
        gap: 46px;
        margin-left: 36px;
    }
    .workManagTableKPISect {
        display: flex;
        gap: 58px;
        margin-left: 31px;
    }
}
.workMangSideMenu{
    pointer-events: none;
}