.mandate-detail-drawer .MuiDrawer-paper {
    width: 45% !important;
    box-shadow: 0px 0px 10px #ededed;
    overflow: hidden;
  }
  
  @media (max-width: 800px) {
    .mandate-detail-drawer .MuiDrawer-paper {
      width: 100% !important;
    }
  }
.mandate-detail-drawer .MuiBackdrop-root{
    opacity: 0 !important;
}
.mandate-detail-data{
    margin-bottom: 2rem;
    width: 100%;
}
.mandate-detail-data .client-avatar-name{
margin-left: 1rem;
}
.mandate-detail-data .client-avatar-name{
    display: flex;
}
.client-avatar-name .client-name{
    color: #000 !important ;
    font-size: 16px ;
    font-weight: 500 !important;
    line-height: 18px;
    display: inline;
    margin: 0 0.5rem;
    padding-right: 2rem;
    width: 100%;
}
.client-avatar-id{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 95%;
}

.client-avatar-id .mandate-xpheno-id{
    color:#727376 !important ;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px;
    display: inline;
    margin: 0 0.5rem;
}

.mandate-divider{
    margin-top: 0.7rem;
}
.mandate-parser-details .mandate-data-location{
background-color: #002882;
color:#F0F6FB;
display: inline-block;
padding: 0.5rem;
border-radius: 10px;
margin-left: 1rem;
margin-top: 1rem;
}

.location-text{
    font-size: 14px !important;
    line-height: 24px;
}

.mandate-parser-details .mandate-data-location:hover{
    background-color:#F0F6FB;
    color: #002882;
}
.mandate-data-box{
    margin-left: 1rem;
    margin-top: 1.5rem;
}

.mandate-data-box .mandate-data-headings{
    color: #727376 !important;
}
.mandate-data-box .mandate-data-details{
    color: #000 !important;
}

.mandate-data-box.data-box-flex{
    width: 70%;
    display: flex;
   justify-content:space-between
}

.mandate-data-box.data-box-flex .mandate-data-details{
    width: 45%;
}

@media (min-width: 900px) {
    .mandate-full-data-box{
    width:70%;
    padding:1rem;
    }
  }

  @media (min-width: 900px) {
    .mandaet-query-builder-block{
    width:30%;
    padding:1rem;
    }
  }
.mandaet-query-builder-block{
    background-color: #fff;
}
  /* .mandate-detail-full-drawer .MuiDrawer-paper {
    width: 80%;
  } */


/* .data-on-fullwidth .mandate-parser-data-box{
    width: 50%;
}

.mandate-drawer-query{
    display: flex;
} */
 /* .mandate-detail-full-drawer .MuiDrawer-paper{
    width: 240; 
    position: absolute;
    transition:none !important;
 } */
 /* .mandate-detail-full-drawer .css-1160xiw-MuiPaper-root-MuiDrawer-paper{
    top: 50px;
    left: 80px;
    right: 20px;
 } */