.breadcrumb {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    margin-bottom: 20px !important;
}
.breadcrumbFilter {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
}