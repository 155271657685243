.mandate-location-list-model{

  .d-flex{
    display: flex;
    gap: 10px;
  }

  .mandate-location-container{
    padding: 20px;
    background: #fff;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    max-height: 90%;
    overflow-y: auto;

    .header-box{
      display: flex;
      justify-content: space-between;
      p{
        color: #000;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .candidate-profile{
      padding: 15px;
      display: flex;
      gap: 10px;
      margin-top: 20px;
      border-radius: 10px;
      border: 1px solid #E5E8EB;

      .prof-detail{
        .can-name{
          color: #000;
          font-size: 16px;
          font-weight: 500;
        }
        .secondary-text{
          color: #727376;
          font-size: 13px;
          font-weight: 400;
        }
        .icon-wrapper{
          color: #727376;
          gap: 4px;
          svg{
            width: 16px;
            height: 16px;
          }
        }
      }

      .avatar-name{
        font-size: .875rem;
        font-style: normal;
        font-weight: 400;
        height: 2.6875rem;
        width: 2.6875rem;
      }
    }

    .table-box{
      margin-top: 15px;

      .t-head{

        tr{
          th{
            color: #727376;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            border-bottom: unset !important;
            background-color: #F3F3F7;
          }
          th:nth-child(1){
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          th:nth-child(6){
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .t-body{
        border-left: 1px solid #E5E8EB;
        border-right: 1px solid #E5E8EB;

        .primary-text{
          color: #000;
          font-size: 14px;
          font-weight: 400;
        }
        .secondary-text{
          color: #727376;
          font-size: 12px;
          font-weight: 400;
        }

        .status-tag{
          border-radius: 5px;
          border: 1px solid #FFF;
          background: #E5E9F3;
          color: #002882;
          font-weight: 400;
          padding: 8px 16px;
          text-align: center;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          
        }
      }
    }

    .btn-box{
      display: flex;
      justify-content: end;
      margin-top: 20px;
    }
  }
}